import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './Navigation.module.scss';
import {
  Modal,
  Link,
  PrimaryButton,
  SecondaryButton,
  InputFieldWithController,
  InputField,
} from '../../common';
import { useAxios } from '../../../api';
import { NewWorkspaceModal } from '../../Workspace/NewWorkspaceModal';
import NavigationMenu from './NavigationLinks';
import { Check, ChevronLeft, ChevronRight, ChevronsUpDown } from 'lucide-react';
import toast from 'react-hot-toast';
import { useSession } from '../../../context/SessionContext';
import { WorkspaceProps, useWorkspace } from '../../../context/WorkspaceContext';
import { useForm } from 'react-hook-form';

interface NavigationProps {
  workspaceList: WorkspaceProps[];
  setWorkspaceList: Dispatch<SetStateAction<WorkspaceProps[]>>;
}

interface SessionWorkspaceProps {
  isActive: boolean;
  workspaceId: string;
}
interface sessionProps {
  _id: string;
  workspace: SessionWorkspaceProps[];
}

export const Navigation = ({ workspaceList, setWorkspaceList }: NavigationProps) => {
  const { setWorkspace, workspace } = useWorkspace();
  const { session, setSession } = useSession();

  const [toggle, setToggle] = useState(true);
  const [switcher, setSwitcher] = useState(false);
  const [workspaceModal, setWorkspaceModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [leaveopen, setLeaveOpen] = useState(false);
  const [errors, setErrors] = useState({} as Record<string, string>);
  const [name, setName] = useState('');
  const { handleSubmit } = useForm();

  const { makeRequest } = useAxios<{ data: Record<string, string> }>(
    `workspace/${workspace?.workspaceId}`
  );

  const { response: deleteResponse, makeRequest: deleteWorkspaceRequest } = useAxios(
    `workspace/${workspace?.workspaceId}`
  );

  // const { response: sessionData, makeRequest: makeSessionDataRequest } = useAxios('auth/session');

  // useEffect(() => {
  //   makeSessionDataRequest({ method: 'get' });
  // }, [workspaceList]);

  // useEffect(() => {
  //   if (sessionData) {
  //     const data = (sessionData as { data: Record<string, unknown>[] }).data;
  //     setSession(data);
  //   }
  // }, [sessionData]);

  useEffect(() => {
    if (workspaceList) {
      const activeWorkspaceDetails = (session as sessionProps)?.workspace?.find(
        (val) => val.isActive
      );
      if (activeWorkspaceDetails?.isActive) {
        setWorkspace(activeWorkspaceDetails as unknown as WorkspaceProps);
      } else {
        setWorkspace(workspaceList[0]);
      }
    }
  }, [workspaceList, session]);

  useEffect(() => {
    if (deleteResponse) {
      makeRequest({ method: 'get' });
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (workspace?._id) {
      makeRequest({ method: 'get' });
    }
  }, [workspace]);

  const handleDeleteWorkspace = () => {
    if (name !== workspace?.details.name) {
      setErrors({ name: 'Name doesn"t match' });
      return false;
    }

    deleteWorkspaceRequest({ method: 'delete' });
    setOpen(false);
    toast.success('Workspace deleted sucessfully', {
      duration: 4000,
    });
    window.location.reload();
  };
  const handleDeleteWorkspaceCancel = () => {
    setOpen(false);
  };
  const { makeRequest: leaveWorkspaceRequest } = useAxios(`workspace/member/leave`);
  const handleLeaveWorkspace = () => {
    leaveWorkspaceRequest({
      method: 'delete',
      data: {
        workspaceId: workspace?.workspaceId,
      },
    });
    setLeaveOpen(false);
    toast.success('You leave Workspace sucessfully', {
      duration: 4000,
    });
    window.location.reload();
  };
  const handleLeaveWorkspaceCancel = () => {
    setLeaveOpen(false);
  };

  const workspaceDetails = () => {
    const intialLetterOfName = workspace?.details?.name
      ?.split(' ')[0]
      .substring(0, 1)
      .toUpperCase();

    return toggle ? (
      <button>
        <h6>{intialLetterOfName}</h6>
        <span>
          <p>{workspace?.details?.name}</p>
          <h5>{workspace?.role}</h5>
        </span>
        <ChevronsUpDown />
      </button>
    ) : (
      <button>
        <h6>{intialLetterOfName}</h6>
      </button>
    );
  };

  const handleWorkspaceSwitcher = (value: WorkspaceProps) => {
    setName('');
    setWorkspace(value);
    setSwitcher(false);
  };

  return (
    <div className={`${styles.sideBar} ${!toggle ? styles.short : ''}`}>
      <button
        className={`${styles.toggleBtn} ${!toggle ? styles.off : ''}`}
        onClick={() => setToggle(!toggle)}
      >
        <ChevronLeft />
      </button>
      <span className={styles.logo}>
        <img src={toggle ? '/assets/big-logo.png' : '/assets/logo.png'} alt='' />
      </span>
      <div className={styles.workspaceToggle}>
        {toggle && <p className={styles.title}>workspace</p>}
        <div className='details' onClick={() => setSwitcher(true)}>
          {workspaceDetails()}
        </div>
        <Modal open={switcher} closeModal={() => setSwitcher(false)} className='workspaceModal'>
          <>
            <div className='details'>
              {workspaceList?.length &&
                workspaceList?.map((val: WorkspaceProps, index: number) => {
                  const intialLetterOfName = val?.details?.name
                    ?.split(' ')[0]
                    .substring(0, 1)
                    .toUpperCase();
                  const selectedWorkspaceId = workspace?.workspaceId === val.workspaceId;
                  return (
                    <button key={index} onClick={() => handleWorkspaceSwitcher(val)}>
                      <h6>{intialLetterOfName}</h6>
                      <span>
                        <p>{val?.details?.name}</p>
                        <h5>{val.role}</h5>
                      </span>
                      {selectedWorkspaceId ? <Check /> : null}
                    </button>
                  );
                })}
            </div>
            {workspace?.role !== 'Member' && (
              <div className='management'>
                <p>Workspace Management</p>
                <Link to='/workspace' onclick={() => setSwitcher(false)}>
                  Workspace Settings <ChevronRight />
                </Link>
                <Link to='/billingAndSubscription' onclick={() => setSwitcher(false)}>
                  Billing & Subscription
                  <ChevronRight />
                </Link>
              </div>
            )}
            <div className='invite'>
              <Link to='/inviteUser' onclick={() => setSwitcher(false)}>
                Invite people to {workspace?.details?.name} <ChevronRight />
              </Link>
              <Link>
                Create a channel <ChevronRight />
              </Link>
            </div>

            <div className='new-workspace'>
              <Link
                onclick={() => {
                  setWorkspaceModal(true);
                  setSwitcher(false);
                }}
              >
                Create new workspace <ChevronRight />
              </Link>
              {workspace?.role == 'Owner' && (
                <span className='delete'>
                  <Link
                    onclick={() => {
                      setSwitcher(false);
                      setOpen(true);
                    }}
                  >
                    Delete workspace <ChevronRight />
                  </Link>
                </span>
              )}
              {['Member', 'Guest'].includes(String(workspace?.role)) && (
                <Link onclick={() => setLeaveOpen(true)}>
                  Leave Workspace <ChevronRight />
                </Link>
              )}
            </div>
          </>
        </Modal>
      </div>

      <NavigationMenu />

      <NewWorkspaceModal
        open={workspaceModal}
        setOpen={() => setWorkspaceModal(false)}
        setWorkspaceList={setWorkspaceList}
      />
      <Modal
        open={open}
        closeModal={() => setOpen(false)}
        className={styles.worknavModal}
        size='sm'
      >
        <div className={styles.body}>
          <h4>
            This will permanently delete the workspace, including all properties, channels, reports
            and toggles. This action cannot be undone. Please type the name of the workspace to
            confirm.
          </h4>
          <form onSubmit={handleSubmit(handleDeleteWorkspace)} noValidate>
            <div className={styles.deleteWorkspaceFormModel}>
              <div>
                <InputField
                  type='text'
                  name='name'
                  label='Workspace name'
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrors({});
                  }}
                />
                {errors.name && <p className='error'>{errors.name}</p>}
              </div>
              <div className={styles.deleteWorkspaceBtnsProfile}>
                <PrimaryButton
                  value='Permanently delete workspace'
                  type='submit'
                  bgColor='#f08c8c'
                  fullWidth={true}
                />
                <PrimaryButton
                  noBorder
                  noBackground
                  value='Cancel'
                  textColor='#74788c'
                  onClick={handleDeleteWorkspaceCancel}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        open={leaveopen}
        closeModal={() => setLeaveOpen(false)}
        className={styles.worknavModal}
        title='Do you want to leave Workspace?'
        size='sm'
      >
        <div className={styles.deleteWorkspaceFormModel}>
          <div className={styles.deleteWorkspaceBtnsProfile}>
            <PrimaryButton value='Confirm' onClick={handleLeaveWorkspace} />
            <SecondaryButton
              bgColor='#74788C'
              value='Cancel'
              onClick={handleLeaveWorkspaceCancel}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

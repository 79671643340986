import { Pagination as Pagin, Stack } from 'rsuite';

const Pagination = ({ page, setPage, data }: any) => {
  const total = data.totalItems;
  return (
    <div style={{ marginTop: '25px' }}>
      <Stack spacing={6} direction={'row'} alignItems={'center'} justifyContent={'center'}>
        <Pagin
          prev
          last
          next
          first
          size='lg'
          total={total}
          limit={10}
          activePage={page}
          onChangePage={(newPage: number) => setPage(newPage)}
        />
      </Stack>
    </div>
  );
};

export default Pagination;

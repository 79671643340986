// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputFieldWithController_formControl__sKm8E {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.InputFieldWithController_formControl__sKm8E .InputFieldWithController_label__kvZwu {
  color: var(--neutral-medium);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 8px;
}
.InputFieldWithController_formControl__sKm8E .InputFieldWithController_inputWrapper__8FgM2 {
  position: relative;
}
.InputFieldWithController_formControl__sKm8E .InputFieldWithController_inputWrapper__8FgM2 .InputFieldWithController_inputIcon__nfoAr {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.InputFieldWithController_formControl__sKm8E .InputFieldWithController_inputWrapper__8FgM2 input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 16px 12px;
}
.InputFieldWithController_formControl__sKm8E .InputFieldWithController_inputWrapper__8FgM2 input.InputFieldWithController_errorInput__68KNS {
  border: 1px solid var(--error);
}
.InputFieldWithController_formControl__sKm8E .InputFieldWithController_inputWrapper__8FgM2 input.InputFieldWithController_password__iv\\+BJ {
  padding-right: 40px;
}
.InputFieldWithController_formControl__sKm8E .InputFieldWithController_inputWrapper__8FgM2 .InputFieldWithController_withIcon__fMoXn {
  padding-left: 40px;
}
.InputFieldWithController_formControl__sKm8E .InputFieldWithController_inputWrapper__8FgM2 .InputFieldWithController_eyeIcon__Scw23 {
  position: absolute;
  right: 10px;
  bottom: 12px;
  border: none;
  cursor: pointer;
  background: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/InputFieldWithController/InputFieldWithController.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,eAAA;EACA,sBAAA;AACF;AAAE;EACE,4BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;AADI;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,2BAAA;AAGN;AAAI;EACE,WAAA;EACA,kBAAA;EACA,qCAAA;EACA,kBAAA;AAEN;AADM;EACE,8BAAA;AAGR;AADM;EACE,mBAAA;AAGR;AAAI;EACE,kBAAA;AAEN;AACI;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AACN","sourcesContent":[".formControl {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  .label {\n    color: var(--neutral-medium);\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 18px;\n    padding-bottom: 8px;\n  }\n  .inputWrapper {\n    position: relative;\n    .inputIcon {\n      position: absolute;\n      left: 10px; // Adjust as necessary\n      top: 50%;\n      transform: translateY(-50%);\n      // Style your icon as necessary\n    }\n    input {\n      width: 100%;\n      border-radius: 4px;\n      border: 1px solid var(--border-color);\n      padding: 16px 12px;\n      &.errorInput {\n        border: 1px solid var(--error);\n      }\n      &.password {\n        padding-right: 40px;\n      }\n    }\n    .withIcon {\n      padding-left: 40px;\n    }\n\n    .eyeIcon {\n      position: absolute;\n      right: 10px;\n      bottom: 12px;\n      border: none;\n      cursor: pointer;\n      background: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formControl": `InputFieldWithController_formControl__sKm8E`,
	"label": `InputFieldWithController_label__kvZwu`,
	"inputWrapper": `InputFieldWithController_inputWrapper__8FgM2`,
	"inputIcon": `InputFieldWithController_inputIcon__nfoAr`,
	"errorInput": `InputFieldWithController_errorInput__68KNS`,
	"password": `InputFieldWithController_password__iv+BJ`,
	"withIcon": `InputFieldWithController_withIcon__fMoXn`,
	"eyeIcon": `InputFieldWithController_eyeIcon__Scw23`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrimaryButton_primary__f7fhK {
  background: var(--primary-color);
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  border-radius: 8px;
  padding: 16px;
  border: none;
  color: var(--neutral-white);
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.1s;
}
.PrimaryButton_primary__f7fhK:hover {
  background-color: var(--neutral-light);
  color: var(--primary-color);
}
.PrimaryButton_primary__f7fhK:hover svg path {
  stroke: var(--primary-color);
}
.PrimaryButton_primary__f7fhK:focus {
  box-shadow: 0 0 0 3px rgba(25, 118, 210, 0.5);
}
.PrimaryButton_primary__f7fhK[disabled] {
  background: var(--disabled);
  cursor: not-allowed;
}
.PrimaryButton_primary__f7fhK:active {
  background-color: #0d47a1;
}
.PrimaryButton_primary__f7fhK.PrimaryButton_fullWidth__V2NLP {
  width: 100%;
}
.PrimaryButton_primary__f7fhK.PrimaryButton_noBorder__8eiH4 {
  border: none;
}
.PrimaryButton_primary__f7fhK.PrimaryButton_noBackground__AX-5f {
  background-color: transparent;
}
.PrimaryButton_primary__f7fhK.PrimaryButton_noBackground__AX-5f:hover {
  color: var(--primary-color) !important;
}
.PrimaryButton_primary__f7fhK.PrimaryButton_icon__MRslj {
  display: flex;
  align-items: center;
}
.PrimaryButton_primary__f7fhK.PrimaryButton_icon__MRslj span {
  margin-right: 8px;
  display: flex;
}
.PrimaryButton_primary__f7fhK.PrimaryButton_icon__MRslj span.PrimaryButton_rightIcon__hajWg {
  margin-right: 0;
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/PrimaryButton/PrimaryButton.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,2BAAA;EAEA,kEACE;AADJ;AAIE;EACE,sCAAA;EACA,2BAAA;AAFJ;AAIM;EACE,4BAAA;AAFR;AAOE;EACE,6CAAA;AALJ;AAOE;EACE,2BAAA;EACA,mBAAA;AALJ;AAOE;EACE,yBAAA;AALJ;AAOE;EACE,WAAA;AALJ;AAOE;EACE,YAAA;AALJ;AAQE;EACE,6BAAA;AANJ;AAOI;EACE,sCAAA;AALN;AAQE;EACE,aAAA;EACA,mBAAA;AANJ;AAOI;EACE,iBAAA;EACA,aAAA;AALN;AAMM;EACE,eAAA;EACA,gBAAA;AAJR","sourcesContent":[".primary {\n  background: var(--primary-color);\n  font-size: 20px;\n  line-height: 26px;\n  font-weight: 500;\n  border-radius: 8px;\n  padding: 16px;\n  border: none;\n  color: var(--neutral-white);\n\n  transition:\n    background-color 0.3s,\n    box-shadow 0.3s,\n    transform 0.1s;\n  &:hover {\n    background-color: var(--neutral-light);\n    color: var(--primary-color);\n    svg {\n      path {\n        stroke: var(--primary-color);\n      }\n    }\n  }\n\n  &:focus {\n    box-shadow: 0 0 0 3px rgba(25, 118, 210, 0.5);\n  }\n  &[disabled] {\n    background: var(--disabled);\n    cursor: not-allowed;\n  }\n  &:active {\n    background-color: #0d47a1;\n  }\n  &.fullWidth {\n    width: 100%;\n  }\n  &.noBorder {\n    border: none;\n  }\n\n  &.noBackground {\n    background-color: transparent;\n    &:hover {\n      color: var(--primary-color) !important;\n    }\n  }\n  &.icon {\n    display: flex;\n    align-items: center;\n    span {\n      margin-right: 8px;\n      display: flex;\n      &.rightIcon {\n        margin-right: 0;\n        margin-left: 8px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `PrimaryButton_primary__f7fhK`,
	"fullWidth": `PrimaryButton_fullWidth__V2NLP`,
	"noBorder": `PrimaryButton_noBorder__8eiH4`,
	"noBackground": `PrimaryButton_noBackground__AX-5f`,
	"icon": `PrimaryButton_icon__MRslj`,
	"rightIcon": `PrimaryButton_rightIcon__hajWg`
};
export default ___CSS_LOADER_EXPORT___;

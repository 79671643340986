import React, { useState } from 'react';
import styles from './Searchbar.module.scss';
import { MapPin, Search } from 'lucide-react';

interface SearchResult {
  id: number;
  label: string;
}

interface SearchBarProps {
  placeholder?: string;
  onSearchChange: (value: string) => void;
  results: SearchResult[];
  onChange?: (value: string) => void;
  label?: string;
  reff?: any;
}

export const Searchbar: React.FC<SearchBarProps> = ({
  placeholder = 'Search...',
  onSearchChange,
  results,
  label,
  onChange,
  reff,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    onSearchChange(newValue);
    onChange?.(newValue);
  };

  const handleSelectResult = (label: string) => {
    setSearchValue(label);
    onChange?.(label);
  };

  const showResults = isFocused && searchValue.length > 0;

  return (
    <div className={styles.searchBarContainer}>
      {label && <label>{label}</label>}
      <div className={styles.searchInput}>
        <Search size={16} />
        <input
          type='text'
          ref={reff}
          value={searchValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 100)}
        />
      </div>
      {/* {showResults && (
        <ul className={styles.resultsList}>
          {results.map((result) => (
            <li
              key={result.id}
              className={styles.resultItem}
              onMouseDown={() => handleSelectResult(result.label)}
            >
              <MapPin /> {result.label}
            </li>
          ))}
        </ul>
      )} */}
    </div>
  );
};

import Avatar from 'react-avatar';
import { InputFieldWithController, Modal, PrimaryButton, SecondaryButton } from '../../common';
import styles from './NewWorkspaceModal.module.scss';
import { Divider } from 'rsuite';
import { useAxios } from '../../../api';
import { useForm } from 'react-hook-form';
import { Dispatch, SetStateAction, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CheckCircle, Trash2, X } from 'lucide-react';
import { WorkspaceProps } from '../../../context/WorkspaceContext';
import { UploadButton } from '../../UploadButton';
import { useSession } from '../../../context/SessionContext';

interface NewWorkspaceModalProps {
  open: boolean;
  setOpen: () => void;
  setWorkspaceList: Dispatch<SetStateAction<WorkspaceProps[]>>;
}

export const NewWorkspaceModal = ({ open, setOpen, setWorkspaceList }: NewWorkspaceModalProps) => {
  const { response, makeRequest } = useAxios('workspace');
  const { makeRequest: listRequest, response: workspaceList } = useAxios('workspace');
  const { response: sessionData, makeRequest: makeSessionDataRequest } = useAxios('auth/session');

  const { handleSubmit, control, reset } = useForm();
  const { session, setSession } = useSession();

  useEffect(() => {
    if (response) {
      toast.success('Workspace successfully created', {
        duration: 4000,
      });
      setOpen();
      reset();
      listRequest({ method: 'get' });
      makeSessionDataRequest({ method: 'get' });
    }
  }, [response]);

  useEffect(() => {
    if (sessionData) {
      const data = (sessionData as { data: Record<string, unknown>[] }).data;
      setSession(data);
    }
  }, [sessionData]);

  useEffect(() => {
    reset({});
  }, [reset]);

  useEffect(() => {
    if (workspaceList) {
      // console.log(workspaceList);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setWorkspaceList((workspaceList as WorkspaceProps)?.data);
    }
  }, [workspaceList]);

  const onSubmit = (data: Record<string, unknown>) => {
    makeRequest({ method: 'post', data: data });
  };

  return (
    <Modal open={open} closeModal={setOpen} title='Create a new workspace'>
      <div className={styles.workspace}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <p>To get started, please give your workspace a name.</p>
          <InputFieldWithController
            type='text'
            name='name'
            control={control}
            label='Workspace name'
          />
          <div className={styles.upload}>
            <Avatar name='A' size='75' round='8px' />
            <div>
              <p>Upload an image to personalize your workspace (optional). </p>
              <span className={styles.uploadBtn}>
                <UploadButton onImageChange={() => {}} />
                <SecondaryButton
                  value='Delete Photo'
                  noBackground={true}
                  noBorder={true}
                  icon={<Trash2 size={16} />}
                  textColor='#ea3c50'
                />
              </span>
            </div>
          </div>
          <Divider className='divider' />
          <div className={styles.btn}>
            <PrimaryButton value='Create Workspace' type='submit' icon={<CheckCircle />} />
            <PrimaryButton
              value='Cancel'
              icon={<X />}
              bgColor='#C7DCE8'
              textColor='#06417E'
              onClick={setOpen}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

import styles from './Header.module.scss';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/homerank-logo.svg';
import { ChevronLeft } from 'lucide-react';
interface HeaderProps {
  title: string;
  content: string;
  logo?: boolean;
  stepper?: boolean;
  count?: number;
}

export const Header = ({ title, content, logo, stepper, count }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <>
      {logo ? <Logo /> : null}
      <div className={styles.content}>
        {stepper ? (
          <button onClick={() => navigate(-1)} type='button' className={styles.stepper}>
            <ChevronLeft /> Step {count}
          </button>
        ) : null}
        <h1>{title}</h1>
        <p>{content}</p>
      </div>
    </>
  );
};

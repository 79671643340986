import { BrowserRouter } from 'react-router-dom';
import { Routing } from './components/Routes';
import 'rsuite/dist/rsuite.min.css';
import { Toaster } from 'react-hot-toast';
import { AppProvider } from './context';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';
import { LoadScript } from '@react-google-maps/api';

const apiKey = `${process.env.REACT_APP_GOOGLE_API}`;
// Replace with your Google API key

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <LoadScript
          googleMapsApiKey={apiKey}
          libraries={['places']}
          // onLoad={() => console.log('Google Maps API loaded successfully')}
          // onError={() => console.error('Error loading Google Maps API')}
        >
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Toaster />
              <Routing />
            </PersistGate>
          </Provider>
        </LoadScript>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;

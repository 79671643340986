// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Auth_container__TLYdG {
  height: 100vh;
}

.Auth_authWrapper__LkWSF {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
}
@media (max-width: 1024px) {
  .Auth_authWrapper__LkWSF {
    height: 100vh;
  }
}
.Auth_authWrapper__LkWSF .Auth_left__nCNI0 {
  grid-column: span 7;
}
@media (max-width: 1024px) {
  .Auth_authWrapper__LkWSF .Auth_left__nCNI0 {
    display: none;
  }
}
.Auth_authWrapper__LkWSF .Auth_left__nCNI0 span img {
  height: 100vh;
  padding: 25px 24px;
  width: 100%;
}
.Auth_authWrapper__LkWSF .Auth_right__UHcpz {
  grid-column: span 5;
  gap: 32px;
  display: grid;
  width: 70%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .Auth_authWrapper__LkWSF .Auth_right__UHcpz {
    grid-column: span 12;
  }
}`, "",{"version":3,"sources":["webpack://./src/containers/Auth/Auth.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sCAAA;EACA,mBAAA;AACF;AAAE;EAJF;IAKI,aAAA;EAGF;AACF;AAFE;EACE,mBAAA;AAIJ;AAHI;EAFF;IAGI,aAAA;EAMJ;AACF;AALI;EACE,aAAA;EACA,kBAAA;EACA,WAAA;AAON;AAJE;EACE,mBAAA;EACA,SAAA;EACA,aAAA;EACA,UAAA;EACA,cAAA;AAMJ;AALI;EANF;IAOI,oBAAA;EAQJ;AACF","sourcesContent":[".container {\n  height: 100vh;\n}\n\n.authWrapper {\n  display: grid;\n  grid-template-columns: repeat(12, 1fr);\n  align-items: center;\n  @media (max-width: 1024px) {\n    height: 100vh;\n  }\n  .left {\n    grid-column: span 7;\n    @media (max-width: 1024px) {\n      display: none;\n    }\n    span img {\n      height: 100vh;\n      padding: 25px 24px;\n      width: 100%;\n    }\n  }\n  .right {\n    grid-column: span 5;\n    gap: 32px;\n    display: grid;\n    width: 70%;\n    margin: 0 auto;\n    @media (max-width: 1024px) {\n      grid-column: span 12;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Auth_container__TLYdG`,
	"authWrapper": `Auth_authWrapper__LkWSF`,
	"left": `Auth_left__nCNI0`,
	"right": `Auth_right__UHcpz`
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState } from 'react';
import { Badge, Searchbar, SecondaryButton, Table, Tabs } from '../../components/common';
import styles from './Dashboard.module.scss';
import { UpgradePlan } from '../../components/UpgradePlan';
import { usePageTitle } from '../../context/PageTitleContext';
import { CalendarClock, Trash } from 'lucide-react';
import { useSession } from '../../context/SessionContext';
import { useWorkspace } from '../../context/WorkspaceContext';

const dummyData = [
  { id: 1, label: '327 Loring Ave Mill Valley, CA 94941' },
  { id: 2, label: '327 Lorton Avenue Burlingames, CA' },
];

const todoData: Record<string, unknown>[] = [
  {
    id: 1,
    market: 'Sausalito, CA',
    add: '10/30/2023',
  },
  {
    id: 2,
    market: 'Sausalito, CA',
    add: '10/30/2023',
  },
  {
    id: 3,
    market: 'Sausalito, CA',
    add: '10/30/2023',
  },
  {
    id: 4,
    market: 'Sausalito, CA',
    add: '10/30/2023',
  },
  {
    id: 5,
    market: 'Sausalito, CA',
    add: '10/30/2023',
  },
];

interface sessionProps {
  firstName: string;
  lastName: string;
}

export const Dashboard = () => {
  const { session } = useSession();
  const { setPageTitle } = usePageTitle();
  const [results, setResults] = useState<{ id: number; label: string }[]>([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setPageTitle('');
  }, []);

  const handleSearchChange = (value: string) => {
    if (value === '') {
      setResults([]);
    } else {
      const filteredResults = dummyData.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      );
      setResults(filteredResults);
    }
  };

  const handleSort = (sortColumn: string, sortType?: 'asc' | 'desc') => {
    console.log(sortColumn, sortType);
  };

  const dueDateRenderer = () => {
    return (
      <div>
        <Badge label='Due 04/14' icon={<CalendarClock />} />
      </div>
    );
  };

  const toggleRenderer = () => {
    return (
      <div className={styles.property}>
        <img src='/assets/banner4.jpg' alt='' />
        <div>
          <h5>161 Harrison Ave, Sausalito, CA 94965</h5>
        </div>
      </div>
    );
  };

  const actionRenderer = () => {
    return (
      <div className={styles.action}>
        <SecondaryButton bgColor='#06417E' textColor='#fff' value='View details' />
        <SecondaryButton
          className={styles.delete}
          icon={<Trash />}
          onClick={() => console.log('Delete')}
        />
      </div>
    );
  };

  const columnsTodo = [
    {
      title: 'Property',
      dataKey: 'property',
      sortable: true,
      width: 400,
      renderer: toggleRenderer,
    },
    { title: 'Housing market', dataKey: 'market', sortable: true, flexGrow: 1 },
    { title: 'Added date', dataKey: 'add', sortable: true, flexGrow: 1 },
    {
      title: 'due date',
      dataKey: 'due',
      renderer: dueDateRenderer,
      sortable: true,
      // width: 200,
      flexGrow: 1,
    },
    { title: 'Actions', dataKey: 'actions', renderer: actionRenderer, flexGrow: 1 },
  ];

  const todoRenderer = (
    <>
      <Table
        height={400}
        data={todoData}
        columns={columnsTodo}
        pageSize={10}
        onSort={handleSort}
        loading={false}
      />
    </>
  );

  const tabTitles = ['My To-do list', 'My recent toggles', 'Channel updates'];
  const tabContents = [
    { content: todoRenderer, key: 'todo' },
    { content: <p>test</p>, key: 'recent' },
    { content: <p>test</p>, key: 'updates' },
  ];

  // const workspace = useWorkspace();

  // console.log(workspace);

  return (
    <div className={styles.dashboard}>
      <div className={styles.searchWrap}>
        <div className={styles.search}>
          <h2>Hello again, {(session as sessionProps)?.firstName}</h2>
          <h5>
            Search for a property to toggle or <span>ask an AI Chatbot</span>
          </h5>
          <Searchbar
            placeholder='Search by property address or MLS ID'
            onSearchChange={handleSearchChange}
            results={results}
          />
        </div>
        <div className={styles.upgrade}>
          <UpgradePlan />
        </div>
      </div>
      <div className={styles.new}>
        <h2>What's new on your workspace</h2>
        <Tabs
          tabTitles={tabTitles}
          tabContents={tabContents}
          defaultIndex={tabIndex}
          setTabIndex={(index: number) => setTabIndex(index)}
        />
      </div>
    </div>
  );
};

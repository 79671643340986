import React, {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  FunctionComponent,
  ReactNode,
} from 'react';

interface PageTitleContextProps {
  pageTitle: string | ReactNode;
  setPageTitle: Dispatch<SetStateAction<string | ReactNode>>;
}

export const PageTitleContext = React.createContext<PageTitleContextProps>({
  pageTitle: '',
  setPageTitle: () => {},
});

export const usePageTitle = () => useContext(PageTitleContext);

interface PageTitleProviderProps {
  children: React.ReactNode;
}

export const PageTitleProvider: FunctionComponent<PageTitleProviderProps> = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string | ReactNode>('');
  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </PageTitleContext.Provider>
  );
};

import { Header } from '../Header';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputFieldWithController, PrimaryButton } from '../../common';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../../api';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { ERROR } from '../../../constants/common';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required(ERROR.REQUIRED_FEILD),
});

export const ResetPassword = () => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { response, error, makeRequest } = useAxios('auth/reset-password');

  useEffect(() => {
    if (response) {
      navigate('/auth/verifyaccount');
    }
    if (error) {
      // console.log(error);
      const message = error?.response?.data as Record<string, string>;
      toast.error(message?.message, {
        duration: 4000,
      });
    }
  }, [response, error]);

  const onSubmit = (data: Record<string, unknown>) => {
    localStorage.setItem('email', data.email as string);
    makeRequest({ method: 'post', data: data });
  };

  return (
    <>
      <Header
        stepper
        count={1}
        title='Reset your password'
        content='Please enter your registered email or username to continue.'
      />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <InputFieldWithController
          name='email'
          type='email'
          label='Email or username'
          control={control}
          errors={errors}
        />
        <PrimaryButton type='submit' value='Submit' />
      </form>
    </>
  );
};

import { ReactNode } from 'react';
import { Modal as CustomModal, ModalProps } from 'rsuite';
import styles from './Modal.module.scss';
import { X } from 'lucide-react';
interface CustomModalProps extends ModalProps {
  children: ReactNode;
  open: boolean;
  closeModal: () => void;
  title?: string;
  className?: string;
}

export const Modal = ({
  children,
  open,
  closeModal,
  className,
  title,
  ...props
}: CustomModalProps) => {
  return (
    <CustomModal
      className={`${className}`}
      dialogClassName={`${styles.modal}`}
      open={open}
      onClose={closeModal}
      backdropClassName={styles.overlay}
      {...props}
    >
      {title ? (
        <CustomModal.Header closeButton={false} className={styles.header}>
          <CustomModal.Title>{title}</CustomModal.Title>
          <X onClick={closeModal} />
        </CustomModal.Header>
      ) : null}
      {children}
    </CustomModal>
  );
};

import { useEffect } from 'react';
import { usePageTitle } from '../../../context/PageTitleContext';
import { useNavigate } from 'react-router-dom';
import { IconButton, Dropdown, SecondaryButton, InputField, DateRangePicker } from '../../common';
import styles from './ReportSearch.module.scss';
import { useForm } from 'react-hook-form';
import { ERROR } from '../../../constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ArrowLeftCircle, MapPin, MessageSquare, Search } from 'lucide-react';
import { endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { DateRange } from 'rsuite/esm/DateRangePicker';

const optionsList = [
  {
    label: 'Sausalito, CA',
    value: 'Sausalito, CA',
  },
  {
    label: 'Sausalito, CA',
    value: 'Sausalito, CA',
  },
  {
    label: 'Sausalito, CA',
    value: 'Sausalito, CA',
  },
];

const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()] as DateRange,
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())] as DateRange,
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()] as DateRange,
  },
];

const validationSchema = Yup.object().shape({
  search: Yup.string().required(ERROR.REQUIRED_FEILD),
  unit: Yup.string(),
  propertySize: Yup.string().required(ERROR.REQUIRED_FEILD),
  lotSize: Yup.string().required(ERROR.REQUIRED_FEILD),
  bathrooms: Yup.string().required(ERROR.REQUIRED_FEILD),
  bedroom: Yup.string().required(ERROR.REQUIRED_FEILD),
  price: Yup.string().required(ERROR.REQUIRED_FEILD),
  urls: Yup.array().of(
    Yup.object().shape({
      url: Yup.string().required(ERROR.REQUIRED_FEILD),
    })
  ),
});

export const ReportSearch = () => {
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();

  const { handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      urls: [{ url: '' }],
    },
  });

  const header = (
    <div className='page-header'>
      <IconButton
        icon={<ArrowLeftCircle size={64} strokeWidth='1' color='#14192F' />}
        onClick={() => navigate('/properties')}
        className='shadow-less'
      />
      <h5>Create property</h5>
      <SecondaryButton
        value='Ask AI Assistant'
        icon={<MessageSquare />}
        bgColor='#06417E'
        textColor='#fff'
      />
    </div>
  );
  useEffect(() => {
    setPageTitle(header);
  }, []);

  const onSubmit = (data: Record<string, unknown>) => {
    console.log(data);
  };

  return (
    <div className={styles.ReportForm}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className={styles.propertyDetails}>
          <div className={styles.flex}>
            <InputField
              label='Search by address'
              icon={<Search size={16} />}
              placeholder='Search by address'
            />
            <InputField
              label='Search by toggle ID'
              icon={<Search size={16} />}
              placeholder='Search by toggle ID'
            />
            <Dropdown
              label='Housing market'
              options={optionsList}
              placeholder='Select Housing market'
              icon={<MapPin />}
            />
            <DateRangePicker
              label='Report creation date'
              placeholder='This Month'
              showOneCalendar
              showHeader={false}
              ranges={predefinedRanges}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

import { Header } from '../Header';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { PrimaryButton, ControlledDropdown, InputFieldWithController } from '../../common';
import { ERROR } from '../../../constants/common';
import state from '../../../constants/usState.json';
import { useAxios } from '../../../api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const validationSchema = Yup.object().shape({
  identification: Yup.string().required(ERROR.REQUIRED_FEILD),
  city: Yup.string().required(ERROR.REQUIRED_FEILD),
  state: Yup.string().required(ERROR.REQUIRED_FEILD),
  username: Yup.string().required(ERROR.REQUIRED_FEILD),
});

const optionsList = [
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'Buyer',
    value: 'buyer',
  },
  {
    label: 'Seller',
    value: 'seller',
  },
  {
    label: 'Aficionado',
    value: 'aficionado',
  },
  {
    label: 'Broker',
    value: 'broker',
  },
  {
    label: 'Agent',
    value: 'agent',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const CompleteProfile = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { response, error, makeRequest } = useAxios('auth/complete-profile');

  useEffect(() => {
    if (response) {
      reset({
        identification: '',
        city: '',
        state: '',
        username: '',
      });
      navigate('/');
    }
    if (error) {
      // console.log(error);
      const message = error?.response?.data as Record<string, string>;
      toast.error(message?.message, {
        duration: 4000,
      });
    }
  }, [response, error]);

  const onSubmit = (data: Record<string, unknown>) => {
    // console.log(data);
    delete data.identification;
    makeRequest({ method: 'put', data: data });
  };

  return (
    <>
      <Header
        title='Tell us more about you'
        content='This will help us create the best experience possible to you and our community.'
        stepper
        count={1}
      />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ControlledDropdown
          control={control}
          name='identification'
          options={optionsList}
          errors={errors}
          placeholder='Select the option that best describes you'
          label='I am a'
        />
        <div className='inlinefields'>
          <InputFieldWithController
            name='city'
            label='City'
            control={control}
            errors={errors}
            type='text'
          />
          <ControlledDropdown
            control={control}
            name='state'
            options={state}
            errors={errors}
            placeholder='Select State'
            label='State'
          />
        </div>
        <InputFieldWithController
          name='username'
          label='Choose a username'
          control={control}
          errors={errors}
          type='text'
          helperText='This is your HomeRank community handle.'
        />
        <PrimaryButton type='submit' value='Complete Profile' />
      </form>
    </>
  );
};

import { NavLink } from 'react-router-dom';
import routesConfig from '../../Routes/RoutesConfig';
import styles from './Navigation.module.scss';

const NavigationMenu: React.FC = () => {
  return (
    <ul className={styles.navLinks}>
      {routesConfig.map((route) => (
        <li key={route.path}>
          <NavLink to={route.path} className={({ isActive }) => (isActive ? styles.active : '')}>
            <route.icon /> <span>{route.label}</span>
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default NavigationMenu;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown_formControl__8MUYC {
  width: 100%;
}
.Dropdown_formControl__8MUYC .Dropdown_label__-3iaY {
  color: var(--neutral-medium);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 8px;
  display: block;
}
.Dropdown_formControl__8MUYC .Dropdown_dropdownInput__oPOAt {
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 14px 12px;
  color: var(--neutral-medium);
  font-size: 16px;
  font-weight: 500;
}
.Dropdown_formControl__8MUYC .Dropdown_dropdownInput__oPOAt .react-dropdown-select-content input {
  width: 90%;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.Dropdown_formControl__8MUYC .Dropdown_dropdownInput__oPOAt .react-dropdown-select-content input::placeholder {
  color: var(--placeholder);
  font-size: 16px;
  font-weight: 400;
}
.Dropdown_formControl__8MUYC .Dropdown_dropdownContainer__uhq\\+7 {
  position: relative;
}
.Dropdown_formControl__8MUYC .Dropdown_dropdownContainer__uhq\\+7 .Dropdown_inputIcon__MKo2T {
  padding-left: 40px;
}
.Dropdown_formControl__8MUYC .Dropdown_dropdownContainer__uhq\\+7 .Dropdown_icon__AVWLG {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.Dropdown_formControl__8MUYC .Dropdown_dropdownContainer__uhq\\+7 .Dropdown_icon__AVWLG svg {
  width: 16px;
}
.Dropdown_formControl__8MUYC .Dropdown_dropdownContainer__uhq\\+7 .Dropdown_icon__AVWLG svg path {
  stroke: var(--neutral-medium);
}`, "",{"version":3,"sources":["webpack://./src/components/common/Dropdown/Dropdown.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,4BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AAEJ;AAAE;EACE,kBAAA;EACA,qCAAA;EACA,kBAAA;EACA,4BAAA;EACA,eAAA;EACA,gBAAA;AAEJ;AAAM;EACE,UAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;AAER;AADQ;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;AAGV;AAEE;EACE,kBAAA;AAAJ;AACI;EACE,kBAAA;AACN;AACI;EACE,kBAAA;EACA,UAAA;EACA,MAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;AACN;AAAM;EACE,WAAA;AAER;AADQ;EACE,6BAAA;AAGV","sourcesContent":[".formControl {\n  width: 100%;\n  .label {\n    color: var(--neutral-medium);\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 18px;\n    padding-bottom: 8px;\n    display: block;\n  }\n  .dropdownInput {\n    border-radius: 4px;\n    border: 1px solid var(--border-color);\n    padding: 14px 12px;\n    color: var(--neutral-medium);\n    font-size: 16px;\n    font-weight: 500;\n    :global .react-dropdown-select-content {\n      input {\n        width: 90%;\n        position: absolute;\n        top: 0;\n        bottom: 0;\n        height: 100%;\n        margin: 0 auto;\n        display: flex;\n        align-items: center;\n        &::placeholder {\n          color: var(--placeholder);\n          font-size: 16px;\n          font-weight: 400;\n        }\n      }\n    }\n  }\n  .dropdownContainer {\n    position: relative;\n    .inputIcon {\n      padding-left: 40px;\n    }\n    .icon {\n      position: absolute;\n      left: 10px;\n      top: 0;\n      bottom: 0;\n      display: flex;\n      align-items: center;\n      svg {\n        width: 16px;\n        path {\n          stroke: var(--neutral-medium);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formControl": `Dropdown_formControl__8MUYC`,
	"label": `Dropdown_label__-3iaY`,
	"dropdownInput": `Dropdown_dropdownInput__oPOAt`,
	"dropdownContainer": `Dropdown_dropdownContainer__uhq+7`,
	"inputIcon": `Dropdown_inputIcon__MKo2T`,
	"icon": `Dropdown_icon__AVWLG`
};
export default ___CSS_LOADER_EXPORT___;

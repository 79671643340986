import { useEffect, useState } from 'react';
import { usePageTitle } from '../../context/PageTitleContext';
import styles from './CommunityProfile.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, IconButton, SecondaryButton } from '../common';
import {
  ArrowLeftCircleIcon,
  Briefcase,
  CalendarDays,
  ChevronsUpDown,
  Edit,
  Hash,
  MapPin,
} from 'lucide-react';
import { ChannelsCard } from '../Channels';
import { ChannelModal } from '../Channels/ChannelModal';

const properties = [
  {
    id: 1,
    image: '/assets/banner4.jpg',
    address: '32 Pine St, Sausalito, CA',
    city: 'Sausalito',
    state: 'CA',
    zip: '94965',
    price: 6850000,
    beds: 4,
    baths: 6,
    sqft: 3300,
    dueDate: '04/14',
    togglesCount: 4,
    EstimatedValue: 'Estimated value',
    EstimatedValue2: 'Home Value Ratio (HVR)',
    EstimatedValueCount: '$ 4,288,854',
    EstimatedValueCount1: '100%',
    EstimatedValueCount2: '178%',
    ProfileDes:
      'I just toggled this property in Sausalito. Listing price is lower than the toggle estimated value. I wonder if I should get a HomeFacts Report to get more insights... Thoughts?',
    profileDetails: (
      <>
        <strong>Chris P</strong> <span>@mike_chaplin</span> <span>2 min</span>
      </>
    ),
  },

  {
    id: 2,
    image: '/assets/banner4.jpg',
    address: '2118 Thornridge Cir. Syracuse, Sausalito, CA',
    city: 'Sausalito',
    state: 'CA',
    zip: '94965',
    price: 1234567,
    beds: 4,
    baths: 6,
    sqft: 3300,
    dueDate: '05/22',
    togglesCount: 2,
    EstimatedValue: 'Estimated value',
    EstimatedValue2: 'Home Value Ratio (HVR)',
    EstimatedValueCount: '$ 4,288,854',
    EstimatedValueCount1: '100%',
    EstimatedValueCount2: '178%',
    ProfileDes:
      'I just toggled this property in Sausalito. Listing price is lower than the toggle estimated value. I wonder if I should get a HomeFacts Report to get more insights... Thoughts?',
    profileDetails: (
      <>
        <strong>Chris P</strong> <span>@mike_chaplin</span> <span>2 min</span>
      </>
    ),
  },
  {
    id: 3,
    image: '/assets/banner4.jpg',
    address: '32 Pine St, Sausalito, CA',
    city: 'Sausalito',
    state: 'CA',
    zip: '94965',
    price: 6850000,
    beds: 4,
    baths: 6,
    sqft: 3300,
    dueDate: '04/14',
    togglesCount: 4,
    EstimatedValue: 'Estimated value',
    EstimatedValue2: 'Home Value Ratio (HVR)',
    EstimatedValueCount: '$ 4,288,854',
    EstimatedValueCount1: '100%',
    EstimatedValueCount2: '178%',
    ProfileDes:
      'I just toggled this property in Sausalito. Listing price is lower than the toggle estimated value. I wonder if I should get a HomeFacts Report to get more insights... Thoughts?',
    profileDetails: (
      <>
        <strong>Chris P</strong> <span>@mike_chaplin</span> <span>2 min</span>
      </>
    ),
  },
  {
    id: 4,
    image: '/assets/banner4.jpg',
    address: '2118 Thornridge Cir. Syracuse, Sausalito, CA',
    city: 'Sausalito',
    state: 'CA',
    zip: '94965',
    price: 1234567,
    beds: 4,
    baths: 6,
    sqft: 3300,
    dueDate: '05/22',
    togglesCount: 2,
    EstimatedValue: 'Estimated value',
    EstimatedValue2: 'Home Value Ratio (HVR)',
    EstimatedValueCount: '$ 4,288,854',
    EstimatedValueCount1: '100%',
    EstimatedValueCount2: '178%',
    ProfileDes:
      'I just toggled this property in Sausalito. Listing price is lower than the toggle estimated value. I wonder if I should get a HomeFacts Report to get more insights... Thoughts?',
    profileDetails: (
      <>
        <strong>Chris P</strong> <span>@mike_chaplin</span> <span>2 min</span>
      </>
    ),
  },
];
export const ProfileDetail = () => {
  const [channelSelectorModalOpen, setChannelSelectorModalOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { setPageTitle } = usePageTitle();
  const header = (
    <div className='page-header'>
      <IconButton
        icon={<ArrowLeftCircleIcon size={64} strokeWidth='1' color='#14192F' />}
        onClick={() => navigate(-1)}
        className='shadow-less'
      />
      <h5>{pathname === '/myprofile' ? 'My Profile details' : 'Profile details'}</h5>
    </div>
  );
  useEffect(() => {
    setPageTitle(header);
  }, []);

  return (
    <div className={styles.ChannleListingHolder}>
      <div className={styles.Channles22}>
        <div className={styles.myProfileinformation}>
          <span className={styles.myProfileiImg}>
            <img src={'/assets/banner2.jpg'} alt='banner' />
            <span className={styles.onlineIcon}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
              >
                <circle cx='16' cy='16' r='14' fill='#00A652' stroke='white' strokeWidth='4' />
              </svg>
            </span>
          </span>
          <div className={styles.myProfileinformationHolder}>
            <div className={styles.myProfileinDes}>
              <h4>{pathname === '/myprofile' ? 'Chris P' : 'Mike C'}</h4>
              <Badge
                variant='info'
                label={pathname === '/myprofile' ? 'Workspace Owner' : 'Workspace Member'}
              />
            </div>
            <div className={styles.myProfileinDes}>
              <span>@chris_p</span>
              <span>3 channels</span>
              <span>987 toggles</span>
            </div>
            <div className={styles.myProfileinDes}>
              <span>
                <MapPin />
                Mill Valley, CA
              </span>
              <span>
                <CalendarDays />
                Member since: November 2023
              </span>
            </div>
            {pathname === '/myprofile' && (
              <div className={styles.myProfileinDes}>
                <SecondaryButton
                  bgColor='#06417E'
                  value='Edit Profile'
                  icon={<Edit size='16px' />}
                  iconPosition='left'
                  textColor='#fff'
                  onClick={() => navigate('/profile')}
                />
                <SecondaryButton
                  bgColor='#06417E'
                  textColor='#fff'
                  value='Manage Workspace'
                  icon={<Briefcase size='16px' />}
                  iconPosition='left'
                  onClick={() => navigate('/profile')}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.profileCardWrap}>
          <div className={styles.activity}>
            <h6>Channel Activity</h6>
            <div
              className={styles.selector}
              onClick={() => setChannelSelectorModalOpen(true)}
              style={{ cursor: 'pointer' }}
            >
              <Hash />
              <span>All Channels</span>
              <ChevronsUpDown />
            </div>
          </div>
          <div className={styles.channles}>
            {properties.map((property) => (
              <div key={property.id}>
                <ChannelsCard property={property} path={pathname} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.todoList}>{/* <h5>To-do list status</h5> */}</div>
      </div>
      <ChannelModal
        open={channelSelectorModalOpen}
        closeModal={() => setChannelSelectorModalOpen(false)}
      />
    </div>
  );
};

import { FC } from 'react';
import styles from './IconButton.module.scss';

interface IconButtonProps {
  icon: JSX.Element;
  onClick?: () => void;
  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
}

export const IconButton: FC<IconButtonProps> = ({
  icon,
  onClick,
  ariaLabel,
  className,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      aria-label={ariaLabel}
      className={`${styles.iconButton} ${className || ''}`}
      disabled={disabled}
    >
      {icon}
    </button>
  );
};

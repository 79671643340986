// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShareToggleModal_shareToggle__uoHnQ {
  padding: 24px;
}
.ShareToggleModal_shareToggle__uoHnQ .react-tabs__tab-panel {
  padding-top: 24px;
}

.ShareToggleModal_emailTab__iFia9 {
  display: flex;
  gap: 24px;
  flex-direction: column;
}
.ShareToggleModal_emailTab__iFia9 .ShareToggleModal_btn__s-dwY {
  display: flex;
  gap: 16px;
}
.ShareToggleModal_emailTab__iFia9 .ShareToggleModal_btn__s-dwY button {
  flex: 1 1;
  text-align: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Toggle/ShareToggleModal/ShareToggleModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,iBAAA;AAEJ;;AACA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;AAEF;AADE;EACE,aAAA;EACA,SAAA;AAGJ;AAFI;EACE,SAAA;EACA,kBAAA;EACA,uBAAA;AAIN","sourcesContent":[".shareToggle {\n  padding: 24px;\n  :global .react-tabs__tab-panel {\n    padding-top: 24px;\n  }\n}\n.emailTab {\n  display: flex;\n  gap: 24px;\n  flex-direction: column;\n  .btn {\n    display: flex;\n    gap: 16px;\n    button {\n      flex: 1;\n      text-align: center;\n      justify-content: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareToggle": `ShareToggleModal_shareToggle__uoHnQ`,
	"emailTab": `ShareToggleModal_emailTab__iFia9`,
	"btn": `ShareToggleModal_btn__s-dwY`
};
export default ___CSS_LOADER_EXPORT___;

import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../../context/PageTitleContext';
import {
  PrimaryButton,
  Tabs,
  DatePicker,
  Dropdown,
  InputField,
  Table,
  SecondaryButton,
  Badge,
} from '../../common';
import { useEffect, useState } from 'react';
import styles from './ToggleList.module.scss';
import { CalendarClock, MapPin, Plus, Search, Trash2 } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTodo,
  deleteToggle,
  getTodDoList,
  getToggleList,
} from '../../../containers/Toggles/toggleAction';
import { Reducer } from '@reduxjs/toolkit';
import { format, isBefore } from 'date-fns';
import { useDebounce } from '../../../Utils/helperFunctions';
import toast from 'react-hot-toast';
import { DateRangePicker, Dropdown as Drop } from 'rsuite';
import DropDownRender from '../../common/DropDownRender/DropDownRender';
import { isArray } from 'lodash';

interface DateRangePickerProps {
  onSelect: (value: [Date, Date] | null) => void; // Adjusted the type here
}

const DateRangePickerComponent: React.FC<DateRangePickerProps> = ({ onSelect }) => {
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null); // Adjusted the type here

  const handleSelect = (value: [Date, Date] | null) => {
    // Adjusted the type here
    setDateRange(value);
    onSelect(value);
  };

  return <DateRangePicker value={dateRange} onChange={handleSelect} style={{ width: 300 }} />;
};

// export default DateRangePickerComponent;

// export default DropdownComponent;

const options = [
  { label: 'Overdue', value: 'Overdue' },
  { label: 'Due', value: 'Due' },
];

const dateOptions = [
  { label: 'Today', value: 'today' },
  { label: 'This Week', value: 'this_week' },
  { label: 'This Month', value: 'this_month' },
  { label: 'Date Range', value: 'dateRange' },
];

const header = (
  <div className='page-header'>
    <h5>Toggles</h5>
    {/* <PrimaryButton
      onClick={() => navigate('/toggle/create')}
      bgColor='#C7DCE8'
      textColor='#06417E'
      icon={<Plus />}
      value='Add New'
    /> */}
  </div>
);

export const ToggleList = () => {
  const { setPageTitle } = usePageTitle();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch: any = useDispatch();
  const { toggleList, todoList } = useSelector((state: any) => state.toggle);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const [filter, setFilter] = useState<any>({
    date: '',
    status: '',
    dueDateValue: '',
  });
  const [dueDateValue, setDueDateValue] = useState<any>('');

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleChangeStatus = (e: any) => {
    console.log(e);
    setFilter({ ...filter, status: e[0].value });
  };

  useEffect(() => {
    setPageTitle(header);
    if (tabIndex === 0) {
      dispatch(
        getToggleList(
          {
            // limit: 5,
            // page: 1,
            ...(searchTerm && { searchKey: searchTerm }),
            ...(filter.dueDateValue && { dateType: filter.dueDateValue }),
          },
          () => {}
        )
      );
    }
    if (tabIndex === 1) {
      dispatch(
        getTodDoList(
          {
            // limit: 5,
            // page: 1,
            ...(searchTerm && { searchKey: searchTerm }),
            ...(filter.status && { status: filter.status }),
            ...(filter.dueDateValue && { dateType: filter.dueDateValue }),
          },
          () => {}
        )
      );
    }
  }, [tabIndex, debouncedSearchTerm, filter]);

  const todoPropertyRenderer = (data: any) => {
    // console.log(data);
    return (
      <div className={styles.property}>
        <img src='/assets/banner4.jpg' alt='' />
        <div>
          <h5>{data?.propertyDetails?.address || 'N/A'}</h5>
        </div>
      </div>
    );
  };

  const toggleRenderer = (data: any) => {
    // console.log(data);
    return (
      <div className={styles.property}>
        <img src='/assets/banner4.jpg' alt='' />
        <div>
          <h5>{data?.propertyDetails?.address || 'N/A'}</h5>
        </div>
      </div>
    );
  };

  const actionRenderer = (rowData: Record<string, unknown>) => {
    // console.log(rowData);
    return (
      <div className={styles.action}>
        {/* <SecondaryButton
          bgColor='#06417E'
          textColor='#fff'
          value='View details'
          onClick={() => navigate(`/propertyDetail/${rowData.id}`)}
        /> */}
        <SecondaryButton
          className={styles.delete}
          icon={<Trash2 />}
          onClick={() =>
            dispatch(
              deleteTodo(rowData?._id, () => {
                toast.success('Todo Delete Successfully.', {
                  duration: 4000,
                });
                dispatch(
                  getTodDoList(
                    {
                      ...(searchTerm && { searchKey: searchTerm }),
                    },
                    () => {}
                  )
                );
              })
            )
          }
        />
      </div>
    );
  };

  const toggleActionRenderer = (rowData: Record<string, unknown>) => {
    // console.log(rowData);
    return (
      <div className={styles.action}>
        {/* <SecondaryButton
          bgColor='#06417E'
          textColor='#fff'
          value='View details'
          onClick={() => navigate(`/propertyDetail/${rowData._id}`)}
        /> */}
        <SecondaryButton
          className={styles.delete}
          icon={<Trash2 />}
          onClick={() =>
            dispatch(
              deleteToggle(rowData?._id, () => {
                toast.success('Toggle Delete Successfully.', {
                  duration: 4000,
                });
                dispatch(
                  getToggleList(
                    {
                      ...(searchTerm && { searchKey: searchTerm }),
                    },
                    () => {}
                  )
                );
              })
            )
          }
        />
      </div>
    );
  };

  const dueDateRenderer = (data: any) => {
    return (
      <div>
        <Badge
          variant={isBefore(data?.dueDate, new Date()) ? 'error' : 'primary'}
          label={`${isBefore(data?.dueDate, new Date()) ? 'Overdue' : 'Due'} ${format(
            data?.dueDate,
            'MM/yyyy'
          )}`}
          icon={<CalendarClock />}
        />
      </div>
    );
  };

  const handleSort = (sortColumn: string, sortType?: 'asc' | 'desc') => {
    // console.log(sortColumn, sortType);
  };

  // console.log('toggleList', toggleList);

  const columns = [
    {
      title: 'Property',
      dataKey: 'property',
      sortable: true,
      width: 400,
      renderer: toggleRenderer,
    },
    { title: 'toggle Id', dataKey: 'toggle_id', sortable: true, flexGrow: 1 },
    {
      title: 'Toggle date',
      dataKey: 'createdAt',
      sortable: true,
      flexGrow: 1,
      renderer: (data: any) => <p>{format(data.createdAt, 'yyyy-MM-dd')}</p>,
    },
    { title: 'Estimated', dataKey: 'estimated_price', sortable: true, flexGrow: 1 },
    {
      title: 'Report',
      dataKey: 'report',
      sortable: true,
      flexGrow: 1,
      renderer: () => <div>N/A</div>,
    },
    { title: 'Actions', dataKey: 'actions', renderer: toggleActionRenderer, flexGrow: 1 },
  ];

  const data: Record<string, unknown>[] = [
    {
      id: 1,
      market: 'Sausalito, CA',
      date: '10/30/2023',
      est: '$ 1,234,567',
      report: '1 HomeFact Report',
    },
    {
      id: 2,
      market: 'Sausalito, CA',
      date: '10/30/2023',
      est: '$ 1,234,567',
      report: '1 HomeFact Report',
    },
    {
      id: 3,
      market: 'Sausalito, CA',
      date: '10/30/2023',
      est: '$ 1,234,567',
      report: '1 HomeFact Report',
    },
    {
      id: 4,
      market: 'Sausalito, CA',
      date: '10/30/2023',
      est: '$ 1,234,567',
      report: '1 HomeFact Report',
    },
    {
      id: 5,
      market: 'Sausalito, CA',
      date: '10/30/2023',
      est: '$ 1,234,567',
      report: '1 HomeFact Report',
    },
  ];

  const columnsTodo = [
    {
      title: 'Property',
      dataKey: 'propertyDetails.address',
      sortable: true,
      width: 400,
      renderer: todoPropertyRenderer,
    },
    // { title: 'Housing market', dataKey: 'market', sortable: true, flexGrow: 1 },
    {
      title: 'Added date',
      dataKey: 'createdAt',
      sortable: true,
      flexGrow: 1,
      renderer: (data: any) => <p>{format(data.createdAt, 'yyyy-MM-dd')}</p>,
    },
    {
      title: 'due date',
      dataKey: 'dueDate',
      renderer: dueDateRenderer,
      sortable: true,
      flexGrow: 1,
    },
    { title: 'Actions', dataKey: 'actions', renderer: actionRenderer, flexGrow: 1 },
  ];

  const todoData: Record<string, unknown>[] = [
    {
      id: 1,
      // market: 'Sausalito, CA',
      add: '10/30/2023',
    },
    {
      id: 2,
      // market: 'Sausalito, CA',
      add: '10/30/2023',
    },
    {
      id: 3,
      // market: 'Sausalito, CA',
      add: '10/30/2023',
    },
    {
      id: 4,
      // market: 'Sausalito, CA',
      add: '10/30/2023',
    },
    {
      id: 5,
      // market: 'Sausalito, CA',
      add: '10/30/2023',
    },
  ];

  // console.log(filter);
  const selectValue = (value: any) => {
    if (isArray(value)) {
      // console.log('yesss');
      setFilter({
        ...filter,
        dueDateValue: {
          startDate: format(value[0], 'yyyy-MM-dd'),
          endDate: format(value[1], 'yyyy-MM-dd'),
        },
      });
    } else {
      setFilter({ ...filter, dueDateValue: value });
    }
  };

  const handlePageChnage = (newPage: number) => {
    // console.log(newPage);
    if (tabIndex === 0) {
      dispatch(
        getToggleList(
          {
            pageNo: newPage,
            limit: 5,
            ...(searchTerm && { searchKey: searchTerm }),
            ...(filter.dueDateValue && { dateType: filter.dueDateValue }),
          },
          () => {}
        )
      );
    }
    if (tabIndex === 1) {
      dispatch(
        getTodDoList(
          {
            pageNo: newPage,
            limit: 5,
            ...(searchTerm && { searchKey: searchTerm }),
            ...(filter.status && { status: filter.status }),
            ...(filter.dueDateValue && { dateType: filter.dueDateValue }),
          },
          () => {}
        )
      );
    }
  };

  const toggleTabRender = (
    <div>
      <div className={styles.flex}>
        <InputField
          label='Search by address'
          icon={<Search size={16} />}
          placeholder='Search by address'
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <DropDownRender label='Select Dates' selectValue={selectValue} />
        {/* <Dropdown
          label='Housing market'
          options={options}
          placeholder='Select Housing market'
          icon={<MapPin />}
        /> */}
      </div>
      <Table
        height={400}
        data={toggleList?.data || []}
        columns={columns}
        pageSize={5}
        onSort={handleSort}
        loading={false}
        pagination
        paginationData={toggleList}
        onPangeChange={handlePageChnage}
        limit={5}
      />
    </div>
  );

  const todoTabRender = (
    <div>
      <div className={styles.flex}>
        <InputField
          label='Search by address'
          icon={<Search size={16} />}
          placeholder='Search by address'
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {/* <DatePicker label='Due date' placeholder='This Month' /> */}
        {/* <DropdownComponent /> */}
        <DropDownRender label='Select Dates' selectValue={selectValue} />

        <Dropdown
          label='To-do status'
          options={options}
          placeholder='Select Status'
          onChange={handleChangeStatus}
        />
        {/* <Dropdown
          label='Housing market'
          options={options}
          placeholder='Select Housing market'
          icon={<MapPin />}
        /> */}
      </div>
      <Table
        height={400}
        data={todoList?.data || []}
        columns={columnsTodo}
        pageSize={10}
        onSort={handleSort}
        loading={false}
        // pagination
        // paginationData={toggleList}
        // onPangeChange={handlePageChnage}
        // limit={5}
      />
    </div>
  );

  const tabTitles = ['My toggles', 'My to-do list'];
  const tabContents = [
    { content: toggleTabRender, key: 'toggle' },
    { content: todoTabRender, key: 'todo' },
  ];

  return (
    <div className={styles.toggleContainer}>
      <Tabs
        tabTitles={tabTitles}
        tabContents={tabContents}
        defaultIndex={tabIndex}
        setTabIndex={(index: number) => setTabIndex(index)}
      />
    </div>
  );
};

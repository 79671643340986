import { useEffect, useState } from 'react';
import { PropertiesListing } from '../../components/Properties';
import styles from './Properties.module.scss';
import { getProperteisList } from './propertiesAction';
import { useDispatch } from 'react-redux';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { getUserLocation } from '../../Utils/helperFunctions';

const containerStyle = {
  width: '100%',
  height: '100vh',
  borderRadius: '20px',
};

const center = {
  lat: 28.7041, // Default center latitude (San Francisco)
  lng: 77.1025, // Default center longitude (San Francisco)
};

export const Properties = () => {
  const dispatch: any = useDispatch();
  const [currentLocation, setCurrentLocation] = useState<any>({
    latitude: '',
    longitude: '',
  });
  const [placeName, setPlaceName] = useState<string>('');

  useEffect(() => {
    getUserLocation((result: any) => {
      if (result) {
        const { latitude, longitude } = result;
        // console.log('result', result);
        setCurrentLocation(result);
        const geocoder = new window.google.maps.Geocoder();
        const latLng = { lat: result.latitude, lng: result.longitude };

        geocoder.geocode({ location: latLng }, (results: any, status: any) => {
          if (status === 'OK') {
            if (results[0]) {
              setPlaceName(results[0].formatted_address);
              // console.log('result =>>>>', results[0].formatted_address);
            }
          } else {
            console.error('Geocoder failed due to: ', status);
          }
        });
      }
    });
  }, []);

  return (
    <div className={styles.properties}>
      <PropertiesListing placeName={placeName} />
      <div className={styles.map}>
        <div className={styles.map}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={
              currentLocation
                ? { lat: currentLocation.latitude, lng: currentLocation.longitude }
                : center
            }
            zoom={7}
            // onLoad={(mapInstance: any) => setMap(mapInstance)}
          >
            {currentLocation && (
              <Marker
                position={{ lat: currentLocation.latitude, lng: currentLocation.longitude }}
              />
            )}
          </GoogleMap>
        </div>
      </div>
    </div>
  );
};

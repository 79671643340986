import { useEffect, useState } from 'react';
import { IconButton, Table } from '../../common';
import styles from './WorkspaceUsersList.module.scss';
import { Eye, X } from 'lucide-react';

import { useAxios } from '../../../api';
import { useSession } from '../../../context/SessionContext';
import { useNavigate } from 'react-router-dom';

// interface work
interface WorkspaceUserProps {
  workspace?: {
    data: {
      _id: string;
      members: Record<string, unknown>[];
      memberDetails: Record<string, unknown>[];
    };
  };
}

interface sessionProps {
  _id: string;
}

export const WorkspaceUsersList = ({ workspace }: WorkspaceUserProps) => {
  const { session } = useSession();
  const navigate = useNavigate();
  const [todoDatas, setTodoData] = useState<Record<string, unknown>[]>([]);

  const { makeRequest, response } = useAxios('workspace/member/invite');

  useEffect(() => {
    if (workspace?.data) {
      workspace.data.memberDetails = workspace.data.memberDetails.map(
        (el: Record<string, unknown>) => {
          const roleDetails = workspace.data.members.find((member: Record<string, unknown>) => {
            return String(member.userId) === String(el._id);
          }) as Record<string, string>;

          el.role = roleDetails.role;
          el.user = `${el.firstName} ${el.lastName}`;
          return el;
        }
      );
      // console.log(workspace.data.memberDetails, '-----------------1-----------');

      setTodoData(workspace.data.memberDetails);
    } else {
      console.error('Member data is missing');
    }
  }, [workspace]);

  const handleSort = (sortColumn: string, sortType?: 'asc' | 'desc') => {
    const sortedData = [...todoDatas];
    sortedData.sort((a, b) => {
      const itemA = a[sortColumn] as string;
      const itemB = b[sortColumn] as string;

      if (sortType === 'asc') {
        return itemA.localeCompare(itemB);
      } else {
        return itemB.localeCompare(itemA);
      }
    });
    setTodoData(sortedData);
  };

  /*const toggleRenderer = () => {
    return (
      <div className={styles.property}>
        <img src='/assets/banner4.jpg' alt='' />
        <div>
          <h5>161 Harrison Ave, Sausalito, CA 94965</h5>
        </div>
      </div>
    );
  };*/

  const removeMember = (rowData: Record<string, unknown>) => {
    makeRequest({
      method: 'delete',
      data: {
        memberId: rowData._id,
        workspaceId: workspace?.data?._id,
      },
    });
    window.location.reload();
  };

  const actionRenderer = (rowData: Record<string, unknown>) => {
    return (
      <div className={styles.action}>
        <IconButton icon={<Eye />} onClick={() => navigate('/profile/mike')} />
        <IconButton icon={<X />} onClick={() => removeMember(rowData)} />
      </div>
    );
  };

  const columnsTodo = [
    { title: 'User', dataKey: 'user', sortable: true, flexGrow: 1 },
    { title: 'Username', dataKey: 'username', sortable: true, flexGrow: 1 },
    {
      title: 'Role',
      dataKey: 'role',
      sortable: true,
      flexGrow: 1,
    },
    { title: 'Actions', dataKey: 'actions', renderer: actionRenderer, flexGrow: 1 },
  ];

  const todoRenderer = (
    <>
      <Table
        height={400}
        data={todoDatas}
        columns={columnsTodo}
        pageSize={10}
        onSort={handleSort}
        loading={false}
      />
    </>
  );
  return (
    <div className={styles.dashboard}>
      <div className={styles.new}>{todoRenderer}</div>
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';

interface Properties {
  propertiesList: any;
  propertyDetails: any;
  favoutites: any;
  loading: boolean;
  error: boolean;
}

const initialState: Properties = {
  propertiesList: {},
  propertyDetails: {},
  favoutites: {},
  loading: false,
  error: false,
};
const propertiesListSlice: any = createSlice({
  name: 'properties management',
  initialState,
  reducers: {
    updateProperties: (state: any, action: any) => {
      return { ...state, ...action.payload };
    },
    resetProperties: () => {
      return initialState;
    },
  },
});

export const { updateProperties, resetProperties } = propertiesListSlice.actions;

export default propertiesListSlice.reducer;

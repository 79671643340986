import { useEffect, useState } from 'react';
import ChannelsListList from '../ChannelsListList/ChannelsIist';
import ChannelsMember from '../ChannelsMember/ChannelsMember';
import styles from './Channels.module.scss';
import { SecondaryButton, PrimaryButton, Tabs, Modal, Dropdown, IconButton } from '../../common';
import {
  ArrowLeftCircle,
  BellOff,
  BellRing,
  CheckCheck,
  ChevronsUpDown,
  Hash,
  PlusIcon,
  Settings,
  Settings2,
  Trash2,
  TrendingUp,
  UserPlus,
  X,
} from 'lucide-react';
import { Divider, FlexboxGrid } from 'rsuite';
import { ChannelsCard } from '../ChannelsCard';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../../context/PageTitleContext';
import { CreateChannelModal } from '../CreateChannelModal';
import { ChannelShareModal } from '../ChannelShareModal';
import { ChannelInvite } from '../ChannelInvite';
import { ChannelModal } from '../ChannelModal';

const properties = [
  {
    id: 1,
    image: '/assets/banner4.jpg',
    address: '32 Pine St, Sausalito, CA',
    city: 'Sausalito',
    state: 'CA',
    zip: '94965',
    price: 6850000,
    beds: 4,
    baths: 6,
    sqft: 3300,
    dueDate: '04/14',
    togglesCount: 4,
    EstimatedValue: 'Estimated value',
    EstimatedValue2: 'Home Value Ratio (HVR)',
    EstimatedValueCount: '$ 4,288,854',
    EstimatedValueCount1: '100%',
    EstimatedValueCount2: '178%',
    ProfileDes:
      'I just toggled this property in Sausalito. Listing price is lower than the toggle estimated value. I wonder if I should get a HomeFacts Report to get more insights... Thoughts?',
    profileDetails: (
      <>
        <strong>Mike C</strong> <span>@mike_chaplin</span> <span>2 min</span>
      </>
    ),
  },

  {
    id: 2,
    image: '/assets/banner4.jpg',
    address: '2118 Thornridge Cir. Syracuse, Sausalito, CA',
    city: 'Sausalito',
    state: 'CA',
    zip: '94965',
    price: 1234567,
    beds: 4,
    baths: 6,
    sqft: 3300,
    dueDate: '05/22',
    togglesCount: 2,
    EstimatedValue: 'Estimated value',
    EstimatedValue2: 'Home Value Ratio (HVR)',
    EstimatedValueCount: '$ 4,288,854',
    EstimatedValueCount1: '100%',
    EstimatedValueCount2: '178%',
    ProfileDes:
      'I just toggled this property in Sausalito. Listing price is lower than the toggle estimated value. I wonder if I should get a HomeFacts Report to get more insights... Thoughts?',
    profileDetails: (
      <>
        <strong>Mike C</strong> <span>@mike_chaplin</span> <span>2 min</span>
      </>
    ),
  },
  {
    id: 3,
    image: '/assets/banner4.jpg',
    address: '32 Pine St, Sausalito, CA',
    city: 'Sausalito',
    state: 'CA',
    zip: '94965',
    price: 6850000,
    beds: 4,
    baths: 6,
    sqft: 3300,
    dueDate: '04/14',
    togglesCount: 4,
    EstimatedValue: 'Estimated value',
    EstimatedValue2: 'Home Value Ratio (HVR)',
    EstimatedValueCount: '$ 4,288,854',
    EstimatedValueCount1: '100%',
    EstimatedValueCount2: '178%',
    ProfileDes:
      'I just toggled this property in Sausalito. Listing price is lower than the toggle estimated value. I wonder if I should get a HomeFacts Report to get more insights... Thoughts?',
    profileDetails: (
      <>
        <strong>Mike C</strong> <span>@mike_chaplin</span> <span>2 min</span>
      </>
    ),
  },
  {
    id: 4,
    image: '/assets/banner4.jpg',
    address: '2118 Thornridge Cir. Syracuse, Sausalito, CA',
    city: 'Sausalito',
    state: 'CA',
    zip: '94965',
    price: 1234567,
    beds: 4,
    baths: 6,
    sqft: 3300,
    dueDate: '05/22',
    togglesCount: 2,
    EstimatedValue: 'Estimated value',
    EstimatedValue2: 'Home Value Ratio (HVR)',
    EstimatedValueCount: '$ 4,288,854',
    EstimatedValueCount1: '100%',
    EstimatedValueCount2: '178%',
    ProfileDes:
      'I just toggled this property in Sausalito. Listing price is lower than the toggle estimated value. I wonder if I should get a HomeFacts Report to get more insights... Thoughts?',
    profileDetails: (
      <>
        <strong>Mike C</strong> <span>@mike_chaplin</span> <span>2 min</span>
      </>
    ),
  },
];

const TrendingListDeta = [
  {
    id: 1,
    TrendingListTitle: 'Condos',
    TrendingListTitleDec: '16 Members 160 toggles this week',
  },
  {
    id: 2,
    TrendingListTitle: 'Exclusive',
    TrendingListTitleDec: '8 Members 128 toggles this week',
  },
  {
    id: 3,
    TrendingListTitle: 'Public',
    TrendingListTitleDec: '32 Members 99 toggles this week',
  },
  {
    id: 4,
    TrendingListTitle: 'Celebrity Houses',
    TrendingListTitleDec: '33 Members 43 toggles this week',
  },
  {
    id: 5,
    TrendingListTitle: '[Channel Name]',
    TrendingListTitleDec: '33 Members 43 toggles this week',
  },
];

const ChannleMemberDeta = [
  {
    id: 1,
    image: '/assets/banner4.jpg',
    MemberTitle: 'Floyd',
    MemberDescription: '@SunnySideUp',
    ButtonText: 'Owner',
  },

  {
    id: 2,
    image: '/assets/banner4.jpg',
    MemberTitle: 'Francisco',
    MemberDescription: '@WiseWhale',
    ButtonText: 'Member',
  },
  {
    id: 3,
    image: '/assets/banner4.jpg',
    MemberTitle: 'Dianne',
    MemberDescription: '@MerryMantis',
    ButtonText: 'Guest',
  },
  {
    id: 4,
    image: '/assets/banner4.jpg',
    MemberTitle: 'Gwen',
    MemberDescription: '@SilentKnight',
    ButtonText: 'Member',
  },
  {
    id: 5,
    image: '/assets/banner4.jpg',
    MemberTitle: 'Bruce',
    MemberDescription: '@MysticMoose',
    ButtonText: 'Member',
  },
  {
    id: 6,
    image: '/assets/banner4.jpg',
    MemberTitle: 'Wendy',
    MemberDescription: '@EagleEye88',
    ButtonText: 'Member',
  },
  {
    id: 7,
    image: '/assets/banner4.jpg',
    MemberTitle: 'Cody',
    MemberDescription: '@EagleEye88',
    ButtonText: 'Member',
  },
];

const options = [
  { label: 'Option 1', value: 'option1' },
  { label: 'Option 2', value: 'option2' },
];
export const ChannelsListing = () => {
  const { setPageTitle } = usePageTitle();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [channelDetailModalTabIndex, setChannelDetailModalTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [channelDetailModalOpen, setChannelDetailModalOpen] = useState(false);
  const [createChannelModalOpen, setCreateChannelModalOpen] = useState(false);
  const [shareToggleModalOpen, setShareToggleModalOpen] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [channelSelectorModalOpen, setChannelSelectorModalOpen] = useState(false);
  const [notificationToggle, setNotificationToggle] = useState(false);

  const channelRenderer = (
    <>
      <ul className={styles.chalneSidebarlist}>
        {TrendingListDeta.map((TrendingList) => (
          <li key={TrendingList.id}>
            <ChannelsListList property={TrendingList} />
          </li>
        ))}
      </ul>
    </>
  );

  const tabContents = [
    { content: channelRenderer, key: 'channel' },
    { content: <p>Testingg</p>, key: 'toggle' },
  ];

  const tabTitlesPop = ['Channels', 'Toggles'];

  const aboutRender = (
    <>
      <div className={styles.ChannelDetails}>
        <Dropdown label='Managed by' options={options} placeholder='Select an option' />
        <Dropdown label='Channel name' options={options} placeholder='Select an option' />
        <Dropdown label='Channel privacy' options={options} placeholder='Select an option' />
      </div>

      <div className={styles.ChanneldetailsAbout}>
        <div className={styles.ChanneldetailsHolder}>
          <PrimaryButton className={styles.center} value='Save' />
          <PrimaryButton
            className={styles.center}
            value='Cancel'
            bgColor='#C7DCE8'
            textColor='#06417E'
          />
        </div>
        <div>
          <PrimaryButton
            className={styles.center}
            value='Delete'
            icon={<Trash2 />}
            noBackground
            noBorder
            textColor='#EA3C50'
          />
        </div>
      </div>
    </>
  );

  const ChannelMemberRender = (
    <>
      <ul className={styles.ChannleMemberList}>
        {ChannleMemberDeta.map((TrendingList) => (
          <li key={TrendingList.id}>
            <ChannelsMember property={TrendingList} />
          </li>
        ))}
      </ul>
      <Divider className='divider' />
      <PrimaryButton
        className={styles.center}
        value='Invite to channel'
        icon={<UserPlus />}
        onClick={() => {
          setChannelDetailModalOpen(false);
          setInviteModalOpen(true);
        }}
        fullWidth
      />
    </>
  );

  const channelSettingDetailTab = ['About', 'Members'];

  const tabContentsChannleDetails = [
    { content: aboutRender, key: 'about' },
    { content: ChannelMemberRender, key: 'member' },
  ];

  const header = (
    <div className='page-header'>
      <IconButton
        icon={<ArrowLeftCircle size={64} strokeWidth='1' color='#14192F' />}
        onClick={() => navigate(-1)}
        className='shadow-less'
      />
      <h5>Channels</h5>
      <PrimaryButton
        onClick={() => setCreateChannelModalOpen(true)}
        bgColor='#C7DCE8'
        textColor='#06417E'
        icon={<PlusIcon />}
        value='Create Channel'
      />
    </div>
  );
  useEffect(() => {
    setPageTitle(header);
  }, []);

  return (
    <>
      <div className={styles.ChannelListingHolder}>
        <div className={styles.Channels}>
          <div>
            <div className={styles.notificationsPannel}>
              <div className={styles.exclusive}>
                <div
                  className={styles.selector}
                  onClick={() => setChannelSelectorModalOpen(true)}
                  style={{ cursor: 'pointer' }}
                >
                  <Hash />
                  <span>Exclusive</span>
                  <ChevronsUpDown />
                </div>
                <SecondaryButton
                  textColor={notificationToggle ? '#fff' : ''}
                  bgColor={notificationToggle ? '#06417E' : ''}
                  value={notificationToggle ? 'Notifications are ON' : 'Notifications are OFF'}
                  icon={notificationToggle ? <BellRing /> : <BellOff />}
                  iconPosition='right'
                  onClick={() => setNotificationToggle(!notificationToggle)}
                />
              </div>
              <div className={styles.ExclusivebuttonHolder}>
                <PrimaryButton value='Filter' onClick={() => setOpen(true)} icon={<Settings2 />} />
                <PrimaryButton
                  value='Channel Settings'
                  onClick={() => setChannelDetailModalOpen(true)}
                  iconPosition='right'
                  icon={<Settings />}
                />
              </div>
            </div>
            <div className={styles.shareToggle} onClick={() => setShareToggleModalOpen(true)}>
              Share a toggle on this channel{' '}
            </div>
          </div>
          {properties.map((property) => (
            <div key={property.id}>
              <ChannelsCard property={property} />
            </div>
          ))}
        </div>
        <div className={styles.chalneSidebar}>
          <h2>
            <TrendingUp color='#C7DCE8' />
            Trending
          </h2>
          <div className={styles.toggleContainer}>
            <Tabs
              tabTitles={tabTitlesPop}
              tabContents={tabContents}
              defaultIndex={tabIndex}
              setTabIndex={(index: number) => setTabIndex(index)}
            />
          </div>
        </div>
      </div>
      <Modal
        open={open}
        closeModal={() => setOpen(false)}
        className={styles.filterModal}
        title='Filter channel posts'
        size='xs'
      >
        <div className={styles.filter}>
          <FlexboxGrid style={{ gap: '24px' }}>
            <Dropdown label='Filter by user' options={options} placeholder='Select an option' />
            <Dropdown
              label='Filter by housing market'
              options={options}
              placeholder='Select an option'
            />
          </FlexboxGrid>
          <Divider className='divider' />
          <FlexboxGrid className={styles.btn} style={{ flexDirection: 'column', gap: '24px' }}>
            <PrimaryButton value='Apply filters' icon={<CheckCheck />} fullWidth />
            <PrimaryButton
              className={styles.custom}
              value='Clear filters'
              icon={<X />}
              bgColor='#C7DCE8'
              textColor='#06417E'
              fullWidth
            />
          </FlexboxGrid>
        </div>
      </Modal>

      <Modal
        open={channelDetailModalOpen}
        closeModal={() => setChannelDetailModalOpen(false)}
        className={styles.filterModal}
        title='Channel details'
        size='xs'
      >
        <div className={styles.filter}>
          <FlexboxGrid style={{ gap: '24px' }}>
            <div className={styles.toggleContainerr}>
              <Tabs
                tabTitles={channelSettingDetailTab}
                tabContents={tabContentsChannleDetails}
                defaultIndex={channelDetailModalTabIndex}
                setTabIndex={(index: number) => setChannelDetailModalTabIndex(index)}
              />
            </div>
          </FlexboxGrid>
        </div>
      </Modal>
      <CreateChannelModal
        open={createChannelModalOpen}
        closeModal={() => setCreateChannelModalOpen(false)}
      />
      <ChannelShareModal
        open={shareToggleModalOpen}
        closeModal={() => setShareToggleModalOpen(false)}
      />
      <ChannelInvite open={inviteModalOpen} closeModal={() => setInviteModalOpen(false)} />
      <ChannelModal
        open={channelSelectorModalOpen}
        closeModal={() => setChannelSelectorModalOpen(false)}
      />
    </>
  );
};

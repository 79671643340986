import React, { useState, useEffect } from 'react';

interface TimerProps {
  initialTime: number;
  onCountdownComplete: () => void;
}

export const Timer: React.FC<TimerProps> = ({ initialTime, onCountdownComplete }) => {
  const [seconds, setSeconds] = useState(initialTime);

  useEffect(() => {
    if (seconds === 0) {
      onCountdownComplete();
      return;
    }
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return <>00:{formattedSeconds}</>;
};

import { Modal, Link, PrimaryButton } from '../../common';
import Avatar from 'react-avatar';
import styles from './Header.module.scss';
import { useState } from 'react';
import { usePageTitle } from '../../../context/PageTitleContext';
import { PricingPlanDialog } from '../../SubscriptionPlans';
import { ArrowLeftIcon, Bell, MessageCircle, Settings, User2 } from 'lucide-react';
import { useSession } from '../../../context/SessionContext';

interface sessionProps {
  firstName: string;
  lastName: string;
}

export const Header = () => {
  const { session } = useSession();
  const { pageTitle } = usePageTitle();
  const [toggle, setToggle] = useState(false);
  const [pricingModal, setPricingModal] = useState(false);

  const fullName = `${(session as sessionProps).firstName} ${(session as sessionProps).lastName}`;
  return (
    <div className={styles.header}>
      <h2>{pageTitle}</h2>
      <PrimaryButton
        onClick={() => setPricingModal(true)}
        value='Upgrade'
        className={styles.upgrade}
      />

      <MessageCircle />
      <Bell />
      <div
        role='button'
        tabIndex={-1}
        onClick={() => setToggle(true)}
        className={styles.profileInfo}
      >
        <Avatar name={fullName} size='48px' round={true} />
        <div className={styles.profileName}>
          <p>{fullName}</p>
          <span>Workspace Owner</span>
        </div>
      </div>
      <Modal
        open={toggle}
        size='xs'
        closeModal={() => setToggle(false)}
        className={styles.profileMenu}
      >
        <>
          <div className={styles.userInfo}>
            <Avatar name={fullName} size='48px' round={true} />
            <div>
              <p>{fullName}</p>
              <span>Workspace Owner</span>
            </div>
          </div>
          <div className={styles.profileLink}>
            <div className={styles.list}>
              <Link to='/myprofile' onclick={() => setToggle(false)}>
                <span>
                  <User2 /> My Profile
                </span>
              </Link>
              <Link to='/profile' onclick={() => setToggle(false)}>
                <span>
                  <Settings /> Settings
                </span>
              </Link>
              <Link to='/auth/login' onclick={() => localStorage.removeItem('token')}>
                <span>
                  <ArrowLeftIcon />
                  Sign Out
                </span>
              </Link>
            </div>
          </div>
        </>
      </Modal>
      <PricingPlanDialog open={pricingModal} closeModal={() => setPricingModal(false)} />
    </div>
  );
};

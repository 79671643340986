import { useForm } from 'react-hook-form';
import { Header } from '../Header';
import { PrimaryButton, InputFieldWithController, Link } from '../../common';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from '../Auth.module.scss';
import { useAxios } from '../../../api';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ERROR } from '../../../constants/common';
import { jwtDecode } from 'jwt-decode';
import { useSession } from '../../../context/SessionContext';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required(ERROR.REQUIRED_FEILD),
  password: Yup.string()
    .required(ERROR.REQUIRED_FEILD)
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/,
      'Password must contain at least one special character'
    ),
});

export const Login = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const { setSession } = useSession();
  const { code } = useParams();

  const { response, makeRequest, error } = useAxios(`auth/login${code ? '\\' + code : ''}`);
  useEffect(() => {
    // console.log(code);
    if (response) {
      // console.log(response);
      const data = (response as { data: Record<string, unknown> }).data;
      const isProfileCompleted = data?.isProfileCompleted;
      const accessToken = data?.accessToken as string;
      const firstName = data?.firstName as string;
      const lastName = data?.lastName as string;
      const fullName = firstName + ' ' + lastName;
      if (accessToken) {
        localStorage.setItem('token', accessToken);
        // Set session data in context
        const sessionData = jwtDecode(accessToken);
        setSession(sessionData);
        setSession(fullName);
      }
      if (isProfileCompleted) {
        navigate('/');
      } else {
        navigate('/auth/completeProfile');
      }
    }
    if (error) {
      console.log(error);
      const message = error?.response?.data as Record<string, string>;
      toast.error(message?.message, {
        duration: 4000,
      });
    }
  }, [response, error]);

  const onSubmit = (data: Record<string, unknown>) => {
    console.log(data);
    makeRequest({ method: 'post', data: data });
  };

  return (
    <>
      <Header title='Welcome back!' content='Sign in to continue to HomeRank.' logo />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <InputFieldWithController
          name='email'
          type='email'
          label='Email or username'
          control={control}
          errors={errors}
        />
        <InputFieldWithController
          name='password'
          type='password'
          control={control}
          errors={errors}
          label='Password'
        />
        <Link to='/auth/forgotpassword'>Forgot your password?</Link>
        <PrimaryButton type='submit' value='Log In' />
      </form>
      <div className={styles.already}>
        <p>
          Need an account? <Link to={'/auth/signup' + (code ? '/' + code : '')}>Sign up</Link>.
        </p>
      </div>
    </>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpgradePlan_upgradePlan__LXC7h {
  width: 100%;
  border-radius: 16px;
  background: var(--neutral-dark);
  overflow: hidden;
}
.UpgradePlan_upgradePlan__LXC7h img {
  height: 200px;
  object-fit: cover;
  width: 100%;
}
.UpgradePlan_upgradePlan__LXC7h .UpgradePlan_content__Ogu9D {
  padding: 16px 24px;
  display: flex;
  align-items: center;
}
.UpgradePlan_upgradePlan__LXC7h .UpgradePlan_content__Ogu9D p:first-child {
  color: var(--warning);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding: 0 0 8px;
}
.UpgradePlan_upgradePlan__LXC7h .UpgradePlan_content__Ogu9D p:last-child {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  opacity: 0.6;
  color: #fff;
}
.UpgradePlan_upgradePlan__LXC7h .UpgradePlan_content__Ogu9D button {
  border-radius: 8px;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/UpgradePlan/UpgradePlan.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EAEA,mBAAA;EACA,+BAAA;EACA,gBAAA;AAAF;AACE;EACE,aAAA;EACA,iBAAA;EACA,WAAA;AACJ;AACE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AAAI;EACE,qBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAEN;AAAI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AAEN;AAAI;EACE,kBAAA;EACA,YAAA;AAEN","sourcesContent":[".upgradePlan {\n  width: 100%;\n  // margin-left: 24px;\n  border-radius: 16px;\n  background: var(--neutral-dark);\n  overflow: hidden;\n  img {\n    height: 200px;\n    object-fit: cover;\n    width: 100%;\n  }\n  .content {\n    padding: 16px 24px;\n    display: flex;\n    align-items: center;\n    p:first-child {\n      color: var(--warning);\n      text-transform: uppercase;\n      font-size: 14px;\n      font-weight: 600;\n      line-height: 18px;\n      padding: 0 0 8px;\n    }\n    p:last-child {\n      font-size: 16px;\n      font-weight: 300;\n      line-height: 22px;\n      opacity: 0.6;\n      color: #fff;\n    }\n    button {\n      border-radius: 8px;\n      border: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upgradePlan": `UpgradePlan_upgradePlan__LXC7h`,
	"content": `UpgradePlan_content__Ogu9D`
};
export default ___CSS_LOADER_EXPORT___;

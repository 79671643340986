import React, { ReactNode, InputHTMLAttributes } from 'react';
import styles from './InputField.module.scss';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  icon?: ReactNode;
  error?: string;
  helperText?: string;
  iconPosition?: 'left' | 'right';
}

export const InputField: React.FC<InputFieldProps> = ({
  label,
  icon,
  error,
  helperText,
  iconPosition = 'left',
  ...inputProps
}) => {
  return (
    <div className={styles.formControl}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.inputWrapper}>
        {icon && iconPosition === 'left' && <span className={styles.inputIcon}>{icon}</span>}
        <input
          {...inputProps}
          className={`${styles.inputField} ${error ? styles.errorInput : ''} ${
            icon ? styles.withIcon : ''
          } ${iconPosition === 'right' ? styles.rightInput : ''}`}
        />
        {icon && iconPosition === 'right' && (
          <span className={`${styles.inputIcon} ${styles.rightIcon}`}>{icon}</span>
        )}
      </div>
      {helperText && <p className={styles.helperText}>{helperText}</p>}
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToggleList_toggleContainer__7BRmW {
  background: var(--neutral-white);
  border-radius: 16px;
  padding: 24px;
}
.ToggleList_toggleContainer__7BRmW .ToggleList_flex__KTSBe {
  display: flex;
  gap: 24px;
  margin: 0 0 24px;
}
.ToggleList_toggleContainer__7BRmW .ToggleList_flex__KTSBe > div {
  flex: 1 1;
}
.ToggleList_toggleContainer__7BRmW .react-tabs__tab-panel {
  padding-top: 16px;
}
.ToggleList_toggleContainer__7BRmW .ToggleList_property__gv-3s {
  display: flex;
  align-items: center;
  gap: 16px;
}
.ToggleList_toggleContainer__7BRmW .ToggleList_property__gv-3s img {
  width: 64px;
}
.ToggleList_toggleContainer__7BRmW .ToggleList_property__gv-3s h5 {
  font-size: 16px;
  color: var(--neutral-dark);
  font-weight: 500;
  line-height: 24px;
}
.ToggleList_toggleContainer__7BRmW .ToggleList_action__mYP3R {
  display: flex;
  align-items: center;
  gap: 16px;
}
.ToggleList_toggleContainer__7BRmW .ToggleList_action__mYP3R .ToggleList_delete__Favoc {
  padding: 8px 12px;
}
.ToggleList_toggleContainer__7BRmW .ToggleList_action__mYP3R .ToggleList_delete__Favoc span {
  margin: 0;
}
.ToggleList_toggleContainer__7BRmW .ToggleList_action__mYP3R .ToggleList_delete__Favoc svg path {
  stroke: var(--neutral-medium);
}`, "",{"version":3,"sources":["webpack://./src/components/Toggle/ToggleList/ToggleList.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,mBAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,SAAA;EACA,gBAAA;AAEJ;AADI;EACE,SAAA;AAGN;AAAE;EACE,iBAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAEJ;AADI;EACE,WAAA;AAGN;AADI;EACE,eAAA;EACA,0BAAA;EACA,gBAAA;EACA,iBAAA;AAGN;AAAE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAEJ;AADI;EACE,iBAAA;AAGN;AAFM;EACE,SAAA;AAIR;AADQ;EACE,6BAAA;AAGV","sourcesContent":[".toggleContainer {\n  background: var(--neutral-white);\n  border-radius: 16px;\n  padding: 24px;\n  .flex {\n    display: flex;\n    gap: 24px;\n    margin: 0 0 24px;\n    & > div {\n      flex: 1;\n    }\n  }\n  :global .react-tabs__tab-panel {\n    padding-top: 16px;\n  }\n  .property {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    img {\n      width: 64px;\n    }\n    h5 {\n      font-size: 16px;\n      color: var(--neutral-dark);\n      font-weight: 500;\n      line-height: 24px;\n    }\n  }\n  .action {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    .delete {\n      padding: 8px 12px;\n      span {\n        margin: 0;\n      }\n      svg {\n        path {\n          stroke: var(--neutral-medium);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleContainer": `ToggleList_toggleContainer__7BRmW`,
	"flex": `ToggleList_flex__KTSBe`,
	"property": `ToggleList_property__gv-3s`,
	"action": `ToggleList_action__mYP3R`,
	"delete": `ToggleList_delete__Favoc`
};
export default ___CSS_LOADER_EXPORT___;

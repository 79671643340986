import styles from './Badges.module.scss';

type BadgeProps = {
  label: string;
  variant?: 'success' | 'primary' | 'info' | 'error';
  icon?: JSX.Element;
};

export const Badge: React.FC<BadgeProps> = ({ label, variant = 'primary', icon }) => {
  return (
    <div className={`${styles.badge} ${styles[variant]}`}>
      {icon && <span className={styles.badgeIcon}>{icon}</span>}
      <span className={styles.badgeLabel}>{label}</span>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { usePageTitle } from '../../../context/PageTitleContext';
import styles from './PropertyDetails.module.scss';
import PropertyCard from '../PropertiesCard/PropertyCard';
import { Divider, FlexboxGrid } from 'rsuite';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { format, isBefore } from 'date-fns';
import { DatePicker, Dropdown, IconButton, Modal, PrimaryButton, Table, Badge } from '../../common';
import Avatar from 'react-avatar';
import {
  ArrowLeftCircle,
  CalendarClock,
  ExternalLink,
  Eye,
  ListChecks,
  PieChart,
  PlusIcon,
  SlidersHorizontal,
  Trash2,
} from 'lucide-react';
import { getPropertyDetails } from '../../../containers/Properties/propertiesAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddTodo,
  deleteToggle,
  getWorkSpaceDetails,
} from '../../../containers/Toggles/toggleAction';
import { useWorkspace } from '../../../context/WorkspaceContext';
import toast from 'react-hot-toast';
import { useAxios } from '../../../api';
import { useSession } from '../../../context/SessionContext';
// import { useSession } from '../context/SessionContext';

const property = {
  id: 1,
  image: '/assets/banner4.jpg',
  address: '32 Pine St, Sausalito, CA, 94965',
  city: 'Sausalito',
  state: 'CA',
  zip: '94965',
  price: 6850000,
  beds: 4,
  baths: 6,
  sqft: 3300,
  dueDate: '04/14',
  togglesCount: 4,
};

const options = [{ label: 'Manish', value: '65dcaca6ec18bd013af2659d' }];

export const PropertyDetail = () => {
  const [addToDoModal, setAddToDoModal] = useState(false);
  const { response: sessionData, makeRequest: makeSessionDataRequest } = useAxios('auth/session');

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { workspace } = useWorkspace();
  const { id } = useParams();
  const { propertyDetails } = useSelector((state: any) => state.properties);
  const { workSpaceDetails } = useSelector((state: any) => state.toggle);
  const { session }: any = useSession();

  const [addTodoData, setAddTodoData] = useState<any>({
    assignedTo: '',
    dueDate: '',
  });

  // console.log(session);

  const actionRenderer = (data: any) => {
    // console.log('data =>', data);
    return (
      <div className={styles.action}>
        {/* <IconButton icon={<Eye />} onClick={() => navigate(`/propertyDetail/${data?._id}`)} /> */}
        <IconButton
          className={styles.delete}
          disabled={session?._id !== data.userDetails?._id}
          icon={<Trash2 />}
          onClick={() =>
            dispatch(
              deleteToggle(data._id, () => {
                dispatch(getPropertyDetails(id, () => {}));
              })
            )
          }
        />
      </div>
    );
  };

  const toggleRenderer = (data: any) => {
    return (
      <div className={styles.toggleby}>
        <Avatar name='WM' size='48' round />
        <div>
          <h5>{data?.userDetails.firstName + ' ' + data?.userDetails.lastName}</h5>
          {/* <h6>@janebroker</h6> */}
        </div>
      </div>
    );
  };

  // console.log('workspace', workSpaceDetails);

  const columns = [
    {
      title: 'Toggled by',
      dataKey: 'toggledBy',
      sortable: true,
      flexGrow: 1,
      renderer: toggleRenderer,
    },
    {
      title: 'Toggle Date',
      dataKey: 'date',
      sortable: true,
      width: 150,
      renderer: (data: any) => <p>{format(data.createdAt, 'yyyy-MM-dd')}</p>,
    },
    { title: 'Estimated', dataKey: 'estimated_price', sortable: true, width: 200 },
    { title: 'Actions', dataKey: 'action', renderer: actionRenderer, width: 100 },
  ];

  const data: Record<string, unknown>[] = [
    { id: 1, date: 28, est: '$ 1,234,567' },
    { id: 2, date: 28, est: '$ 1,234,567' },
    { id: 3, date: 28, est: '$ 1,234,567' },
    { id: 4, date: 28, est: '$ 1,234,567' },
    { id: 5, date: 28, est: '$ 1,234,567' },
  ];

  const { setPageTitle } = usePageTitle();

  const header = (
    <div className='page-header'>
      <IconButton
        icon={
          <ArrowLeftCircle size={64} strokeWidth='1' color='#14192F' onClick={() => navigate(-1)} />
        }
        className='shadow-less'
      />
      <h5>Property details</h5>
      <PrimaryButton
        bgColor='#C7DCE8'
        textColor='#06417E'
        icon={<PlusIcon />}
        value='New Toggle'
        // disabled={Object.keys(propertyDetails).length === 0}
        onClick={() =>
          navigate('/toggle/create', {
            state: {
              propertyDetails: propertyDetails,
              workspace: workspace,
            },
          })
        }
      />
      <PrimaryButton
        onClick={() => setAddToDoModal(true)}
        bgColor='#C7DCE8'
        textColor='#06417E'
        icon={<ListChecks />}
        value='Add to-do'
      />
    </div>
  );

  useEffect(() => {
    setPageTitle(header);
    dispatch(getPropertyDetails(id, () => {}));

    dispatch(getWorkSpaceDetails({ id: workspace?.workspaceId }, () => {}));
  }, [workspace?.workspaceId]);

  const handleSort = (sortColumn: string, sortType?: 'asc' | 'desc') => {
    // console.log(sortColumn, sortType);
  };

  // console.log('propertyDetails =>', propertyDetails);

  const handleAddTdod = () => {
    // console.log({ ...addTodoData, propertyId: id });
    const payload = {
      ...addTodoData,
      propertyId: id,
    };
    dispatch(
      AddTodo(payload, (status: any) => {
        dispatch(getPropertyDetails(id, () => {}));

        toast.success('Todo Has Been Added Successfully', {
          duration: 4000,
        });
        setAddTodoData({
          assignedTo: '',
          dueDate: '',
        });
      })
    );

    setAddToDoModal(!addToDoModal);
  };

  return (
    <div className={styles.detailContainer}>
      <div className={styles.card}>
        <PropertyCard property={propertyDetails} />
        <div className={styles.toggle}>
          <h5>Related toggles</h5>
          <Table
            height={400}
            data={propertyDetails?.toggles || []}
            columns={columns}
            pageSize={10}
            onSort={handleSort}
            loading={false}
          />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.todoList}>
          <h5>To-do list status</h5>
          {/* <Badge label='Due 04/14' icon={<CalendarClock size={16} />} /> */}
          {propertyDetails?.todo?.dueDate ? (
            <Badge
              variant={isBefore(propertyDetails?.todo?.dueDate, new Date()) ? 'error' : 'primary'}
              label={`${
                isBefore(propertyDetails?.todo?.dueDate, new Date()) ? 'Overdue' : 'Due'
              } ${format(propertyDetails?.todo?.dueDate, 'MM/yyyy')}`}
              icon={<CalendarClock />}
            />
          ) : (
            'N/A'
          )}
          <Divider className='divider' />
          <div className={styles.links}>
            <h5>External links</h5>
            {propertyDetails?.externalLinks?.map((item: any, i: number) => (
              <Link key={item + i} to={item} target='_blank'>
                {item}
                <ExternalLink size={16} />
              </Link>
            ))}
            {/* <Link
              to='https://www.redfin.com/CA/Mill-Valley/281-Loring-Ave-94941/home/22374348'
              target='_blank'
            >
              https://www.redfin.com/CA/Mill-Valley/32...
              <ExternalLink size={16} />
            </Link> */}
          </div>
          <Divider className='divider' />
        </div>
        <div className={styles.sideContent}>
          <h5>
            <PieChart /> Side content: Statistics
          </h5>
          <ul>
            <li>Charts / Indicators for:</li>
            <li>Views</li>
            <li>Popularity (sold reports + views + toggles + shares) </li>
            <li>Toggles</li>
            <li>Averages</li>
          </ul>
        </div>
      </div>
      <Modal
        open={addToDoModal}
        size='xs'
        closeModal={() => setAddToDoModal(false)}
        title='Create to-do item'
      >
        <div className={styles.create}>
          <Dropdown
            label='Assign to'
            options={
              workSpaceDetails?.memberDetails?.map((item: any) => ({
                label: item?.firstName,
                value: item?._id,
              })) || options
            }
            onChange={(e: any) => setAddTodoData({ ...addTodoData, assignedTo: e[0].value })}
            placeholder='Select an option'
          />
          <DatePicker
            label='Due date'
            placeholder='Select Date'
            size='lg'
            // defaultValue={new Date()}
            shouldDisableDate={(date) => isBefore(date, new Date())}
            onChange={(e: any) => setAddTodoData({ ...addTodoData, dueDate: format(e, 'T') })}
          />
          <Divider className='divider' />
          <FlexboxGrid className={styles.btn}>
            <PrimaryButton
              value='Confirm'
              onClick={handleAddTdod}
              disabled={!addTodoData?.assignedTo || !addTodoData?.dueDate}
            />
            <PrimaryButton
              onClick={() => {
                setAddToDoModal(false);
                setAddTodoData({
                  assignedTo: '',
                  dueDate: '',
                });
              }}
              value='Cancel'
              bgColor='#74788C'
            />
          </FlexboxGrid>
        </div>
      </Modal>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { usePageTitle } from '../../../context/PageTitleContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton, PrimaryButton, SecondaryButton, Tabs } from '../../common';
import styles from './ToggleCreate.module.scss';
import { Divider, FlexboxGrid, Slider } from 'rsuite';
import Avatar from 'react-avatar';
import { PropertyDataModal } from '../PropertyDataModal';
import { ArrowLeftCircle, ChevronRight, Edit, MessageSquare } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleSliderAdvnace, ToggleSliderFirst } from '../../../containers/Toggles/toggleAction';

export const ToggleCreate = () => {
  const { setPageTitle } = usePageTitle();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch: any = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [editModal, setEditModal] = useState(false);
  const { toggle_Lot_location } = useSelector((state: any) => state.toggle);
  const [lotSliderValue, setLotSliderValue] = useState(toggle_Lot_location?.rank_lot_location || 0);
  const [advanceLotValue, setAdvanceLotValue] = useState<any>({
    neighborhood_slider: 0,
    location_slider: 0,
    site_location_slider: 0,
  });

  const header = (
    <div className='page-header'>
      <IconButton
        icon={<ArrowLeftCircle size={64} strokeWidth='1' color='#14192F' />}
        onClick={() => navigate(-1)}
        className='shadow-less'
      />
      <h5>Create toggle</h5>
    </div>
  );

  // console.log(' 1', state);

  useEffect(() => {
    setPageTitle(header);
  }, []);

  const handleSliderChange = (value: number) => {
    setLotSliderValue(value);
    const payload = {
      lot_location: value,
      structure_condition: 50,
      lot_sqft: 1000,
      house_sqft: 1000,
      lot_size: 1000,
      zip: '90024',
    };
    dispatch(ToggleSliderFirst(payload, 'lot_location', () => {}));
  };

  const advanceHandleSliderChange = (value: number, type: string) => {
    setAdvanceLotValue({ ...advanceLotValue, [type]: value });

    const payload: any = {
      structure_location_value: 608000,
      lot_sqft: 1000,
      house_sqft: 1000,
      zip: '90024',
      neighborhood_slider: 50,
      location_slider: 50,
      site_location_slider: 50,
    };

    payload[type] = value;

    dispatch(ToggleSliderAdvnace(payload, 'lot_location', () => {}));
  };

  // console.log(advanceLotValue);

  const basicTabRender = (
    <div className={styles.mode}>
      <div className={styles.head}>
        <div>
          <h5>Lot-location value: ${toggle_Lot_location?.lot_location_value || '00'}</h5>
          <p>
            How would you rate the <strong>neighborhood</strong>, <strong>school</strong> and{' '}
            <strong>location</strong> ?
          </p>
        </div>
        <PrimaryButton value={`Rank ${lotSliderValue}%`} />
      </div>
      <div className={styles.range}>
        <Slider
          tooltip={false}
          step={1}
          value={lotSliderValue}
          progress
          onChange={handleSliderChange}
        />
        <div className={styles.scaleMarkings}>
          {Array.from({ length: 28 }).map((_, index) => (
            <span key={index} className={styles.scaleMark}></span>
          ))}
        </div>
        <div className={styles.labels}>
          <span className={`${styles.label} ${styles.poor}`}>POOR</span>
          <span className={`${styles.label} ${styles.average}`}>AVERAGE</span>
          <span className={`${styles.label} ${styles.excellent}`}>EXCELLENT</span>
        </div>
      </div>
    </div>
  );

  const advanceTabRender = (
    <div className={styles.mode}>
      <div className={styles.filter}>
        <div className={styles.head}>
          <div>
            <h5>General zone utility: $ 0,000,000</h5>
            <p>
              How would you rate the <strong>neighborhood</strong>, <strong>school</strong> and{' '}
              <strong>location</strong> ?
            </p>
          </div>
          <PrimaryButton value={`Rank ${advanceLotValue?.neighborhood_slider}%`} />
        </div>
        <div className={styles.range}>
          <Slider
            tooltip={false}
            step={1}
            progress
            value={advanceLotValue?.neighborhood_slider}
            onChange={(e: any) => advanceHandleSliderChange(e, 'neighborhood_slider')}
          />
          <div className={styles.scaleMarkings}>
            {Array.from({ length: 28 }).map((_, index) => (
              <span key={index} className={styles.scaleMark}></span>
            ))}
          </div>
          <div className={styles.labels}>
            <span className={`${styles.label} ${styles.poor}`}>POOR</span>
            <span className={`${styles.label} ${styles.average}`}>AVERAGE</span>
            <span className={`${styles.label} ${styles.excellent}`}>EXCELLENT</span>
          </div>
        </div>
      </div>
      <div className={styles.filter}>
        <div className={styles.head}>
          <div>
            <h5>Specific location utility: $ 0,000,000</h5>
            <p>
              How would you rate the <strong>location</strong>, <strong>views</strong> and{' '}
              <strong>traffic</strong> ?
            </p>
          </div>
          <PrimaryButton value={`Rank ${advanceLotValue?.location_slider}%`} />
        </div>
        <div className={styles.range}>
          <Slider
            tooltip={false}
            step={1}
            progress
            value={advanceLotValue?.location_slider}
            onChange={(e: any) => advanceHandleSliderChange(e, 'location_slider')}
          />
          <div className={styles.scaleMarkings}>
            {Array.from({ length: 28 }).map((_, index) => (
              <span key={index} className={styles.scaleMark}></span>
            ))}
          </div>
          <div className={styles.labels}>
            <span className={`${styles.label} ${styles.poor}`}>POOR</span>
            <span className={`${styles.label} ${styles.average}`}>AVERAGE</span>
            <span className={`${styles.label} ${styles.excellent}`}>EXCELLENT</span>
          </div>
        </div>
      </div>
      <div className={styles.filter}>
        <div className={styles.head}>
          <div>
            <h5>Site-lot benefits & constraints: $ 0,000,000</h5>
            <p>
              How would you rate the <strong>site lot</strong>, <strong>curb appeal</strong> and{' '}
              <strong>landscape</strong> ?
            </p>
          </div>
          <PrimaryButton value={`Rank ${advanceLotValue?.site_location_slider}%`} />
        </div>
        <div className={styles.range}>
          <Slider
            tooltip={false}
            step={1}
            progress
            value={advanceLotValue?.site_location_slider}
            onChange={(e: any) => advanceHandleSliderChange(e, 'site_location_slider')}
          />
          <div className={styles.scaleMarkings}>
            {Array.from({ length: 28 }).map((_, index) => (
              <span key={index} className={styles.scaleMark}></span>
            ))}
          </div>
          <div className={styles.labels}>
            <span className={`${styles.label} ${styles.poor}`}>POOR</span>
            <span className={`${styles.label} ${styles.average}`}>AVERAGE</span>
            <span className={`${styles.label} ${styles.excellent}`}>EXCELLENT</span>
          </div>
        </div>
      </div>
    </div>
  );

  const tabTitles = ['Basic mode', 'Advance mode'];
  const tabContents = [
    { content: basicTabRender, key: 'basic' },
    { content: advanceTabRender, key: 'advance' },
  ];

  // console.log(' =>>>>', toggle_Lot_location);

  return (
    <div className={styles.create}>
      <div className={styles.left}>
        <div className={styles.header}>
          <h5>Step 1: Lot-location value</h5>
          <SecondaryButton
            value='Ask AI Assistant'
            icon={<MessageSquare size={16} />}
            bgColor='#06417E'
            textColor='#fff'
          />
        </div>
        <div className={styles.tab}>
          <Tabs
            tabTitles={tabTitles}
            tabContents={tabContents}
            defaultIndex={tabIndex}
            setTabIndex={(index: number) => setTabIndex(index)}
          />
          <Divider className='divider' />
          <div className={styles.btn}>
            <PrimaryButton
              onClick={() =>
                navigate('/toggle/create/step2', {
                  state: state,
                })
              }
              value='Next'
              icon={<ChevronRight />}
              iconPosition='right'
            />
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.selectedProperty}>
          <div className={styles.head}>
            <h5>Selected Property</h5>
            <SecondaryButton
              value='Edit'
              icon={<Edit size={16} />}
              noBorder
              noBackground
              iconPosition='right'
              textColor='#fff'
              className={styles.edit}
            />
          </div>
          <div className={styles.address}>
            <FlexboxGrid align='middle' style={{ gap: '16px', flexWrap: 'nowrap' }}>
              <Avatar name='WM' size='80' round={'8px'} />
              <p>{state?.propertyDetails.address || 'N/A'}</p>
            </FlexboxGrid>
          </div>
        </div>
        <div className={styles.est}>
          <h5>Estimated value</h5>
          <p>${toggle_Lot_location?.estimated_price || '00'}</p>
          <p className={styles.rank}>100 %</p>
        </div>
        <div className={`${styles.details} ${styles.noRadius}`}>
          <div className={styles.head}>
            <h5>Property Details</h5>
            <SecondaryButton
              value='Edit'
              icon={<Edit size={16} />}
              noBorder
              noBackground
              iconPosition='right'
              textColor='#fff'
              className={styles.edit}
              onClick={() => setEditModal(true)}
            />
          </div>
          <div className={styles.info}>
            <ul>
              <li>
                <h5>House size</h5>
                <p>3,286 sqft</p>
                <p className={styles.rank}>100%</p>
              </li>
              <li>
                <h5>Lot size</h5>
                <p>9,888</p>
                <p className={styles.rank}>89%</p>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.head}>
            <h5>Total LLV</h5>
            <SecondaryButton
              value='Edit'
              icon={<Edit size={16} />}
              noBorder
              noBackground
              iconPosition='right'
              textColor='#fff'
              className={styles.edit}
            />
          </div>
          <div className={styles.info}>
            <ul>
              <li>
                <h5>Lot-location value</h5>
                <p>${toggle_Lot_location?.lot_location_value || '00'}</p>

                <p className={styles.rank}>63%</p>
              </li>
            </ul>
          </div>
        </div>
        <PropertyDataModal open={editModal} setOpen={() => setEditModal(false)} />
      </div>
    </div>
  );
};

import React, { useState, ReactNode } from 'react';
import styles from './Checkbox.module.scss';
import { Check } from 'lucide-react';

interface CheckboxProps {
  label?: ReactNode;
  name: string;
  required?: boolean;
  onChange?: any;
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, name, required, onChange }) => {
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChange && onChange(event.target.checked);
  };

  return (
    <div className={styles.checkboxContainer}>
      <label className={styles.customCheckbox}>
        <input
          type='checkbox'
          name={name}
          checked={checked}
          required={required}
          onChange={handleChange}
          className={styles.hiddenCheckbox}
        />
        <div className={styles.styledCheckbox}>{checked && <Check />}</div>
        <span>{label}</span>
      </label>
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Auth_already__7J0GP p {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: var(--neutral-medium);
}
.Auth_already__7J0GP button {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: var(--primary-color);
  background: none;
  border: none;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/Auth.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,4BAAA;AAAJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;AAAJ","sourcesContent":[".already {\n  p {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 24px;\n    color: var(--neutral-medium);\n  }\n  button {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 24px;\n    color: var(--primary-color);\n    background: none;\n    border: none;\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"already": `Auth_already__7J0GP`
};
export default ___CSS_LOADER_EXPORT___;

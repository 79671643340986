// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChannelInvite_invite__9yHlT {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}
.ChannelInvite_invite__9yHlT .ChannelInvite_btn__FSGRB {
  display: flex;
  gap: 16px;
}
.ChannelInvite_invite__9yHlT .ChannelInvite_btn__FSGRB button {
  flex: 1 1;
  text-align: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Channels/ChannelInvite/ChannelInvite.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,SAAA;AAEJ;AADI;EACE,SAAA;EACA,kBAAA;EACA,uBAAA;AAGN","sourcesContent":[".invite {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 24px;\n  .btn {\n    display: flex;\n    gap: 16px;\n    button {\n      flex: 1;\n      text-align: center;\n      justify-content: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invite": `ChannelInvite_invite__9yHlT`,
	"btn": `ChannelInvite_btn__FSGRB`
};
export default ___CSS_LOADER_EXPORT___;

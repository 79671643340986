// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DatePicker_datepicker__hChGl label {
  font-size: 14px;
  color: var(--neutral-medium);
  margin: 0 0 8px;
  font-weight: 500;
  line-height: 18px;
  display: block;
}
.DatePicker_datepicker__hChGl .rs-input-group {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 8px 12px;
}
.DatePicker_datepicker__hChGl .rs-input-group .rs-input {
  padding: 0;
}
.DatePicker_datepicker__hChGl .rs-input-group .rs-input-group-addon {
  padding-right: 0 !important;
  min-width: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/DatePicker/DatePicker.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,4BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAEE;EACE,qCAAA;EACA,kBAAA;EACA,iBAAA;AAAJ;AACI;EACE,UAAA;AACN;AACI;EACE,2BAAA;EACA,0BAAA;AACN","sourcesContent":[".datepicker {\n  label {\n    font-size: 14px;\n    color: var(--neutral-medium);\n    margin: 0 0 8px;\n    font-weight: 500;\n    line-height: 18px;\n    display: block;\n  }\n  :global .rs-input-group {\n    border: 1px solid var(--border-color);\n    border-radius: 4px;\n    padding: 8px 12px;\n    .rs-input {\n      padding: 0;\n    }\n    .rs-input-group-addon {\n      padding-right: 0 !important;\n      min-width: auto !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datepicker": `DatePicker_datepicker__hChGl`
};
export default ___CSS_LOADER_EXPORT___;

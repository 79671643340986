import { useState } from 'react';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';

type UseAxiosReturn<T> = {
  response: T | null;
  error: AxiosError | null;
  loading: boolean;
  makeRequest: (config: AxiosRequestConfig) => Promise<void>;
};

const useAxios = <T>(
  url: string,
  additionalHeaders: Record<string, string> = {}
): UseAxiosReturn<T> => {
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const headers = {
    ...additionalHeaders,
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
  const baseURL = process.env.REACT_APP_API_URL + url;
  // Create an Axios instance with global headers
  const axiosInstance = axios.create({
    baseURL,
    headers,
  });

  const makeRequest = async (config: AxiosRequestConfig) => {
    try {
      setLoading(true);
      const result = await axiosInstance({
        ...config,
      });
      setResponse(result.data);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  return { response, error, loading, makeRequest };
};

export default useAxios;

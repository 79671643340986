import { useEffect, useRef, useState } from 'react';
import { usePageTitle } from '../../../context/PageTitleContext';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  InputFieldWithController,
  PrimaryButton,
  Searchbar,
  SecondaryButton,
} from '../../common';
import styles from './PropertyForm.module.scss';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ERROR } from '../../../constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormGroup from 'rsuite/esm/FormGroup';
import { Divider } from 'rsuite';
import {
  ArrowLeftCircle,
  ExternalLink,
  MessagesSquare,
  Radius,
  Search,
  Trash2,
  X,
} from 'lucide-react';
import { addProperty } from '../../../containers/Properties/propertiesAction';
import { useDispatch } from 'react-redux';
import { GoogleMap, Marker } from '@react-google-maps/api';
import toast from 'react-hot-toast';
import { UploadButton } from '../../UploadButton';

const dummyData = [
  { id: 1, label: '327 Loring Ave Mill Valley, CA 94941' },
  { id: 2, label: '327 Lorton Avenue Burlingames, CA' },
];

// {
//   "address": "New Delhi 1",
//   "latitude": 28.6138954,
//   "longitude": 77.2090057,
//   "unit": 123,
//   "propertySize": 1232,
//   "lotSize": 1232,
//   "bedrooms": 12,
//   "bathrooms": 12,
//   "price": 1234,
//   "imgUrl": "https://test.com/test",
//   "externalLinks" : [
//       "https://test.com/test",
//       "https://abc.com/abc"
//   ]
// }

const validationSchema = Yup.object().shape({
  search: Yup.string().required(ERROR.REQUIRED_FEILD),
  unit: Yup.string(),
  propertySize: Yup.string().required(ERROR.REQUIRED_FEILD),
  lotSize: Yup.string().required(ERROR.REQUIRED_FEILD),
  bathrooms: Yup.string().required(ERROR.REQUIRED_FEILD),
  bedrooms: Yup.string().required(ERROR.REQUIRED_FEILD),
  price: Yup.string().required(ERROR.REQUIRED_FEILD),
  externalLinks: Yup.array().of(
    Yup.object().shape({
      url: Yup.string().required(ERROR.REQUIRED_FEILD),
    })
  ),
});

const containerStyle = {
  width: '100%',
  height: '300px',
  borderRadius: '20px',
};

const center = {
  lat: 28.7041, // Default center latitude (San Francisco)
  lng: 77.1025, // Default center longitude (San Francisco)
};

export const PropertyForm = () => {
  const inputRef: any = useRef(null);
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const [map, setMap] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  const dispatch: any = useDispatch();
  const [results, setResults] = useState<{ id: number; label: string }[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      externalLinks: [{ url: '' }],
    },
  });

  const handlePlaceSelect = (place: any) => {
    if (place && place.geometry) {
      setSelectedPlace({
        name: place.name,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });

      setValue('search', place.name);
    }
  };

  const header = (
    <div className='page-header'>
      <IconButton
        icon={<ArrowLeftCircle size={64} strokeWidth='1' color='#14192F' />}
        onClick={() => navigate('/properties')}
        className='shadow-less'
      />
      <h5>Create property</h5>
      <SecondaryButton
        value='Ask AI Assistant'
        icon={<MessagesSquare size={16} />}
        bgColor='#06417E'
        textColor='#fff'
      />
    </div>
  );

  const handleImageChange = (image: string | null) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    if (!window.google) {
      console.error('Google Maps API is not loaded.');
      return;
    }

    const google = window.google;
    const autocomplete = new google.maps.places.Autocomplete(inputRef.current);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      handlePlaceSelect(place);
    });

    // Cleanup function to remove the event listener
    return () => {
      if (autocomplete) {
        google.maps.event.clearListeners(autocomplete, 'place_changed');
      }
    };
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'externalLinks',
  });

  const addUrlField = () => {
    append({ url: '' });
  };

  const removeUrlField = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    setPageTitle(header);
  }, []);

  const handleSearchChange = (value: string) => {
    if (value === '') {
      setResults([]);
    } else {
      const filteredResults = dummyData.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      );
      setResults(filteredResults);
    }
  };

  const onSubmit = (data: any) => {
    const payload: any = {
      ...data,
      address: selectedPlace.name,
      longitude: selectedPlace.latitude,
      latitude: selectedPlace.longitude,
      externalLinks: data?.externalLinks?.map((item: any) => item?.url),
      imgUrl: selectedImage,
    };
    delete payload.search;
    // console.log('=>>>', {
    //   ...data,
    //   address: 'New Delhi 1',
    //   latitude: 28.6138954,
    //   longitude: 77.2090057,
    //   imgUrl:
    //     'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
    // });

    dispatch(
      addProperty(payload, (data: any) => {
        console.log(data);
        navigate('/properties');
        toast.success('Property Has Been Added Successfully', {
          duration: 4000,
        });
      })
    );
  };

  console.log('selectedImage', selectedImage);

  return (
    <div className={styles.createForm}>
      <h6>Property address</h6>
      <p className={styles.desc}>
        To get started, please type the property address into the provided field below.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup>
          <Controller
            control={control}
            name='search'
            render={({ field }) => (
              <Searchbar
                reff={inputRef}
                onSearchChange={handleSearchChange}
                results={results}
                {...field}
              />
            )}
          />
          {errors.search ? <p className='error'>{errors.search?.message}</p> : null}
        </FormGroup>
        <div className={styles.map}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={
              selectedPlace ? { lat: selectedPlace.latitude, lng: selectedPlace.longitude } : center
            }
            zoom={8}
            onLoad={(mapInstance: any) => setMap(mapInstance)}
          >
            {selectedPlace && (
              <Marker position={{ lat: selectedPlace.latitude, lng: selectedPlace.longitude }} />
            )}
          </GoogleMap>
        </div>
        <div className={styles.propertyDetails}>
          <h6>Property details</h6>
          <p className={styles.desc}>Please complete the property details below. </p>
          <div className='inlinefields'>
            <InputFieldWithController
              type='text'
              control={control}
              name='unit'
              label='Unit (Optional)'
            />
            <InputFieldWithController
              type='text'
              control={control}
              name='propertySize'
              label='Property size (sqft)'
              errors={errors}
              required
            />
            <InputFieldWithController
              type='text'
              control={control}
              name='lotSize'
              label='Lot size (sqft)'
              errors={errors}
              required
            />
          </div>
          <div className='inlinefields'>
            <InputFieldWithController
              type='text'
              control={control}
              name='bedrooms'
              label='bedrooms'
              errors={errors}
              required
            />
            <InputFieldWithController
              type='text'
              control={control}
              name='bathrooms'
              label='Bathrooms'
              errors={errors}
              required
            />
            <InputFieldWithController
              type='number'
              control={control}
              name='price'
              label='Listing price'
              errors={errors}
              required
            />
          </div>
          <div className={styles.photo}>
            <p className={styles.desc}>Property photo (optional)</p>
            <span>Upload an image to personalize your workspace</span>
            <div className={styles.button}>
              {/* <UploadButton /> */}
              {!selectedImage ? (
                <UploadButton onImageChange={handleImageChange} />
              ) : (
                <div className={styles.photoDiv}>
                  <img src={selectedImage || ''} alt='' width={200} height={200} />

                  <SecondaryButton
                    value='Delete Photo'
                    noBackground={true}
                    noBorder={true}
                    icon={<Trash2 size={16} />}
                    onClick={() => setSelectedImage(null)}
                    textColor='#ea3c50'
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.link}>
            <p className={styles.desc}>External links</p>
            {fields.map((field, index) => (
              <div key={field.id} className={styles.url}>
                <label>Property details URL</label>
                <div className={styles.urlField}>
                  <div className={styles.width}>
                    <Controller
                      control={control}
                      name={`externalLinks.${index}.url`} // Name should include the array index
                      render={({ field }) => (
                        <input
                          {...field}
                          type='text'
                          placeholder='Enter URL'
                          className={styles.textField}
                          required
                        />
                      )}
                    />
                    {errors?.externalLinks?.[index]?.url && (
                      <p className='error'>{errors?.externalLinks?.[index]?.url?.message}</p>
                    )}
                  </div>
                  <IconButton icon={<ExternalLink />} onClick={() => {}} />
                  {fields.length > 1 && (
                    <IconButton icon={<X />} onClick={() => removeUrlField(index)} />
                  )}
                </div>
              </div>
            ))}
            <SecondaryButton
              onClick={addUrlField}
              className={styles.addButton}
              value='+ Add another URL'
              bgColor='#74788C'
              textColor='#fff'
            />
          </div>
        </div>
        <Divider className='divider' />
        <div className={styles.btn}>
          <PrimaryButton value='Save Property' type='submit' />
          <PrimaryButton
            onClick={() => navigate('/properties')}
            value='Cancel'
            bgColor='#E2E3E7'
            textColor='#74788C'
          />
        </div>
      </form>
    </div>
  );
};

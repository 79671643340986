import { Header } from '../Header';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputFieldWithController, PrimaryButton, Link, Timer } from '../../common';
import styles from '../Auth.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAxios } from '../../../api';
import toast from 'react-hot-toast';
import { ERROR } from '../../../constants/common';

const validationSchema = Yup.object().shape({
  code: Yup.string().required(ERROR.REQUIRED_FEILD),
});

export const VerifyAccount = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const location = useLocation();
  const storedEmail = localStorage.getItem('email');
  const pathName = location.pathname.split('/')[2] === 'signup';

  const [showResendButton, setShowResendButton] = useState(false);
  const url = pathName ? 'auth/verify-email' : 'auth/validate-reset-password';
  const method = pathName ? 'put' : 'post';
  const { response, error, makeRequest } = useAxios(url);
  const codeVerificationApiUrl = pathName ? 'auth/resend-verification-mail' : 'auth/reset-password';

  const {
    response: data,
    error: resendError,
    makeRequest: resendRequest,
  } = useAxios(codeVerificationApiUrl);

  useEffect(() => {
    if (response) {
      reset({
        code: '',
      });
      if (pathName) {
        navigate('/auth/login');
      } else {
        navigate('/auth/createpassword');
      }
    }
    if (error) {
      // console.log(error);
      const message = error?.response?.data as Record<string, string>;
      toast.error(message?.message, {
        duration: 4000,
      });
    }
  }, [response, error, resendError, data]);

  const handleCountdownComplete = () => {
    setShowResendButton(true);
  };

  const resendCode = () => {
    setShowResendButton(false);
    resendRequest({ method: 'post', data: { email: storedEmail } });
  };

  const sendVerificationCode = (data: Record<string, unknown>) => {
    makeRequest({ method: method, data: data });
  };

  const onSubmit = useCallback((data: Record<string, unknown>) => {
    data.email = storedEmail;
    console.log(data);
    localStorage.setItem('code', data?.code as string);
    sendVerificationCode(data);
  }, []);

  return (
    <>
      <Header
        title={pathName ? 'Verify your account' : 'Check your inbox'}
        content='We sent a validation code. Please check your inbox and enter the code in the field below:'
        stepper
        count={pathName ? 1 : 2}
      />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <InputFieldWithController
          name='code'
          type='text'
          label='Enter verification code'
          control={control}
          errors={errors}
        />

        <div className={styles.already}>
          {!showResendButton ? (
            <p>
              Didn't get a code? You can resend in{' '}
              <Timer onCountdownComplete={handleCountdownComplete} initialTime={60} />
            </p>
          ) : (
            <p>
              <button onClick={resendCode}>Click here</button> to Resend verification code!
            </p>
          )}
        </div>
        <PrimaryButton type='submit' value='Verify Account' />
      </form>
      {!pathName && (
        <div className={styles.already}>
          <p>
            You can also <Link to='/auth/login'>login with your email or username</Link> or{' '}
            <Link>contact our support team</Link> if you need assistance with your account or{' '}
          </p>
        </div>
      )}
    </>
  );
};

import React, { ReactNode } from 'react';
import styles from './SecondaryButton.module.scss';

interface SecondaryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
  value?: string;
  noBorder?: boolean;
  noBackground?: boolean;
  icon?: ReactNode;
  textColor?: string;
  bgColor?: string;
  iconPosition?: 'left' | 'right';
}

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  type = 'button',
  value,
  className,
  noBorder,
  noBackground,
  icon,
  textColor,
  bgColor,
  iconPosition = 'left',
  ...props
}) => {
  let buttonClassNames = styles.secondary;
  const buttonStyles: React.CSSProperties = {};
  if (noBorder) buttonClassNames += ` ${styles.noBorder}`;
  if (noBackground) buttonClassNames += ` ${styles.noBackground}`;
  if (icon) buttonClassNames += ` ${styles.icon}`;
  if (className) buttonClassNames += ` ${className}`;
  if (textColor) buttonStyles.color = textColor;
  if (bgColor) buttonStyles.backgroundColor = bgColor;

  return (
    <button className={buttonClassNames} style={buttonStyles} type={type} {...props}>
      {icon && iconPosition === 'left' && <span>{icon}</span>} {value}{' '}
      {icon && iconPosition === 'right' && <span className={styles.rightIcon}>{icon}</span>}
    </button>
  );
};

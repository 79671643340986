import React, { Dispatch, SetStateAction, useState, useContext, FunctionComponent } from 'react';

interface WorkspaceDetails {
  name: string;
}

export interface WorkspaceProps {
  data: SetStateAction<WorkspaceProps | null>;
  workspaceId: string;
  _id: string;
  details: WorkspaceDetails;
  role: string;
  isActive: boolean;
}

interface WorkspaceContextProps {
  workspace: WorkspaceProps | null;
  setWorkspace: Dispatch<SetStateAction<WorkspaceProps | null>>;
}

export const WorkspaceContext = React.createContext<WorkspaceContextProps>({
  workspace: null,
  setWorkspace: () => {},
});

export const useWorkspace = () => useContext(WorkspaceContext);

interface WorkspaceProviderProps {
  children: React.ReactNode;
}

export const WorkspaceProvider: FunctionComponent<WorkspaceProviderProps> = ({ children }) => {
  const [workspace, setWorkspace] = useState<WorkspaceProps | null>(null);
  return (
    <WorkspaceContext.Provider value={{ workspace, setWorkspace }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

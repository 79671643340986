import { useState } from 'react';
import { SecondaryButton } from '../common';
import styles from './UpgradePlan.module.scss';
import { PricingPlanDialog } from '../SubscriptionPlans';

export const UpgradePlan = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.upgradePlan}>
      <img src={'/assets/banner2.jpg'} alt='banner' />
      <div className={styles.content}>
        <div>
          <p>Take it to the next level</p>
          <p>Get the Starter Plan and unlock advanced reports, community features and much more.</p>
        </div>
        <SecondaryButton
          onClick={() => setOpen(true)}
          value='Upgrade'
          textColor='#14192F'
          bgColor='#E0C808'
        />
        <PricingPlanDialog open={open} closeModal={() => setOpen(false)} />
      </div>
    </div>
  );
};

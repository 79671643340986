import styles from '../Auth.module.scss';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PrimaryButton, InputFieldWithController, Link, ControlledCheckbox } from '../../common';
import { Header } from '../Header';
import { useAxios } from '../../../api';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ERROR } from '../../../constants/common';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(ERROR.REQUIRED_FEILD)
    .min(3, 'First name must be at least 3 characters long'),
  lastName: Yup.string().required(ERROR.REQUIRED_FEILD),
  email: Yup.string().email('Email is invalid').required(ERROR.REQUIRED_FEILD),
  password: Yup.string()
    .required(ERROR.REQUIRED_FEILD)
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/,
      'Password must contain at least one special character'
    ),
  termsAndCondition: Yup.boolean()
    .oneOf([true], 'You must accept the terms and conditions')
    .required('You must accept the terms and conditions'),
});

export const Registration = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { response, error, makeRequest } = useAxios(`auth/signup${code ? '\\' + code : ''}`);

  useEffect(() => {
    if (response) {
      reset({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        termsAndCondition: undefined,
      });
      navigate('/auth/signup/verifyaccount');
    }
    if (error) {
      // console.log(error);
      const message = error?.response?.data as Record<string, string>;
      toast.error(message?.message, {
        duration: 4000,
      });
    }
  }, [response, error]);

  const onSubmit = (data: Record<string, unknown>) => {
    delete data.termsAndCondition;
    localStorage.setItem('email', data.email as string);
    console.log(data);
    makeRequest({ method: 'post', data: data });
  };

  useEffect(() => {
    reset({});
  }, [reset]);

  return (
    <>
      <Header title='Create an account' content="Let's get started with your FREE account." logo />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className='inlinefields'>
          <InputFieldWithController
            name='firstName'
            type='text'
            errors={errors}
            label='First Name'
            control={control}
            required
          />

          <InputFieldWithController
            name='lastName'
            type='text'
            errors={errors}
            label='Last Name'
            control={control}
            required
          />
        </div>

        <InputFieldWithController
          name='email'
          type='email'
          errors={errors}
          label='Email'
          control={control}
          required
        />

        <InputFieldWithController
          name='password'
          type='password'
          errors={errors}
          label='Create a password'
          control={control}
          required
        />

        <ControlledCheckbox
          control={control}
          name='termsAndCondition'
          label={
            <>
              I agree with the HomeRank <Link>terms of use</Link> and <Link>privacy policy</Link>
            </>
          }
          errors={errors}
        />

        <PrimaryButton type='submit' value='Create Account' />
      </form>
      <div className={styles.already}>
        <p>
          Already a member? <Link to={'/auth/login' + (code && '/' + code)}>Log in</Link>.
        </p>
      </div>
    </>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InviteUser_inviteUserModal__w18Fu {
  padding: 24px;
  background: #fff;
  border-radius: 16px;
}
.InviteUser_inviteUserModal__w18Fu h2 {
  font-size: 28px;
  color: var(--neutral-medium);
  font-weight: 300;
  line-height: 30px;
}
.InviteUser_inviteUserModal__w18Fu .InviteUser_inviteuserBtns__kX3AD {
  padding: 2opx;
  display: flex;
  gap: 20px;
}
.InviteUser_inviteUserModal__w18Fu .InviteUser_inviteuserBtns__kX3AD .InviteUser_inviteBtn__dcRpR {
  padding: 10px;
  width: 20rem;
}`, "",{"version":3,"sources":["webpack://./src/components/InviteUser/InviteUser.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;EACA,4BAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACE;EACE,aAAA;EACA,aAAA;EACA,SAAA;AACJ;AAAI;EACE,aAAA;EACA,YAAA;AAEN","sourcesContent":[".inviteUserModal {\n  padding: 24px;\n  background: #fff;\n  border-radius: 16px;\n\n  h2 {\n    font-size: 28px;\n    color: var(--neutral-medium);\n    font-weight: 300;\n    line-height: 30px;\n  }\n  .inviteuserBtns{\n    padding: 2opx;\n    display: flex;\n    gap:20px;\n    .inviteBtn{\n      padding: 10px;\n      width:20rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inviteUserModal": `InviteUser_inviteUserModal__w18Fu`,
	"inviteuserBtns": `InviteUser_inviteuserBtns__kX3AD`,
	"inviteBtn": `InviteUser_inviteBtn__dcRpR`
};
export default ___CSS_LOADER_EXPORT___;

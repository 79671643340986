import React, { ReactNode } from 'react';
import styles from './ChannelsCard.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconButton, SecondaryButton } from '../../common';
import { ExternalLink, Heart, MapPin, ThumbsUp, Trash2 } from 'lucide-react';

interface Property {
  id: number;
  image: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  price: number;
  beds: number;
  baths: number;
  sqft: number;
  dueDate?: string;
  togglesCount: number;
  EstimatedValueCount?: string;
  EstimatedValueCount1?: string;
  EstimatedValue2?: string;
  EstimatedValue?: string;
  EstimatedValueCount2?: string;
  ProfileDes?: string;
  profileDetails?: ReactNode;
}
interface PropertyCardProps {
  property: Property;
  path?: string;
}

export const ChannelsCard: React.FC<PropertyCardProps> = ({ property, path }) => {
  const { id } = useParams();
  const params = useLocation();
  const navigate = useNavigate();

  const location = params?.pathname?.split('/')[1];

  const formatPrice = (price: number) => {
    return price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  return (
    <div
      className={`${location === 'property' ? styles.detailCard : ''} ${
        (location !== 'property' && id) || ''
      } ${styles.card}`}
    >
      {location === 'property' && (
        <button className={styles.heartIcon}>
          {' '}
          <Heart />{' '}
        </button>
      )}
      <div className={styles.ProfileDes}>
        <div className={styles.cardtopArea}>
          <div className={styles.profileImg}>
            <div className={styles.profileIholder} onClick={() => navigate(`/profile/mike`)}>
              <span className={styles.profileIImage}>
                <img src={property.image} alt='Property' className={styles.propertyImage} />
              </span>
              <> {property.profileDetails} </>
            </div>
            {path === '/myprofile' ? <IconButton icon={<Trash2 />} onClick={() => {}} /> : null}
          </div>
          <div className={styles.ProfileDes}>{property.ProfileDes} </div>
        </div>
        <div className={styles.carddecHolder} onClick={() => navigate('/property/1')}>
          <img src={property.image} alt='Property' className={styles.propertyImage} />
          <div className={styles.propertyDetails}>
            <h3>{property.address}</h3>
            {location !== 'property' && (
              <p className={styles.address}>
                <MapPin /> <span>{`${property.city}, ${property.state}, ${property.zip}`}</span>
              </p>
            )}
            <div className={location === 'property' ? styles.flex : ''}>
              <div className={styles.bedBathSqft}>
                <span>
                  <strong>{property.beds}</strong> bed
                </span>
                <span>
                  <strong>{property.baths}</strong> bath
                </span>
                <span>
                  <strong>{property.sqft.toLocaleString()}</strong> sqft
                </span>
              </div>
              <div className={styles.price}>
                <span>
                  <strong>{formatPrice(property.price)}</strong>
                </span>
                {!id && (
                  <span className={styles.toggles}>
                    <strong>{property.togglesCount}</strong> toggles
                  </span>
                )}
                {location === 'propertyDetail' && (
                  <SecondaryButton value='View details' icon={<ExternalLink />} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.cardBottom}>
          <div className={styles.topLine}>
            {property.EstimatedValue}
            <span>
              {property.EstimatedValueCount}
              <span>{property.EstimatedValueCount1}</span>
            </span>
          </div>
          <div className={styles.topLine}>
            {property.EstimatedValue2}
            <span>{property.EstimatedValueCount2} </span>
          </div>
        </div>
        <div className={styles.kudos}>
          <ThumbsUp />
          <span>Kudos</span>
          <strong>399</strong>
        </div>
      </div>
    </div>
  );
};

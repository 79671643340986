import React, { ReactNode, useEffect } from 'react';
import styles from './SubscriptionPlans.module.scss';
import { Modal, PrimaryButton } from '../common';
import { Check } from 'lucide-react';
import { useAxios } from '../../api';
import toast from 'react-hot-toast';
import { useWorkspace } from '../../context/WorkspaceContext';

interface Plan {
  title: string;
  subtitle: string;
  priceCurrent: string;
  price: string;
  priceDuration: string;
  description: string;
  featuresTitle?: ReactNode | string;
  features: string[];
  buttonLabel: string;
}

interface PricingPlanProps {
  plan: Plan;
  onSelectPlan: () => void;
}

const PricingPlan: React.FC<PricingPlanProps> = ({ plan, onSelectPlan }) => {
  return (
    <div className={styles.plan}>
      <p className={styles.subtitle}>{plan.subtitle}</p>
      <div className={styles.content}>
        <h3>{plan.title}</h3>
        <p className={styles.price}>
          <span className={styles.currency}>{plan.priceCurrent}</span> <span>{plan.price}</span> /{' '}
          {plan.priceDuration}
        </p>
        <p className={styles.description}>{plan.description}</p>
        <PrimaryButton onClick={onSelectPlan} value={plan.buttonLabel} />
        <span className={styles.feat}>{plan.featuresTitle}</span>
        <ul className={styles.features}>
          {plan.features.map((feature, index) => (
            <li key={index}>
              <Check /> {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

interface PricingPlanDialogProps {
  onSelectPlan?: (selectedPlanTitle: string) => void;
  open: boolean;
  closeModal: () => void;
}

export const PricingPlanDialog: React.FC<PricingPlanDialogProps> = ({
  onSelectPlan,
  open,
  closeModal,
}) => {
  const { workspace } = useWorkspace();
  const plans = [
    {
      title: 'FREE',
      subtitle: 'Best for enthusiasts and aficionados',
      priceCurrent: '$',
      price: '0',
      priceDuration: 'month',
      description: 'Try our valuation tool and create private toggles',
      features: ['Valuate properties', 'Create private toggles', 'Share toggles by email'],
      buttonLabel: 'Choose Free Plan',
    },
    {
      title: 'Starter',
      subtitle: 'Best for professionals and small teams',
      priceCurrent: '$',
      price: '10',
      priceDuration: 'month',
      description: 'Access AI chat, organize teams and manage to-do lists',
      featuresTitle: (
        <p>
          Everything in <span>FREE</span>, plus
        </p>
      ),
      features: [
        'Access to AI valuation chatbot',
        'Create teams',
        'Share toggles with teams',
        'Invite up to 3 team members',
        'Invite additional team members for $X ea.',
      ],
      buttonLabel: 'Choose Starter Plan',
    },
    {
      title: 'Pro',
      subtitle: 'Best for larger teams and corporations',
      priceCurrent: '$',
      price: '20',
      priceDuration: 'month',
      description: 'Access AI chat, organize teams and manage to-do lists',
      featuresTitle: (
        <p>
          Everything in <span>STARTER</span>, plus
        </p>
      ),
      features: [
        'Access to AI valuation chatbot',
        'Create teams',
        'Share toggles with teams',
        'Invite up to 3 team members',
        'Invite additional team members for $X ea.',
      ],
      buttonLabel: 'Choose Pro Plan',
    },
  ];

  const { response, makeRequest, error } = useAxios(
    'workspace/subscription/' + workspace?.workspaceId
  );
  useEffect(() => {
    if (response) {
      toast.success('Subscription updated successfully', {
        duration: 4000,
      });
      closeModal();
    }
    if (error) {
      const message = error?.response?.data as Record<string, string>;
      toast.error(message?.message, {
        duration: 4000,
      });
    }
  }, [response, error]);

  return (
    <Modal
      size='lg'
      open={open}
      closeModal={closeModal}
      className={styles.planModal}
      title='Upgrade your workspace'
    >
      <div className={styles.pricingPlanDialog}>
        <div className={styles.planList}>
          {plans.map((plan) => (
            <PricingPlan
              key={plan.title}
              plan={plan}
              onSelectPlan={() => {
                onSelectPlan?.(plan.title);
                makeRequest({
                  method: 'patch',
                  data: { subscriptionType: plan.title.toUpperCase() },
                });
              }}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

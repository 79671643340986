import Avatar from 'react-avatar';
import PropertyCard from '../../Properties/PropertiesCard/PropertyCard';
import styles from './ToggleDetail.module.scss';
import { Divider } from 'rsuite';
import { IconButton, PrimaryButton, SecondaryButton } from '../../common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { usePageTitle } from '../../../context/PageTitleContext';
import { ShareToggleModal } from '../ShareToggleModal';
import { CreateNewModal } from '../CreateNewModal';
import { ArrowLeftCircle, ChevronLeft, FileBarChart, Lock, Share2 } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { getToggleDetails } from '../../../containers/Toggles/toggleAction';

const property = {
  id: 1,
  image: '/assets/banner4.jpg',
  address: '32 Pine St, Sausalito, CA',
  city: 'Sausalito',
  state: 'CA',
  zip: '94965',
  price: 6850000,
  beds: 4,
  baths: 6,
  sqft: 3300,
  dueDate: '04/14',
  togglesCount: 4,
};

const data = [
  { item: 'House size', value: '3,286 sqft', rank: '100 %' },
  { item: 'House size', value: '3,286 sqft', rank: '100 %' },
  { item: 'House size', value: '3,286 sqft', rank: '100 %' },
];

export const ToggleDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [shareToggelModalOpen, setShareToggleModalOpen] = useState(false);
  const [toggleOpen, setToggleOpen] = useState(false);
  const { setPageTitle } = usePageTitle();
  const dispatch: any = useDispatch();
  const { toggleDetails } = useSelector((state: any) => state.toggle);

  console.log('toggleDetails', toggleDetails);

  const header = (
    <div className='page-header'>
      <IconButton
        icon={<ArrowLeftCircle size={64} strokeWidth='1' color='#14192F' />}
        onClick={() => navigate(-1)}
        className='shadow-less'
      />
      <h5>{id ? 'Toggle details' : 'Create toggle'}</h5>
      <PrimaryButton
        onClick={() => setShareToggleModalOpen(true)}
        bgColor='#C7DCE8'
        textColor='#06417E'
        icon={<Share2 />}
        value='Share toggle'
      />
      <PrimaryButton
        onClick={() => {}}
        bgColor='#C7DCE8'
        textColor='#06417E'
        icon={<FileBarChart />}
        value='Purchase report'
      />
    </div>
  );
  useEffect(() => {
    setPageTitle(header);
    dispatch(getToggleDetails(state?.id, () => {}));
  }, []);

  return (
    <div className={styles.detailWrap}>
      <div className={styles.left}>
        {!id && <h5>Toggle results</h5>}
        <div onClick={() => navigate(`/property/${property.id}`)}>
          <PropertyCard property={toggleDetails?.propertyDetails} />
        </div>
        <div className={styles.estValue}>
          <div>
            <h4>Estimated value</h4>
            <div>
              <p className={styles.price}>$ {toggleDetails?.estimated_price}</p>
              <p className={styles.percentage}>{toggleDetails?.estimated_price_rank}%</p>
            </div>
          </div>
          <div>
            <h4>Home Value Ratio (HVR)</h4>
            <div>
              <p className={styles.percentage}>{toggleDetails?.hvr} %</p>
            </div>
          </div>
        </div>
        <table className={styles.dataTable}>
          <thead>
            <tr>
              <th>ITEM</th>
              <th>VALUE</th>
              <th>RANK</th>
            </tr>
          </thead>
          <tbody>
            {data.map((dataItem, index) => (
              <tr key={index}>
                <td>{dataItem.item}</td>
                <td>{dataItem.value}</td>
                <td>{dataItem.rank}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {!id && (
          <>
            <Divider className='divider' />
            <div className={styles.btn}>
              <PrimaryButton
                value='Previous'
                icon={<ChevronLeft />}
                bgColor='#C7DCE8'
                textColor='#06417E'
                onClick={() => navigate(-1)}
              />
              <div>
                <PrimaryButton
                  className={styles.togglebtn}
                  onClick={() => setShareToggleModalOpen(true)}
                  icon={<Share2 />}
                  value='Share toggle'
                  iconPosition='right'
                />
                <PrimaryButton
                  onClick={() => setToggleOpen(true)}
                  value='Toggle another property'
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div className={styles.right}>
        <div className={styles.moreDetail}>
          <h5>More details</h5>
          <div className={styles.status}>
            <p>
              Toggled on 14/04/2025 by{' '}
              <span>
                <Avatar name='WM' size='16px' round />
                <p>Jane S</p>
              </span>
            </p>
          </div>
        </div>
        <Divider className='divider' />
        <div className={styles.moreDetail}>
          <h5>Shared on</h5>
          <ul className={styles.sharedStatus}>
            <li>
              <Lock /> Exclusive
            </li>
            <li># Celebrity Homes 😎</li>
          </ul>
        </div>
        <Divider className='divider' />
        <h5>HomeFacts Report</h5>
        <SecondaryButton value='Purchase Report' bgColor='#E0C808' textColor='#14192F' />
        <ShareToggleModal
          open={shareToggelModalOpen}
          setOpen={() => setShareToggleModalOpen(false)}
        />
      </div>
      <CreateNewModal open={toggleOpen} setOpen={() => setToggleOpen(false)} />
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_content__e0Zvh h1 {
  font-size: 40px;
  font-weight: 700;
  color: var(--primary-color);
  padding: 0 0 8px;
  line-height: 48px;
}
.Header_content__e0Zvh p {
  color: var(--neutral-medium);
  font-weight: 300;
  line-height: 26px;
}

.Header_stepper__qeh0G {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  padding: 0;
  padding-bottom: 11px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/Header/Header.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;EACA,2BAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAEE;EACE,4BAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;;AAGA;EACE,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,UAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,YAAA;EACA,gBAAA;AAAF","sourcesContent":[".content {\n  h1 {\n    font-size: 40px;\n    font-weight: 700;\n    color: var(--primary-color);\n    padding: 0 0 8px;\n    line-height: 48px;\n  }\n  p {\n    color: var(--neutral-medium);\n    font-weight: 300;\n    line-height: 26px;\n  }\n}\n.stepper {\n  color: var(--primary-color);\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 18px;\n  text-transform: uppercase;\n  padding: 0;\n  padding-bottom: 11px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  border: none;\n  background: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Header_content__e0Zvh`,
	"stepper": `Header_stepper__qeh0G`
};
export default ___CSS_LOADER_EXPORT___;

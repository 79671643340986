// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploadButton_upload__YziJp {
  background: var(--neutral-light);
  font-size: 14px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 8px;
  cursor: pointer;
  color: var(--primary-color);
  display: inline-flex;
}`, "",{"version":3,"sources":["webpack://./src/components/UploadButton/UploadButton.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,QAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,2BAAA;EACA,oBAAA;AACF","sourcesContent":[".upload {\n  background: var(--neutral-light);\n  font-size: 14px;\n  padding: 8px 12px;\n  align-items: center;\n  gap: 8px;\n  font-weight: 600;\n  line-height: 18px;\n  border-radius: 8px;\n  cursor: pointer;\n  color: var(--primary-color);\n  display: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload": `UploadButton_upload__YziJp`
};
export default ___CSS_LOADER_EXPORT___;

import toast from 'react-hot-toast';
import { notify } from '../../Utils/toastify';
import endPoints from '../../root_api/endpoint';
import { deleteApiCall, getApiCall, postApiCall } from '../../root_api/methods';
import { resetToggle, updateToggle } from './toggleSlice';
import { isString } from 'lodash';

export const getToggleList = (values?: any, callback?: any, type?: string) => {
  // console.log(values)

  const { pageNo = 1, searchKey = '', status = '', limit = 10, dateType = '' } = values || {};
  const formatDate: any = isString(dateType)
    ? dateType
    : 'custom&startDate=' + dateType.startDate + '&endDate=' + dateType.endDate;

  return (dispatch: any, getState: any) => {
    // console.log('dataToSend', values)
    dispatch(updateToggle({ loading: true }));

    getApiCall(
      `${endPoints.toggle.get}?${searchKey && 'searchKey=' + searchKey}${
        dateType && '&dateType=' + formatDate
      }`,

      (s: any) => {
        const {
          data: { statusCode },
          data,
        } = s;

        if (statusCode && statusCode === 200) {
          console.log('RESSS', data);
          dispatch(updateToggle({ toggleList: data?.data }));
        }
        dispatch(updateToggle({ loading: false }));
      },
      (e: any) => {
        //   setLoad(false)
        dispatch(updateToggle({ loading: false }));
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error');
        } else {
          notify(null, 'error');
        }
      }
    );
  };
};

export const getTodDoList = (values?: any, callback?: any, type?: string) => {
  // console.log(values)

  const { pageNo = 1, searchKey = '', status = '', limit = 10, dateType = '' } = values || {};

  const formatDate: any = isString(dateType)
    ? dateType
    : 'custom&startDate=' + dateType.startDate + '&endDate=' + dateType.endDate;

  // console.log(formatDate);

  return (dispatch: any, getState: any) => {
    dispatch(updateToggle({ loading: true }));

    getApiCall(
      `${endPoints.todo.get}?${searchKey && 'searchKey=' + searchKey}${
        status && '&status=' + status
      }${dateType && '&dateType=' + formatDate}`,

      (s: any) => {
        const {
          data: { statusCode },
          data,
        } = s;

        if (statusCode && statusCode === 200) {
          console.log('RESSS', data);
          dispatch(updateToggle({ todoList: data?.data }));
        }
        dispatch(updateToggle({ loading: false }));
      },
      (e: any) => {
        //   setLoad(false)
        dispatch(updateToggle({ loading: false }));
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error');
        } else {
          notify(null, 'error');
        }
      }
    );
  };
};

export const getWorkSpaceDetails = (values?: any, callback?: any, type?: string) => {
  // console.log(values)

  const { id } = values || {};

  return (dispatch: any, getState: any) => {
    // console.log('dataToSend', values)
    dispatch(updateToggle({ loading: true }));

    getApiCall(
      `${endPoints.todo.workSpace(id)}`,

      (s: any) => {
        const {
          data: { statusCode },
          data,
        } = s;

        if (statusCode && statusCode === 200) {
          console.log('RESSS', data);
          dispatch(updateToggle({ workSpaceDetails: data?.data }));
        }
        dispatch(updateToggle({ loading: false }));
      },
      (e: any) => {
        //   setLoad(false)
        dispatch(updateToggle({ loading: false }));
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error');
        } else {
          notify(null, 'error');
        }
      }
    );
  };
};

export const AddTodo = (values: any, callback?: any) => {
  // console.log(values);

  return (dispatch: any) => {
    postApiCall(
      endPoints.todo.add,
      values,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;

        // console.log('RESSS  edit actionssssss', s);
        if (statusCode && statusCode === 200) {
          callback && callback();
          notify('Add in Favourite ', 'success');
          // notify('Product created successfully', 'success')
        }
      },
      (e: any) => {
        //   setLoad(false)
        if (e?.data && e?.data.message) {
          toast.error(e?.data.message, {
            duration: 4000,
          });
        } else {
          toast.error(null, {
            duration: 4000,
          });
        }
      }
    );
  };
};

export const deleteTodo = (id: any, callback?: Function) => {
  return (dispatch: any, getState: any) => {
    // console.log('dataToSend', values)

    deleteApiCall(
      `${endPoints.todo.delete(id)}`,
      '',
      (s: any) => {
        const {
          data: { statusCode },
        } = s;

        // console.log('RESSS banner update', s)
        if (statusCode && statusCode === 200) {
          callback && callback();
          // navigate(ROUTES.PRODUCTS)
          // notify('Product created successfully', 'success')
        }
      },
      (e: any) => {
        //   setLoad(false)
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error');
        } else {
          notify(null, 'error');
        }
      }
    );
  };
};

// ------------TOGGLES API ----------------------
export const ToggleSliderFirst = (values: any, type: string, callback?: any) => {
  return (dispatch: any) => {
    postApiCall(
      endPoints.toggle.calculate_one,
      values,
      (s: any) => {
        const { status, data } = s;
        // console.log('RESSS  edit actionssssss=====', data);
        if (status === 200) {
          callback && callback();
          console.log('RESSS  edit actionssssss =>', s);
          if (type === 'lot_location') {
            dispatch(updateToggle({ toggle_Lot_location: data }));
          } else if (type === 'structure_condition') {
            dispatch(updateToggle({ toggle_Structure_condition_value: data }));
          }

          // notify('Product created successfully', 'success')
        }
      },
      (e: any) => {
        //   setLoad(false)
        if (e?.data && e?.data.message) {
          toast.error('Someting went wrong', {
            duration: 4000,
          });
        } else {
          toast.error('Someting went wrong', {
            duration: 4000,
          });
        }
      }
    );
  };
};

export const SaveToggle = (values: any, callback?: any) => {
  // console.log(values);

  return (dispatch: any) => {
    postApiCall(
      endPoints.toggle.add,
      values,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;

        // console.log('RESSS  edit actionssssss', s);
        if (statusCode && statusCode === 200) {
          callback && callback();

          dispatch(resetToggle());
          // notify('Product created successfully', 'success')
        }
      },
      (e: any) => {
        //   setLoad(false)
        if (e?.data && e?.data.message) {
          toast.error(e?.data.message, {
            duration: 4000,
          });
        } else {
          toast.error(null, {
            duration: 4000,
          });
        }
      }
    );
  };
};

export const getToggleDetails = (id?: string, callback?: any, type?: string) => {
  return (dispatch: any) => {
    // dispatch(updateProperties({ loading: true }));

    getApiCall(
      `${endPoints.toggle.getToggleDetails(id)}`,
      (s: any) => {
        const {
          data: { statusCode },
          data,
        } = s;

        if (statusCode && statusCode === 200) {
          console.log('RESSS', data);
          dispatch(updateToggle({ toggleDetails: data?.data }));
        }
        dispatch(updateToggle({ loading: false }));
      },
      (e: any) => {
        //   setLoad(false)
        dispatch(updateToggle({ loading: false }));
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error');
        } else {
          notify(null, 'error');
        }
      }
    );
  };
};

export const deleteToggle = (id: any, callback?: Function) => {
  return (dispatch: any, getState: any) => {
    // console.log('dataToSend', values)

    deleteApiCall(
      `${endPoints.toggle.delete(id)}`,
      '',
      (s: any) => {
        const {
          data: { statusCode },
        } = s;

        // console.log('RESSS banner update', s)
        if (statusCode && statusCode === 200) {
          callback && callback();
          // navigate(ROUTES.PRODUCTS)
          // notify('Product created successfully', 'success')
        }
      },
      (e: any) => {
        //   setLoad(false)
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error');
        } else {
          notify(null, 'error');
        }
      }
    );
  };
};

export const ToggleSliderAdvnace = (values: any, type: string, callback?: any) => {
  return (dispatch: any) => {
    postApiCall(
      endPoints.toggle.calculate_two,
      values,
      (s: any) => {
        const { status, data } = s;
        // console.log('RESSS  edit actionssssss=====', data);
        if (status === 200) {
          callback && callback();
          console.log('RESSS  edit actionssssss =>', s);
          if (type === 'lot_location') {
            dispatch(updateToggle({ advanceModeLot: data }));
          } else if (type === 'structure_condition') {
            dispatch(updateToggle({ toggle_Structure_condition_value: data }));
          }

          // notify('Product created successfully', 'success')
        }
      },
      (e: any) => {
        //   setLoad(false)
        if (e?.data && e?.data.message) {
          toast.error('Someting went wrong', {
            duration: 4000,
          });
        } else {
          toast.error('Someting went wrong', {
            duration: 4000,
          });
        }
      }
    );
  };
};

export const ToggleSliderAdvnaceTwo = (values: any, type: string, callback?: any) => {
  return (dispatch: any) => {
    postApiCall(
      endPoints.toggle.calculate_sc,
      values,
      (s: any) => {
        const { status, data } = s;
        // console.log('RESSS  edit actionssssss=====', data);
        if (status === 200) {
          callback && callback();
          console.log('RESSS  edit actionssssss =>', s);
          if (type === 'lot_location') {
            dispatch(updateToggle({ advanceModeLot: data }));
          } else if (type === 'structure_condition') {
            dispatch(updateToggle({ toggle_Structure_condition_value: data }));
          }

          // notify('Product created successfully', 'success')
        }
      },
      (e: any) => {
        //   setLoad(false)
        if (e?.data && e?.data.message) {
          toast.error('Someting went wrong', {
            duration: 4000,
          });
        } else {
          toast.error('Someting went wrong', {
            duration: 4000,
          });
        }
      }
    );
  };
};

import React from 'react';
import { SecondaryButton } from '../../common';
import styles from '../ChannelsMember/ChannelsMember.module.scss';
import { X } from 'lucide-react';

interface TredingCarddec {
  MemberTitle: string;
  MemberDescription: string;
  ButtonText: string;
  image: string;
}

interface TredingCardProps {
  property: TredingCarddec;
}

const ChannelsMember: React.FC<TredingCardProps> = ({ property }) => {
  return (
    <>
      <div className={styles.ChannleMemberholder}>
        <span className={styles.profilePic}>
          <img src={property.image} alt='Property' className={styles.propertyImage} />
        </span>
        <div className={styles.ChannleMemberMedia}>
          <div>
            <h4>{property.MemberTitle}</h4>
            <span>{property.MemberDescription}</span>
          </div>
          <div className={styles.buttonHolder}>
            <SecondaryButton value={property.ButtonText} iconPosition='right' />
            <X />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChannelsMember;

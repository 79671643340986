import Select from 'react-dropdown-select';
import { Controller, Control, FieldValues, Path, FieldErrors } from 'react-hook-form';
import styles from './ControlledDropdown.module.scss';
interface Option {
  label: string;
  value: string;
}

interface ControlledDropdownProps<T extends FieldValues> {
  options: Option[];
  name: Path<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  placeholder: string;
  multi?: boolean;
  label?: string;
}

export const ControlledDropdown = <T extends FieldValues>({
  options,
  multi = false,
  name,
  control,
  errors,
  placeholder,
  label,
}: ControlledDropdownProps<T>) => {
  return (
    <div className={styles.formControl}>
      <label className={styles.label}>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            className={styles.dropdownInput}
            options={options as Option[]}
            searchable={false}
            multi={multi}
            placeholder={placeholder}
            values={
              Array.isArray(field?.value)
                ? field?.value?.[0]?.value
                : (options.filter((val) => val.value === field.value) as unknown as Option[])
            }
            onChange={(selectedOptions) => {
              field.onChange(
                multi
                  ? selectedOptions.map((option) => option.value)
                  : selectedOptions?.length > 0
                    ? selectedOptions[0].value
                    : null
              );
            }}
          />
        )}
      />
      {errors[name] && <p className='error'>{errors[name]?.message as unknown as string}</p>}
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs_tabContainer__62ouD {
  color: #000;
}
.Tabs_tabContainer__62ouD ul {
  border-bottom-color: var(--neutral-light);
}
.Tabs_tabContainer__62ouD ul li.react-tabs__tab {
  border: none;
  border-bottom: 1px solid var(--neutral-medium);
  bottom: -1px;
  padding: 16px 24px;
  text-transform: uppercase;
  color: var(--neutral-medium);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.Tabs_tabContainer__62ouD ul li.react-tabs__tab.react-tabs__tab--selected {
  color: var(--primary-color);
}
.Tabs_tabContainer__62ouD ul li.react-tabs__tab.react-tabs__tab--selected:after {
  content: "";
  height: 4px;
  width: 100%;
  background: var(--primary-color);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.Tabs_tabContainer__62ouD .react-tabs__tab-panel {
  padding: 40px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Tabs/Tabs.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,yCAAA;AAEJ;AADI;EACE,YAAA;EACA,8CAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,4BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAGN;AAFM;EACE,2BAAA;AAIR;AAHQ;EACE,WAAA;EACA,WAAA;EACA,WAAA;EACA,gCAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AAKV;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[".tabContainer {\n  color: #000;\n  ul {\n    border-bottom-color: var(--neutral-light);\n    :global li.react-tabs__tab {\n      border: none;\n      border-bottom: 1px solid var(--neutral-medium);\n      bottom: -1px;\n      padding: 16px 24px;\n      text-transform: uppercase;\n      color: var(--neutral-medium);\n      font-size: 16px;\n      font-weight: 500;\n      line-height: 24px;\n      &.react-tabs__tab--selected {\n        color: var(--primary-color);\n        &:after {\n          content: '';\n          height: 4px;\n          width: 100%;\n          background: var(--primary-color);\n          position: absolute;\n          bottom: 0;\n          left: 0;\n          right: 0;\n        }\n      }\n    }\n  }\n  :global .react-tabs__tab-panel {\n    padding: 40px 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": `Tabs_tabContainer__62ouD`
};
export default ___CSS_LOADER_EXPORT___;

import { ReactNode } from 'react';
import styles from './ControlledCheckbox.module.scss';
import { Controller, Control, FieldErrors, FieldValues, Path } from 'react-hook-form';
import { ReactComponent as Checkmark } from '../../../assets/checked.svg';
interface CheckboxProps<T extends FieldValues> {
  label?: ReactNode;
  errors: FieldErrors<T>;
  control: Control<T>;
  name: Path<T>;
  required?: boolean;
}
export const ControlledCheckbox = <T extends FieldValues>({
  label,
  errors,
  control,
  name,
  required,
}: CheckboxProps<T>) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className={styles.checkboxContainer}>
            <label className={styles.customCheckbox}>
              <input
                type='checkbox'
                checked={field.value}
                required={required}
                {...field}
                className={styles.hiddenCheckbox}
              />
              <div className={styles.styledCheckbox}>{field.value && <Checkmark />}</div>
              <span>{label}</span>
            </label>
          </div>
        )}
      />
      {errors[name] && <p className='error'>{errors[name]?.message as unknown as string}</p>}
    </>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputField_formControl__AJ4NV {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1;
}

.InputField_label__43J5c {
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--neutral-medium);
  font-weight: 500;
  line-height: 18px;
  display: block;
}

.InputField_inputWrapper__U0RGU {
  position: relative;
}

.InputField_inputIcon__9JK8F {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.InputField_inputField__m6cZ4 {
  width: 100%;
  padding: 16px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.InputField_inputField__m6cZ4:focus {
  outline: none;
  border-color: #666;
}
.InputField_inputField__m6cZ4.InputField_errorInput__pSzY4 {
  border-color: #ff3860;
}
.InputField_inputField__m6cZ4.InputField_withIcon__zJlg8 {
  padding-left: 30px;
}
.InputField_inputField__m6cZ4.InputField_rightInput__DkaoP {
  padding-left: 12px;
  padding-right: 35px;
}

.InputField_error__E6pJA {
  color: #ff3860;
  font-size: 0.75rem;
  margin-top: 4px;
}

.InputField_helperText__siPNx {
  font-size: 0.75rem;
  margin-top: 4px;
  color: #666;
}

.InputField_rightIcon__0gFbG {
  right: 15px;
  left: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/common/InputField/InputField.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,4BAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,MAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;EACA,kBAAA;AAEJ;AAAE;EACE,qBAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,mBAAA;AAEJ;;AAEA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,UAAA;AACF","sourcesContent":[".formControl {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  flex: 1;\n}\n\n.label {\n  margin-bottom: 8px;\n  font-size: 14px;\n  color: var(--neutral-medium);\n  font-weight: 500;\n  line-height: 18px;\n  display: block;\n}\n\n.inputWrapper {\n  position: relative;\n}\n\n.inputIcon {\n  position: absolute;\n  left: 10px;\n  top: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n}\n\n.inputField {\n  width: 100%;\n  padding: 16px 12px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  &:focus {\n    outline: none;\n    border-color: #666;\n  }\n  &.errorInput {\n    border-color: #ff3860;\n  }\n  &.withIcon {\n    padding-left: 30px;\n  }\n  &.rightInput {\n    padding-left: 12px;\n    padding-right: 35px;\n  }\n}\n\n.error {\n  color: #ff3860;\n  font-size: 0.75rem;\n  margin-top: 4px;\n}\n\n.helperText {\n  font-size: 0.75rem;\n  margin-top: 4px;\n  color: #666;\n}\n\n.rightIcon {\n  right: 15px;\n  left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formControl": `InputField_formControl__AJ4NV`,
	"label": `InputField_label__43J5c`,
	"inputWrapper": `InputField_inputWrapper__U0RGU`,
	"inputIcon": `InputField_inputIcon__9JK8F`,
	"inputField": `InputField_inputField__m6cZ4`,
	"errorInput": `InputField_errorInput__pSzY4`,
	"withIcon": `InputField_withIcon__zJlg8`,
	"rightInput": `InputField_rightInput__DkaoP`,
	"error": `InputField_error__E6pJA`,
	"helperText": `InputField_helperText__siPNx`,
	"rightIcon": `InputField_rightIcon__0gFbG`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { InputField, Modal, PrimaryButton } from '../../common';
import { Divider } from 'rsuite';
import styles from './ChannelInvite.module.scss';

interface ChannelInviteProps {
  open: boolean;
  closeModal: () => void;
}

export const ChannelInvite = ({ open, closeModal }: ChannelInviteProps) => {
  return (
    <Modal open={open} closeModal={closeModal} title='Add people to channel'>
      <div className={styles.invite}>
        <InputField label='Invite people' placeholder='ex. James, or @mike, or mary@acme.com' />
        <Divider className='divider' />
        <div className={styles.btn}>
          <PrimaryButton value='Add' />
          <PrimaryButton
            value='Cancel'
            bgColor='#C7DCE8'
            onClick={closeModal}
            textColor='#06417E'
          />
        </div>
      </div>
    </Modal>
  );
};

import Avatar from 'react-avatar';
import {
  ControlledDropdown,
  InputFieldWithController,
  PrimaryButton,
  Tabs,
  SecondaryButton,
} from '../common';
import styles from './Profile.module.scss';
import { UploadButton } from '../UploadButton';
import { useForm } from 'react-hook-form';
import state from '../../constants/usState.json';
import { FormButtons } from '../FormButtons';
import { UpgradePlan } from '../UpgradePlan';
import { usePageTitle } from '../../context/PageTitleContext';
import { useEffect, useState } from 'react';
import { Lock, Trash2 } from 'lucide-react';
import { useAxios } from '../../api';
import toast from 'react-hot-toast';
import { PasswordChangeModal } from '../PasswordChangeModal';
import { useSession } from '../../context/SessionContext';

export const Profile = () => {
  const { setPageTitle } = usePageTitle();
  const [tabIndex, setTabIndex] = useState(0);
  const [changePasswordModal, ChangePasswords] = useState(false);
  const tabTitles = ['User Profile', 'Notifications'];
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { session } = useSession();
  const sessionData = session as Record<string, string>;

  const handleImageChange = (image: string | null) => {
    setSelectedImage(image);
  };
  const handleDeletePhoto = () => {
    setSelectedImage(null);
    handleImageChange(null);
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();
  useEffect(() => {
    if (session) {
      reset({
        firstName: sessionData.firstName || '',
        lastName: sessionData.lastName || '',
        userName: sessionData.username || '',
        email: sessionData.email ?? '',
        phone: sessionData.phone ?? '',
        city: sessionData.city ?? '',
        state: sessionData.state ?? '',
      });
    }
  }, [session, reset, setValue]);

  const { response, makeRequest, error } = useAxios('auth/account');

  useEffect(() => {
    setPageTitle('Account Settings');
    if (response) {
      toast.success('Account updated successfully', {
        duration: 4000,
      });
    }
    if (error) {
      const message = error?.response?.data as Record<string, string>;
      toast.error(message?.message, {
        duration: 4000,
      });
    }
  }, [response, error]);

  const onSubmit = (data: Record<string, unknown>) => {
    const list = {
      firstName: data.firstName,
      lastName: data.lastName,
      city: data.city,
      state: data.state,
      phone: data.phone,
      email: data.email,
    };
    makeRequest({ method: 'patch', data: list });
  };
  useEffect(() => {
    reset({});
  }, [reset]);

  const handleSaveChanges = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmit(onSubmit)();
    }
  };

  const userProfileContent = (
    <>
      <div className={styles.profileForm}>
        <div className={styles.profilePhoto}>
          <Avatar size='160' name='WM' round src={selectedImage || ''} />
          <UploadButton onImageChange={handleImageChange} />
          <SecondaryButton
            value='Delete Photo'
            noBackground={true}
            noBorder={true}
            icon={<Trash2 size={16} />}
            textColor='#ea3c50'
            onClick={handleDeletePhoto}
          />
        </div>
        <div className={styles.userForm}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className='inlinefields grid'>
              <InputFieldWithController
                name='firstName'
                type='text'
                errors={errors}
                label='First Name'
                control={control}
                required
              />
              <InputFieldWithController
                name='lastName'
                type='text'
                errors={errors}
                label='Last Name'
                control={control}
                required
              />
              <InputFieldWithController
                name='userName'
                type='text'
                errors={errors}
                label='UserName (@)'
                control={control}
                //required
              />
              <InputFieldWithController
                name='city'
                type='text'
                errors={errors}
                label='City'
                control={control}
                //required
              />
              <ControlledDropdown
                control={control}
                name='state'
                options={state}
                errors={errors}
                placeholder='Select State'
                label='State'
              />
            </div>
            <div className='inlinefields'>
              <InputFieldWithController
                name='email'
                type='email'
                label='Email'
                control={control}
                errors={errors}
              />
              <InputFieldWithController
                name='phone'
                type='tel'
                label='Phone'
                control={control}
                errors={errors}
              />
            </div>
          </form>
          <PrimaryButton
            value='Change Password'
            icon={<Lock />}
            onClick={() => ChangePasswords(true)}
          />
        </div>
      </div>
      <FormButtons onSaveChanges={handleSaveChanges} />
    </>
  );

  const tabContents = [
    { content: userProfileContent, key: 'profile' },
    { content: <h2>Notifications</h2>, key: 'notification' },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.profileContainer}>
          <Tabs
            tabTitles={tabTitles}
            tabContents={tabContents}
            defaultIndex={tabIndex}
            setTabIndex={(index: number) => setTabIndex(index)}
          />
        </div>
        <div className={styles.upgrade}>
          <UpgradePlan />
        </div>
      </div>
      <PasswordChangeModal open={changePasswordModal} setOpen={() => ChangePasswords(false)} />
    </>
  );
};

import { Hash, Lock, Search } from 'lucide-react';
import { InputField, Modal } from '../../common';
import styles from './ChannelModal.module.scss';
import { Badge } from 'rsuite';

interface ChannelModalProps {
  open: boolean;
  closeModal: () => void;
}

export const ChannelModal = ({ open, closeModal }: ChannelModalProps) => {
  return (
    <Modal open={open} closeModal={closeModal} className={styles.selectorModal}>
      <div className={styles.channelSelector}>
        <h4>Acme Corp Channels</h4>
        <InputField icon={<Search />} placeholder='Search channel name' />
        <div className={styles.list}>
          <ul>
            <li className={styles.selected}>
              <Lock color='#fff' /> <p>Exclusive</p>{' '}
              <Badge style={{ background: '#C7DCE8' }} content='5' />
            </li>
            <li>
              <Hash color='#fff' /> <p>Public</p>{' '}
              <Badge style={{ background: '#C7DCE8' }} content='23' />
            </li>
            <li>
              <Hash color='#fff' /> <p>Celebrity Homes 😎</p>
            </li>
            <li>
              <Hash color='#fff' /> <p>Condos</p>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Properties_properties__Ft5lW {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: var(--neutral-white);
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px rgba(18, 38, 63, 0.16);
  overflow: hidden;
  height: calc(100vh - 210px);
}
.Properties_properties__Ft5lW .Properties_map__dtH0W {
  grid-column: span 5;
  background: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/containers/Properties/Properties.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sCAAA;EACA,gCAAA;EACA,mBAAA;EACA,kDAAA;EACA,gBAAA;EACA,2BAAA;AACF;AAAE;EACE,mBAAA;EACA,qBAAA;AAEJ","sourcesContent":[".properties {\n  display: grid;\n  grid-template-columns: repeat(12, 1fr);\n  background: var(--neutral-white);\n  border-radius: 16px;\n  box-shadow: 0px 4px 8px 0px rgba(18, 38, 63, 0.16);\n  overflow: hidden;\n  height: calc(100vh - 210px);\n  .map {\n    grid-column: span 5;\n    background: lightgray;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"properties": `Properties_properties__Ft5lW`,
	"map": `Properties_map__dtH0W`
};
export default ___CSS_LOADER_EXPORT___;

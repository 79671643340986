import {
  LayoutDashboard,
  MessageSquare,
  School,
  SlidersHorizontal,
  Users,
  FileBarChart,
} from 'lucide-react';
import React from 'react';

interface RouteConfig {
  path: string;
  icon: React.ComponentType;
  label: string;
}

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    icon: LayoutDashboard,
    label: 'Dashboard',
  },
  {
    path: '/properties',
    icon: School,
    label: 'Properties',
  },
  {
    path: '/toggles',
    icon: SlidersHorizontal,
    label: 'Toggles',
  },
  {
    path: '/channels',
    icon: Users,
    label: 'Channels',
  },
  {
    path: '/reports',
    icon: FileBarChart,
    label: 'Reports',
  },
  {
    path: '/ai',
    icon: MessageSquare,
    label: 'AI',
  },
];

export default routesConfig;

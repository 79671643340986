import React, { ReactNode } from 'react';
import { Tab, Tabs as CustomTab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styles from './Tabs.module.scss';

interface TabsProps {
  tabTitles: string[];
  tabContents: { content: ReactNode; key: string }[];
  defaultIndex?: number;
  setTabIndex?: (index: number) => void;
}

export const Tabs: React.FC<TabsProps> = ({
  tabTitles,
  tabContents,
  defaultIndex,
  setTabIndex,
}) => {
  return (
    <CustomTab
      className={styles.tabContainer}
      defaultIndex={defaultIndex}
      selectedIndex={defaultIndex}
      onSelect={setTabIndex}
    >
      <TabList>
        {tabTitles.map((title, index) => (
          <Tab key={index}>{title}</Tab>
        ))}
      </TabList>

      {tabContents.map((content) => (
        <TabPanel key={content.key}>{content.content}</TabPanel>
      ))}
    </CustomTab>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateChannelModal_createChannel__kquOm {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.CreateChannelModal_btn__nDAw- {
  justify-content: space-between;
  gap: 16px;
}
.CreateChannelModal_btn__nDAw- button {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Channels/CreateChannelModal/CreateChannelModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AACA;EACE,8BAAA;EACA,SAAA;AAEF;AADE;EACE,SAAA;AAGJ","sourcesContent":[".createChannel {\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n.btn {\n  justify-content: space-between;\n  gap: 16px;\n  button {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createChannel": `CreateChannelModal_createChannel__kquOm`,
	"btn": `CreateChannelModal_btn__nDAw-`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ControlledCheckbox_checkboxContainer__J\\+-ZS {
  display: flex;
  align-items: center;
}
.ControlledCheckbox_checkboxContainer__J\\+-ZS .ControlledCheckbox_customCheckbox__JIs8p {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ControlledCheckbox_checkboxContainer__J\\+-ZS .ControlledCheckbox_customCheckbox__JIs8p span {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: var(--neutral-medium);
  flex: 1 1;
}
.ControlledCheckbox_checkboxContainer__J\\+-ZS .ControlledCheckbox_hiddenCheckbox__oJw\\+r {
  display: none;
}
.ControlledCheckbox_checkboxContainer__J\\+-ZS .ControlledCheckbox_hiddenCheckbox__oJw\\+r:checked + .ControlledCheckbox_styledCheckbox__BHS-T {
  background-color: #6c757d;
  border-color: transparent;
}
.ControlledCheckbox_checkboxContainer__J\\+-ZS .ControlledCheckbox_hiddenCheckbox__oJw\\+r:checked + .ControlledCheckbox_styledCheckbox__BHS-T i {
  visibility: visible;
}
.ControlledCheckbox_checkboxContainer__J\\+-ZS .ControlledCheckbox_styledCheckbox__BHS-T {
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  transition: background-color 150ms;
}
.ControlledCheckbox_checkboxContainer__J\\+-ZS .ControlledCheckbox_styledCheckbox__BHS-T i {
  color: white;
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/common/ControlledCheckbox/ControlledCheckbox.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,4BAAA;EACA,SAAA;AAGN;AAAE;EACE,aAAA;AAEJ;AACQ;EACE,yBAAA;EACA,yBAAA;AACV;AAAU;EACE,mBAAA;AAEZ;AAIE;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,kCAAA;AAFJ;AAGI;EACE,YAAA;EACA,kBAAA;AADN","sourcesContent":[".checkboxContainer {\n  display: flex;\n  align-items: center;\n  .customCheckbox {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    span {\n      font-size: 16px;\n      font-weight: 300;\n      line-height: 24px;\n      color: var(--neutral-medium);\n      flex: 1;\n    }\n  }\n  .hiddenCheckbox {\n    display: none;\n    &:checked {\n      + {\n        .styledCheckbox {\n          background-color: #6c757d;\n          border-color: transparent;\n          i {\n            visibility: visible;\n          }\n        }\n      }\n    }\n  }\n  .styledCheckbox {\n    width: 16px;\n    height: 16px;\n    border: 2px solid #ccc;\n    border-radius: 4px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-right: 8px;\n    transition: background-color 150ms;\n    i {\n      color: white;\n      visibility: hidden;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxContainer": `ControlledCheckbox_checkboxContainer__J+-ZS`,
	"customCheckbox": `ControlledCheckbox_customCheckbox__JIs8p`,
	"hiddenCheckbox": `ControlledCheckbox_hiddenCheckbox__oJw+r`,
	"styledCheckbox": `ControlledCheckbox_styledCheckbox__BHS-T`
};
export default ___CSS_LOADER_EXPORT___;

import { Header } from '../Header';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from '../Auth.module.scss';
import { InputFieldWithController, PrimaryButton, Link } from '../../common';
import { useAxios } from '../../../api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { ERROR } from '../../../constants/common';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(ERROR.REQUIRED_FEILD)
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/,
      'Password must contain at least one special character'
    ),
  confirmPassword: Yup.string()
    .required(ERROR.REQUIRED_FEILD)
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

export const CreateNewPassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const { response, makeRequest, error } = useAxios('auth/update-password');

  useEffect(() => {
    if (response) {
      navigate('/auth/complete');
    }
    if (error) {
      // console.log(error);
      const message = error?.response?.data as Record<string, string>;
      toast.error(message?.message, {
        duration: 4000,
      });
    }
  }, [response]);

  const onSubmit = (data: Record<string, unknown>) => {
    const code = localStorage.getItem('code');
    const email = localStorage.getItem('email');
    data.code = code;
    data.email = email;
    delete data.confirmPassword;
    // console.log(data);
    makeRequest({ method: 'patch', data: data });
  };

  return (
    <>
      <Header
        title='Create a new password'
        content='Please create your new password below.'
        stepper
        count={3}
      />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <InputFieldWithController
          name='password'
          type='password'
          label='Create a password'
          control={control}
          errors={errors}
        />
        <InputFieldWithController
          name='confirmPassword'
          type='password'
          label='Re-type your password'
          control={control}
          errors={errors}
        />
        <PrimaryButton type='submit' value='Reset Password' />
      </form>
      <div className={styles.already}>
        <p>
          You can also <Link to='/auth/login'>login with your email or username</Link> or{' '}
          <Link>contact our support team</Link> if you need assistance with your account or{' '}
        </p>
      </div>
    </>
  );
};

import React, {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  FunctionComponent,
  ReactNode,
} from 'react';

interface SessionContextProps {
  session: Record<string, string> | ReactNode | unknown;
  setSession: Dispatch<SetStateAction<Record<string, string> | ReactNode> | unknown>;
}

export const SessionContext = React.createContext<SessionContextProps>({
  session: {},
  setSession: () => {},
});

export const useSession = () => useContext(SessionContext);

interface PageTitleProviderProps {
  children: React.ReactNode;
}

export const SessionContextProvider: FunctionComponent<PageTitleProviderProps> = ({ children }) => {
  const [session, setSession] = useState<Record<string, string> | ReactNode | unknown>({});
  return (
    <SessionContext.Provider value={{ session, setSession }}>{children}</SessionContext.Provider>
  );
};

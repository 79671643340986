// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Content_content__FgbA1 {
  margin: 48px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Content/Content.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF","sourcesContent":[".content {\n  margin: 48px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Content_content__FgbA1`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_main__8w2NK {
  display: flex;
  min-height: 100vh;
}
.Layout_main__8w2NK .Layout_content__88Srk {
  width: 100%;
  background: var(--neutral-offwhite);
  padding: 0 55px 56px;
}`, "",{"version":3,"sources":["webpack://./src/layout/Layout.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;AACF;AAAE;EACE,WAAA;EACA,mCAAA;EACA,oBAAA;AAEJ","sourcesContent":[".main {\n  display: flex;\n  min-height: 100vh;\n  .content {\n    width: 100%;\n    background: var(--neutral-offwhite);\n    padding: 0 55px 56px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Layout_main__8w2NK`,
	"content": `Layout_content__88Srk`
};
export default ___CSS_LOADER_EXPORT___;

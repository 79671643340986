import { Divider, FlexboxGrid } from 'rsuite';
import { Dropdown, InputField, Modal, PrimaryButton } from '../../common';
import styles from './CreateChannelModal.module.scss';

interface CreateChannelModalProps {
  open: boolean;
  closeModal: () => void;
}

const options = [
  { label: 'Public (anyone can join)', value: 'public' },
  { label: 'Private (only invited members can join)', value: 'private' },
];

export const CreateChannelModal = ({ open, closeModal }: CreateChannelModalProps) => {
  return (
    <Modal open={open} closeModal={closeModal} title='Create a channel' size='xs'>
      <div className={styles.createChannel}>
        <InputField label='Channel name' />
        <Dropdown label='Channel privacy' options={options} placeholder='Select an option' />
        <Divider className='divider' />
        <FlexboxGrid className={styles.btn}>
          <PrimaryButton value='Save' />
          <PrimaryButton onClick={closeModal} value='Cancel' bgColor='#74788C' />
        </FlexboxGrid>
      </div>
    </Modal>
  );
};

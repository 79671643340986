// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Badges_badge__Z4CkB {
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
  padding: 8px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: var(--neutral-white);
}

.Badges_badgeIcon__h0iPK {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.Badges_primary__39oA8 {
  background-color: var(--primary-color);
}

.Badges_success__iHDdU {
  background-color: var(--success);
}

.Badges_info__OB7aD {
  background-color: var(--info-color);
}

.Badges_error__Z4Yui {
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Badges/Badges.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA;AACF;;AAEA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,sCAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,mCAAA;AACF;;AAEA;EACE,qBAAA;AACF","sourcesContent":[".badge {\n  display: inline-flex;\n  align-items: center;\n  border-radius: 16px;\n  padding: 8px 12px;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 18px;\n  color: var(--neutral-white);\n}\n\n.badgeIcon {\n  margin-right: 8px;\n  display: flex;\n  align-items: center;\n}\n\n.primary {\n  background-color: var(--primary-color);\n}\n\n.success {\n  background-color: var(--success);\n}\n\n.info {\n  background-color: var(--info-color);\n}\n\n.error {\n  background-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `Badges_badge__Z4CkB`,
	"badgeIcon": `Badges_badgeIcon__h0iPK`,
	"primary": `Badges_primary__39oA8`,
	"success": `Badges_success__iHDdU`,
	"info": `Badges_info__OB7aD`,
	"error": `Badges_error__Z4Yui`
};
export default ___CSS_LOADER_EXPORT___;

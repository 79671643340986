import styles from './Sidebar.module.scss';
import { PrimaryButton } from '../../common';
import { TrendingUp } from 'lucide-react';
import { useState } from 'react';
import { PricingPlanDialog } from '../../SubscriptionPlans';

export const SideBar = () => {
  const [pricingModal, setPricingModal] = useState(false);
  return (
    <>
      <div className={styles.ChannelListingHolder}>
        <h2>
          <TrendingUp color='#C7DCE8' />
          Usage
        </h2>
        <div className={styles.progressbar}>
          <span style={{ width: '50%' }}></span>
        </div>
        <p>ACME Corp has used 12 out of 20 free Chatbot responses allowed for this workspace.</p>
        <PrimaryButton value='Upgrade' onClick={() => setPricingModal(true)} />
      </div>
      <PricingPlanDialog open={pricingModal} closeModal={() => setPricingModal(false)} />
    </>
  );
};

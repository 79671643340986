import React, { ReactNode } from 'react';
import { RangeSlider, InputGroup, FlexboxGrid, Input } from 'rsuite';
import styles from './RangeSlider.module.scss';

interface CustomRangeSliderProps {
  minValue: number;
  maxValue: number;
  step?: number;
  onChange: (value: [number, number]) => void;
  label?: string;
  inputIcon?: boolean;
  icon?: string | ReactNode;
}

export const CustomRangeSlider: React.FC<CustomRangeSliderProps> = ({
  minValue,
  maxValue,
  step = 1,
  onChange,
  label,
  inputIcon = false,
  icon,
}) => {
  const [value, setValue] = React.useState<[number, number]>([minValue, maxValue]);

  const handleChange = (nextValue: [number, number]) => {
    setValue(nextValue);
    onChange(nextValue);
  };

  const handleInputChange = (index: number, nextValue: number | string) => {
    const newValue = Number(nextValue);
    if (index === 0 && newValue > value[1]) return;
    if (index === 1 && newValue < value[0]) return;
    const updatedValue: [number, number] =
      index === 0 ? [newValue, value[1]] : [value[0], newValue];
    handleChange(updatedValue);
  };

  return (
    <div className={styles.rangeSlider}>
      <label>{label}</label>
      <RangeSlider
        progress
        min={minValue}
        max={maxValue}
        step={step}
        value={value}
        onChange={handleChange}
      />
      <FlexboxGrid className={styles.flex} justify='space-between'>
        <FlexboxGrid>
          <label>Enter minimum</label>
          <InputGroup>
            {inputIcon ? <InputGroup.Addon className={styles.icon}>{icon}</InputGroup.Addon> : null}
            <Input
              min={minValue}
              max={maxValue}
              step={step}
              value={value[0]}
              onChange={(nextValue) => handleInputChange(0, nextValue)}
            />
          </InputGroup>
        </FlexboxGrid>
        <FlexboxGrid>
          <label>Enter maximum</label>
          <InputGroup>
            <InputGroup.Addon className={styles.icon}>{icon}</InputGroup.Addon>
            <Input
              min={minValue}
              max={maxValue}
              step={step}
              value={value[1]}
              onChange={(nextValue) => handleInputChange(1, nextValue)}
            />
          </InputGroup>
        </FlexboxGrid>
      </FlexboxGrid>
    </div>
  );
};

import { Route, Routes } from 'react-router-dom';
import { Profile } from '../../Profile';
import styles from './Content.module.scss';
import { Workspace } from '../../Workspace';
import { Properties } from '../../../containers/Properties';
import { Dashboard } from '../../../containers/Dashboard';
import { PropertyDetail, PropertyForm } from '../../Properties';
import { Toggle } from '../../../containers/Toggles';
import { ToggleCreate, ToggleDetail } from '../../Toggle';
import { StructureValueFilter } from '../../Toggle/ToggleCreate/StructureValueFilter';
import { Channels } from '../../../containers/Channels';
import { AIWrapper } from '../../../containers/AIWrapper';
import { Reports } from '../../../containers/Reports';
import { ProfileDetail } from '../../CommunityProfile';

export const Content = () => {
  return (
    <div className={styles.content}>
      <Routes>
        <Route path='profile' element={<Profile />} />
        <Route path='/profile/:user' element={<ProfileDetail />} />
        <Route path='/myprofile' element={<ProfileDetail />} />
        <Route path='workspace' element={<Workspace />} />
        <Route path='billingAndSubscription' element={<Workspace />} />
        <Route path='inviteUser' element={<Workspace />} />
        <Route path='properties' element={<Properties />} />
        <Route path='property/:id' element={<PropertyDetail />} />
        <Route path='property/create' element={<PropertyForm />} />
        <Route path='toggles' element={<Toggle />} />
        <Route path='propertyDetail/:id' element={<ToggleDetail />} />
        <Route path='toggle/create/result' element={<ToggleDetail />} />
        <Route path='toggle/create' element={<ToggleCreate />} />
        <Route path='toggle/create/step2' element={<StructureValueFilter />} />
        <Route path='channels' element={<Channels />} />
        <Route path='reports' element={<Reports />} />
        <Route path='ai' element={<AIWrapper />} />
        <Route path='/' element={<Dashboard />} />
      </Routes>
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PropertiesListing_listingContainer__8TdQG {
  grid-column: span 7;
  padding: 24px;
}
.PropertiesListing_listingContainer__8TdQG .PropertiesListing_header__atUvt {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  padding-bottom: 28px;
}
.PropertiesListing_listingContainer__8TdQG .PropertiesListing_searchCount__P8tWz {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: 500;
  line-height: 26px;
  padding-bottom: 28px;
}
.PropertiesListing_listingContainer__8TdQG .PropertiesListing_searchCount__P8tWz .PropertiesListing_count__9K5uo {
  color: var(--neutral-dark);
}
.PropertiesListing_listingContainer__8TdQG .PropertiesListing_propertyList__KUz2v {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  overflow: auto;
  height: calc(100vh - 500px);
}
.PropertiesListing_listingContainer__8TdQG .PropertiesListing_label__-DFtD {
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--neutral-medium);
}
.PropertiesListing_listingContainer__8TdQG .PropertiesListing_flex__JPueu {
  flex-wrap: nowrap;
  gap: 24px;
}

.PropertiesListing_filter__g6Z0a {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}
.PropertiesListing_filter__g6Z0a .PropertiesListing_btn__kV16e {
  margin: 20px 0;
}
.PropertiesListing_filter__g6Z0a .PropertiesListing_btn__kV16e button {
  flex: 1 1;
  justify-content: center;
}
.PropertiesListing_filter__g6Z0a .PropertiesListing_custom__POdAS svg path {
  stroke: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/components/Properties/PropertiesListing/PropertiesListing.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;EACA,qBAAA;EACA,oBAAA;AACJ;AACE;EACE,eAAA;EACA,2BAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;AACJ;AAAI;EACE,0BAAA;AAEN;AACE;EACE,aAAA;EACA,qCAAA;EACA,SAAA;EACA,cAAA;EACA,2BAAA;AACJ;AACE;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,4BAAA;AACJ;AACE;EACE,iBAAA;EACA,SAAA;AACJ;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;AACF;AACE;EACE,cAAA;AACJ;AAAI;EACE,SAAA;EACA,uBAAA;AAEN;AAGM;EACE,4BAAA;AADR","sourcesContent":[".listingContainer {\n  grid-column: span 7;\n  padding: 24px;\n\n  .header {\n    display: flex;\n    gap: 16px;\n    align-items: flex-end;\n    padding-bottom: 28px;\n  }\n  .searchCount {\n    font-size: 20px;\n    color: var(--primary-color);\n    font-weight: 500;\n    line-height: 26px;\n    padding-bottom: 28px;\n    .count {\n      color: var(--neutral-dark);\n    }\n  }\n  .propertyList {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 24px;\n    overflow: auto;\n    height: calc(100vh - 500px);\n  }\n  .label {\n    padding-bottom: 8px;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 18px;\n    text-transform: uppercase;\n    color: var(--neutral-medium);\n  }\n  .flex {\n    flex-wrap: nowrap;\n    gap: 24px;\n  }\n}\n.filter {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 24px;\n\n  .btn {\n    margin: 20px 0;\n    button {\n      flex: 1;\n      justify-content: center;\n    }\n  }\n  .custom {\n    svg {\n      path {\n        stroke: var(--primary-color);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listingContainer": `PropertiesListing_listingContainer__8TdQG`,
	"header": `PropertiesListing_header__atUvt`,
	"searchCount": `PropertiesListing_searchCount__P8tWz`,
	"count": `PropertiesListing_count__9K5uo`,
	"propertyList": `PropertiesListing_propertyList__KUz2v`,
	"label": `PropertiesListing_label__-DFtD`,
	"flex": `PropertiesListing_flex__JPueu`,
	"filter": `PropertiesListing_filter__g6Z0a`,
	"btn": `PropertiesListing_btn__kV16e`,
	"custom": `PropertiesListing_custom__POdAS`
};
export default ___CSS_LOADER_EXPORT___;

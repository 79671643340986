import { useEffect, useState } from 'react';
import { usePageTitle } from '../../context/PageTitleContext';
import { UpgradePlan } from '../UpgradePlan';
import {
  ControlledDropdown,
  InputFieldWithController,
  PrimaryButton,
  SecondaryButton,
  Tabs,
} from '../common';
import styles from './Workspace.module.scss';
import { useForm } from 'react-hook-form';
import Avatar from 'react-avatar';
import { FormButtons } from '../FormButtons';
import { PricingPlanDialog } from '../SubscriptionPlans';
import { InviteUserDialog } from '../InviteUser';
import { useLocation, useNavigate } from 'react-router-dom';
import { Trash2, UserPlus } from 'lucide-react';
import { useAxios } from '../../api';
import toast from 'react-hot-toast';
import { WorkspaceUsersList } from './WorkspaceUsersList';
import { useWorkspace } from '../../context/WorkspaceContext';
import { UploadButton } from '../UploadButton';

interface memberDetails {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface workspaceDetailProps {
  data: {
    _id: string;
    members: Record<string, unknown>[];
    memberDetails: Record<string, unknown>[];
  };
}

export const Workspace = () => {
  const { workspace } = useWorkspace();
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  const location = useLocation();

  const [pricingModal, setPricingModal] = useState(false);
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const { response, makeRequest } = useAxios('workspace/' + workspace?.workspaceId);
  const [workspaceList, setWorkspaceList] = useState<Record<string, unknown>>({});

  useEffect(() => {
    makeRequest({ method: 'get' });
  }, [workspace]);

  useEffect(() => {
    if (response) {
      const data = (response as { data: Record<string, unknown> }).data;
      setWorkspaceList(data);
    }
  }, [response]);

  useEffect(() => {
    if (location.pathname === '/workspace') {
      setTabIndex(0);
    } else if (location.pathname === '/inviteUser') {
      setTabIndex(2);
    } else {
      setTabIndex(1);
    }
  }, [location.pathname]);

  useEffect(() => {
    setPageTitle('Workspace Settings');
  }, []);

  const memberUserIds = (workspaceList?.memberDetails as memberDetails[])?.map(
    (memberDetails: memberDetails) => {
      return {
        label:
          memberDetails.firstName + ' ' + memberDetails.lastName + ' (' + memberDetails.email + ')',
        value: memberDetails._id,
      };
    }
  );

  useEffect(() => {
    if (workspace?.role === 'Member' || workspace?.role === 'Guest') {
      navigate('/');
    }
  }, [workspace?.role, navigate]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  useEffect(() => {
    // console.log(workspace);
    reset({
      workspaceName: workspace?.details?.name,
      workspaceOwner: '',
    });
  }, [workspace, reset, setValue, workspaceList]);

  const {
    response: changeResponse,
    makeRequest: changeWorkspace,
    error,
  } = useAxios(`workspace/settings/${workspace?.workspaceId}`);

  useEffect(() => {
    setPageTitle('Account Settings');
    if (changeResponse) {
      reset({
        workspaceName: '',
      });
    }
    if (error) {
      const message = error?.response?.data as Record<string, string>;
      toast.error(message?.message, {
        duration: 4000,
      });
    }
  }, [response, error]);

  const onSubmit = (data: Record<string, string>) => {
    const body = {
      name: data.workspaceName,
    } as Record<string, string>;

    if (data.workspaceOwner) {
      (body as Record<string, string>).userId = data.workspaceOwner;
    }
    // delete data.workspaceName;
    // delete data.workspaceOwner;
    // makeRequest({ method: 'post', data: body });
    changeWorkspace({ method: 'patch', data: body });
  };

  useEffect(() => {
    reset({});
  }, [reset]);

  const handleSaveChanges = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmit(onSubmit)();
    }
  };

  const workspaceSetting = (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className={styles.details}>
          <h5>Workspace details</h5>
          <InputFieldWithController
            name='workspaceName'
            type='text'
            errors={errors}
            label='Workspace name'
            control={control}
            required
          />
          <div className={styles.avatar}>
            <Avatar size='75' round={'16'} name='A' />
            <div className={styles.right}>
              <p>Upload an image to personalize your workspace (optional). </p>
              <div className={styles.btn}>
                <UploadButton onImageChange={() => {}} />
                <SecondaryButton
                  value='Delete Photo'
                  noBackground={true}
                  noBorder={true}
                  icon={<Trash2 size={16} />}
                  textColor='#ea3c50'
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.owner}>
          <h5>Workspace owner</h5>
          <p>
            You are currently the workspace owner. Here you can choose another user to be
            responsible for this workspace.
          </p>
          {memberUserIds ? (
            <ControlledDropdown
              name='workspaceOwner'
              control={control}
              errors={errors}
              label='Workspace Owner'
              options={memberUserIds}
              placeholder='Select Owner'
            />
          ) : null}
        </div>
      </form>
      <FormButtons workSpace onSaveChanges={handleSaveChanges} />
    </>
  );

  const billing = (
    <>
      <div className={styles.subscription}>
        <div>
          <h5>Subscription details</h5>
          <p>You don't have an active subscription</p>
        </div>
        <PrimaryButton onClick={() => setPricingModal(true)} value='Subscribe' />
      </div>
      <div className={styles.subscription}>
        <div>
          <h5>Billing details</h5>
          <p>Access your billing history, download invoices and update your billing information.</p>
        </div>
        <PrimaryButton value='Billing History' />
      </div>
    </>
  );

  const users = (
    <>
      <div className={styles.subscription}>
        <div>
          <h5>Workspace members</h5>
          <p>Manage who can access your workspace</p>
        </div>
        <PrimaryButton
          onClick={() => setInviteUserModal(true)}
          icon={<UserPlus />}
          value='Invite user'
        />
      </div>
      <div className={styles.subscription}>
        <WorkspaceUsersList workspace={response as workspaceDetailProps} />
      </div>
    </>
  );

  const tabTitles = ['Workspace Settings', 'Billing & Subscription', 'Users'];
  const tabContents = [
    { content: workspaceSetting, key: 'setting' },
    { content: billing, key: 'billing' },
    { content: users, key: 'users' },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.workSpaceContainer}>
        <Tabs
          tabTitles={tabTitles}
          tabContents={tabContents}
          defaultIndex={tabIndex}
          setTabIndex={(index: number) => setTabIndex(index)}
        />
      </div>
      <div className={styles.upgrade}>
        <UpgradePlan />
      </div>
      <PricingPlanDialog open={pricingModal} closeModal={() => setPricingModal(false)} />
      <InviteUserDialog open={inviteUserModal} closeModal={() => setInviteUserModal(false)} />
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';

interface Properties {
  toggleList: any;
  todoList: any;
  workSpaceDetails: any;
  toggle_Lot_location: any;
  toggle_Structure_condition_value: any;
  toggleDetails: any;
  advanceModeLot: any;
  // propertyDetails: any;
  // favoutites: any;
  loading: boolean;
  error: boolean;
}

const initialState: Properties = {
  toggleList: {},
  todoList: {},
  workSpaceDetails: {},
  toggle_Lot_location: {},
  toggle_Structure_condition_value: {},
  toggleDetails: {},
  advanceModeLot: {},
  // favoutites: {},
  loading: false,
  error: false,
};
const toggles: any = createSlice({
  name: 'toggle management',
  initialState,
  reducers: {
    updateToggle: (state: any, action: any) => {
      return { ...state, ...action.payload };
    },
    resetToggle: () => {
      return initialState;
    },
  },
});

export const { updateToggle, resetToggle } = toggles.actions;

export default toggles.reducer;

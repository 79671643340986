import { InputFieldWithController, Modal, PrimaryButton, SecondaryButton } from '../common';
import styles from './PasswordChangeModal.module.scss';
import { useAxios } from '../../api';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ERROR } from '../../constants/common';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required(ERROR.REQUIRED_FEILD),
  password: Yup.string().required(ERROR.REQUIRED_FEILD),
});

interface NewWorkspaceModalProps {
  open: boolean;
  setOpen: () => void;
}

export const PasswordChangeModal = ({ open, setOpen }: NewWorkspaceModalProps) => {
  const { response, makeRequest, error } = useAxios('auth/change-password');
  const {
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (response) {
      reset({
        oldPassword: '',
        password: '',
      });
      toast.success('Password chnages succesfullly', {
        duration: 4000,
      });
    }
    if (error) {
      const message = error?.response?.data as Record<string, string>;
      toast.error(message?.message, {
        duration: 4000,
      });
    }
  }, [response, error]);

  const onSubmit = (data: Record<string, unknown>) => {
    if (data.oldPassword === data.password) {
      toast.error('Old password and new password cannot be the same', {
        duration: 4000,
      });
      return;
    }
    makeRequest({ method: 'patch', data: data });
  };
  useEffect(() => {
    reset({});
  }, [reset]);

  const handleSaveChanges = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmit(onSubmit)();
    }
  };

  const handleCancel = () => {
    reset({
      oldPassword: '',
      password: '',
    });
    setOpen();
  };

  return (
    <Modal open={open} closeModal={setOpen} title='Change Password'>
      <div className={styles.passwordFormModel}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWithController
            name='oldPassword'
            type='password'
            errors={errors}
            label='Old Password'
            control={control}
            required
          />
          <InputFieldWithController
            name='password'
            type='password'
            errors={errors}
            label='New Password'
            control={control}
            required
          />
        </form>

        <div className={styles.formBtnsProfile}>
          <PrimaryButton value='Save Changes' onClick={handleSaveChanges} />
          <SecondaryButton bgColor='#74788C' value='Cancel' onClick={handleCancel} />
        </div>
      </div>
    </Modal>
  );
};

import { useEffect } from 'react';
import { SecondaryButton, Table } from '../../components/common';
import styles from '../Dashboard/Dashboard.module.scss';
import { usePageTitle } from '../../context/PageTitleContext';
import { ReportSearch } from '../../components/Reports';
import { Trash2 } from 'lucide-react';

const todoData: Record<string, unknown>[] = [
  {
    id: 1,
    market: 'Sausalito, CA',
    toggleId: 'TGL-12345',
    add: '10/30/2023',
  },
  {
    id: 2,
    market: 'Sausalito, CA',
    toggleId: 'TGL-12345',
    add: '10/30/2023',
  },
  {
    id: 3,
    market: 'Sausalito, CA',
    toggleId: 'TGL-12345',
    add: '10/30/2023',
  },
  {
    id: 4,
    market: 'Sausalito, CA',
    toggleId: 'TGL-12345',
    add: '10/30/2023',
  },
  {
    id: 5,
    market: 'Sausalito, CA',
    toggleId: 'TGL-12345',
    add: '10/30/2023',
  },
];

export const Reports = () => {
  const { setPageTitle } = usePageTitle();

  const header = (
    <div className='page-header'>
      <h5>Reports</h5>
    </div>
  );
  useEffect(() => {
    setPageTitle(header);
  }, []);

  const handleSort = (sortColumn: string, sortType?: 'asc' | 'desc') => {
    console.log(sortColumn, sortType);
  };

  const toggleRenderer = () => {
    return (
      <div className={styles.property}>
        <img src='/assets/banner4.jpg' alt='' />
        <div>
          <h5>161 Harrison Ave, Sausalito, CA 94965</h5>
        </div>
      </div>
    );
  };

  const actionRenderer = () => {
    return (
      <div className={styles.action}>
        <SecondaryButton bgColor='#06417E' textColor='#fff' value='View report' />
        <SecondaryButton
          className={styles.delete}
          icon={<Trash2 />}
          onClick={() => console.log('Delete')}
        />
      </div>
    );
  };

  const columnsTodo = [
    {
      title: 'Property',
      dataKey: 'property',
      sortable: true,
      width: 400,
      renderer: toggleRenderer,
    },
    { title: 'Housing market', dataKey: 'market', sortable: true, flexGrow: 1 },
    { title: 'Report date', dataKey: 'add', sortable: true, flexGrow: 1 },
    {
      title: 'Toggle id',
      dataKey: 'toggleId',
      sortable: true,
      flexGrow: 1,
    },
    { title: 'Actions', dataKey: 'actions', renderer: actionRenderer, flexGrow: 1 },
  ];

  const todoRenderer = (
    <>
      <Table
        height={400}
        data={todoData}
        columns={columnsTodo}
        pageSize={10}
        onSort={handleSort}
        loading={false}
        pagination
      />
    </>
  );
  return (
    <div className={styles.dashboard}>
      <div className={styles.new}>
        <ReportSearch />
        {todoRenderer}
      </div>
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_table__YxNsj .rs-pagination-group {
  margin: 0 auto;
  padding: 16px 0;
  justify-content: center;
}
.Table_table__YxNsj .rs-pagination-group .rs-pagination {
  display: flex;
}
.Table_table__YxNsj .rs-pagination-group .rs-pagination button {
  display: flex;
  font-size: 14px;
  color: var(--neutral-medium);
  font-weight: 600;
  line-height: 18px;
}
.Table_table__YxNsj .rs-pagination-group .rs-pagination button.rs-pagination-btn-active {
  background: var(--primary-color);
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.Table_table__YxNsj .Table_header__xImn- {
  color: #000;
}
.Table_table__YxNsj .Table_header__xImn- .rs-table-cell {
  border-bottom: 1px solid var(--neutral-light-gray);
}
.Table_table__YxNsj .Table_header__xImn- .rs-table-cell .rs-table-cell-content {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: var(--neutral-medium);
  text-transform: uppercase;
  padding: 8px;
}
.Table_table__YxNsj .Table_row__PvTJP {
  border-bottom: 1px solid var(--neutral-light-gray);
}
.Table_table__YxNsj .Table_row__PvTJP .rs-table-cell-content {
  padding: 8px;
  display: flex;
  align-items: center;
}
.Table_table__YxNsj .Table_row__PvTJP .rs-table-cell-content .rs-table-cell-wrap {
  font-size: 16px;
  color: var(--neutral-medium);
  font-weight: 500;
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Table/Table.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,eAAA;EACA,uBAAA;AAAJ;AACI;EACE,aAAA;AACN;AAAM;EACE,aAAA;EACA,eAAA;EACA,4BAAA;EACA,gBAAA;EACA,iBAAA;AAER;AADQ;EACE,gCAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AAGV;AAEE;EACE,WAAA;AAAJ;AACI;EACE,kDAAA;AACN;AAAM;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,4BAAA;EACA,yBAAA;EACA,YAAA;AAER;AAEE;EACE,kDAAA;AAAJ;AACI;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AACN;AAAM;EACE,eAAA;EACA,4BAAA;EACA,gBAAA;EACA,iBAAA;AAER","sourcesContent":[".table {\n  :global .rs-pagination-group {\n    margin: 0 auto;\n    padding: 16px 0;\n    justify-content: center;\n    .rs-pagination {\n      display: flex;\n      button {\n        display: flex;\n        font-size: 14px;\n        color: var(--neutral-medium);\n        font-weight: 600;\n        line-height: 18px;\n        &.rs-pagination-btn-active {\n          background: var(--primary-color);\n          color: #fff;\n          border-radius: 50%;\n          width: 35px;\n          height: 35px;\n        }\n      }\n    }\n  }\n  .header {\n    color: #000;\n    :global .rs-table-cell {\n      border-bottom: 1px solid var(--neutral-light-gray);\n      .rs-table-cell-content {\n        font-size: 14px;\n        font-weight: 600;\n        line-height: 18px;\n        color: var(--neutral-medium);\n        text-transform: uppercase;\n        padding: 8px;\n      }\n    }\n  }\n  .row {\n    border-bottom: 1px solid var(--neutral-light-gray);\n    :global .rs-table-cell-content {\n      padding: 8px;\n      display: flex;\n      align-items: center;\n      .rs-table-cell-wrap {\n        font-size: 16px;\n        color: var(--neutral-medium);\n        font-weight: 500;\n        line-height: 24px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Table_table__YxNsj`,
	"header": `Table_header__xImn-`,
	"row": `Table_row__PvTJP`
};
export default ___CSS_LOADER_EXPORT___;

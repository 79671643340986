import React from 'react';
import { SecondaryButton } from '../../common';

interface TredingCarddec {
  TrendingListTitle: string;
  TrendingListTitleDec: string;
}

interface TredingCardProps {
  property: TredingCarddec;
}

const ChannelsList: React.FC<TredingCardProps> = ({ property }) => {
  return (
    <>
      <div>
        <h4>{property.TrendingListTitle}</h4>
        <span>{property.TrendingListTitleDec}</span>
      </div>
      <SecondaryButton value='View' iconPosition='right' />
    </>
  );
};

export default ChannelsList;

import { InputField, Modal, PrimaryButton, SecondaryButton } from '../../common';
import styles from './PropertyDataModal.module.scss';
import { Divider, FlexboxGrid } from 'rsuite';
import { CheckCircle, Expand, RefreshCcw, X } from 'lucide-react';

interface PropertyDataModalProps {
  open: boolean;
  setOpen: () => void;
}

export const PropertyDataModal = ({ open, setOpen }: PropertyDataModalProps) => {
  return (
    <Modal open={open} closeModal={setOpen} title='Adjust property data'>
      <div className={styles.editProperty}>
        <p>
          You can adjust the property and lot dimensions for comp purposes only. This will not
          affect the property database.
        </p>
        <div className={styles.btn}>
          <FlexboxGrid className={styles.flex}>
            <InputField label='House sqft.' icon={<Expand />} />
            <PrimaryButton value='Rank 100%' />
          </FlexboxGrid>
          <FlexboxGrid className={styles.flex}>
            <InputField label='Lot size sqft.' icon={<Expand />} />
            <PrimaryButton value='Rank 98%' />
          </FlexboxGrid>
        </div>
        <SecondaryButton value='Reset fields' icon={<RefreshCcw />} noBackground />
        <Divider className='divider' />
        <div className={styles.button}>
          <PrimaryButton value='Update' icon={<CheckCircle />} />
          <PrimaryButton value='Cancel' icon={<X />} bgColor='#C7DCE8' textColor='#06417E' />
        </div>
      </div>
    </Modal>
  );
};

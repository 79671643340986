// CombinedProvider.tsx
import React from 'react';
import { PageTitleProvider } from './PageTitleContext';
import { SessionContextProvider } from './SessionContext';
import { WorkspaceProvider } from './WorkspaceContext';

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  return (
    <SessionContextProvider>
      <PageTitleProvider>
        <WorkspaceProvider>{children}</WorkspaceProvider>
      </PageTitleProvider>
    </SessionContextProvider>
  );
};

import { useEffect, useState } from 'react';
import { usePageTitle } from '../../../context/PageTitleContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton, PrimaryButton, SecondaryButton, Tabs } from '../../common';
import styles from './ToggleCreate.module.scss';
import { Divider, FlexboxGrid, Slider } from 'rsuite';
import Avatar from 'react-avatar';
import { ArrowLeftCircle, ChevronLeft, ChevronRight, Edit, MessagesSquare } from 'lucide-react';
import {
  SaveToggle,
  ToggleSliderAdvnaceTwo,
  ToggleSliderFirst,
} from '../../../containers/Toggles/toggleAction';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

export const StructureValueFilter = () => {
  const { setPageTitle } = usePageTitle();
  const dispatch: any = useDispatch();

  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [structureSliderValue, setStructureSliderValue] = useState(0);
  const { toggle_Lot_location, toggle_Structure_condition_value, advanceModeLot } = useSelector(
    (state: any) => state.toggle
  );
  const [advanceLotValue, setAdvanceLotValue] = useState<any>({
    design_slider: 0,
    detail_slider: 0,
    condition_slider: 0,
  });

  const { state } = useLocation();

  const header = (
    <div className='page-header'>
      <IconButton
        icon={<ArrowLeftCircle size={64} strokeWidth='1' color='#14192F' />}
        onClick={() => navigate(-1)}
        className='shadow-less'
      />
      <h5>Create toggle</h5>
    </div>
  );

  // console.log('State', state);
  // console.log('toggle_Lot_location', toggle_Lot_location);
  // console.log('toggle_Structure_condition_value', toggle_Lot_location);

  useEffect(() => {
    setPageTitle(header);
  }, []);

  // console.log(toggle_Lot_location);

  const handleSliderChange = (value: number) => {
    setStructureSliderValue(value);
    const payload = {
      lot_location: toggle_Lot_location?.rank_lot_location,
      structure_condition: value,
      lot_sqft: 1000,
      house_sqft: 1000,
      lot_size: 1000,
      zip: '90024',
    };
    dispatch(ToggleSliderFirst(payload, 'structure_condition', () => {}));
  };

  const advanceHandleSliderChange = (value: number, type: string) => {
    setAdvanceLotValue({ ...advanceLotValue, [type]: value });

    const payload: any = {
      design_slider: 0,
      detail_slider: 0,
      condition_slider: 0,
      house_sqft: 1000,
      lot_location_value: 2229291,
      lot_sqft: 1000,
      structure_location_value: 779000,
      zip: '90024',
    };

    payload[type] = value;

    dispatch(ToggleSliderAdvnaceTwo(payload, 'lot_location', () => {}));
  };

  // console.log('advanceLotValue', advanceModeLot);

  const handleAddToggle = () => {
    const payload = {
      propertyId: state?.propertyDetails?._id,
      // workspaceId: state?.workspace?.workspaceId,
      estimated_price:
        tabIndex === 0
          ? toggle_Structure_condition_value?.estimated_price
          : advanceModeLot?.estimated_price,
      estimated_price_rank: 0,
      value_sqft: 0,
      value_sqft_rank: 0,
      lot_location_value:
        tabIndex === 0
          ? toggle_Structure_condition_value?.lot_location_value
          : advanceModeLot?.estimated_price,
      lot_value_rank: toggle_Structure_condition_value?.rank_lot_location,
      structure_location_value: toggle_Structure_condition_value?.structure_location_value,
      structure_value_rank: structureSliderValue,
      structure_quality: 0,
      structure_quality_rank: 0,
      house_sqft: 0,
      house_sqft_rank: toggle_Structure_condition_value?.house_sqft_rank,
      lot_sqft: 0,
      lot_size_rank: toggle_Structure_condition_value?.lot_size_rank,
      hvr: 0,
      toggle_id: 0,
    };
    dispatch(
      SaveToggle(payload, () => {
        toast.success('Toggle Created Successfully.');
        navigate(`/property/${state?.propertyDetails?._id}`, {
          state: {
            id: state?.propertyDetails?._id,
          },
        });
      })
    );
  };

  console.log(state);

  const basicTabRender = (
    <div className={styles.mode}>
      <div className={styles.head}>
        <div>
          <h5>Structure-condition value: ${toggle_Structure_condition_value?.estimated_price}</h5>
          <p>
            How would you rate the <strong>design</strong>, <strong>finish</strong> and{' '}
            <strong>condition</strong>?
          </p>
        </div>
        <PrimaryButton value={`Rank ${structureSliderValue}%`} />
      </div>
      <div className={styles.range}>
        <Slider
          tooltip={false}
          step={1}
          value={structureSliderValue}
          progress
          onChange={handleSliderChange}
        />
        <div className={styles.scaleMarkings}>
          {Array.from({ length: 28 }).map((_, index) => (
            <span key={index} className={styles.scaleMark}></span>
          ))}
        </div>
        <div className={styles.labels}>
          <span className={`${styles.label} ${styles.poor}`}>POOR</span>
          <span className={`${styles.label} ${styles.average}`}>AVERAGE</span>
          <span className={`${styles.label} ${styles.excellent}`}>EXCELLENT</span>
        </div>
      </div>
    </div>
  );

  const advanceTabRender = (
    <div className={styles.mode}>
      <div className={styles.filter}>
        <div className={styles.head}>
          <div>
            <h5>Architecture: $ 0,000,000</h5>
            <p>
              How would you rate the <strong>design</strong>, <strong>flow & layout</strong> and{' '}
              <strong>curb & appeal</strong> ?
            </p>
          </div>
          <PrimaryButton value={`Rank ${advanceLotValue?.design_slider}%`} />
        </div>
        <div className={styles.range}>
          <Slider
            tooltip={false}
            step={1}
            progress
            value={advanceLotValue?.design_slider}
            onChange={(e: any) => advanceHandleSliderChange(e, 'design_slider')}
          />
          <div className={styles.scaleMarkings}>
            {Array.from({ length: 28 }).map((_, index) => (
              <span key={index} className={styles.scaleMark}></span>
            ))}
          </div>
          <div className={styles.labels}>
            <span className={`${styles.label} ${styles.poor}`}>POOR</span>
            <span className={`${styles.label} ${styles.average}`}>AVERAGE</span>
            <span className={`${styles.label} ${styles.excellent}`}>EXCELLENT</span>
          </div>
        </div>
      </div>
      <div className={styles.filter}>
        <div className={styles.head}>
          <div>
            <h5>House features: $ 0,000,000</h5>
            <p>
              How would you rate the <strong>detail & finish</strong>, <strong>features</strong> and{' '}
              <strong>build quality</strong> ?
            </p>
          </div>
          <PrimaryButton value={`Rank ${advanceLotValue?.detail_slider}%`} />
        </div>
        <div className={styles.range}>
          <Slider
            tooltip={false}
            step={1}
            progress
            value={advanceLotValue?.detail_slider}
            onChange={(e: any) => advanceHandleSliderChange(e, 'detail_slider')}
          />
          <div className={styles.scaleMarkings}>
            {Array.from({ length: 28 }).map((_, index) => (
              <span key={index} className={styles.scaleMark}></span>
            ))}
          </div>
          <div className={styles.labels}>
            <span className={`${styles.label} ${styles.poor}`}>POOR</span>
            <span className={`${styles.label} ${styles.average}`}>AVERAGE</span>
            <span className={`${styles.label} ${styles.excellent}`}>EXCELLENT</span>
          </div>
        </div>
      </div>
      <div className={styles.filter}>
        <div className={styles.head}>
          <div>
            <h5>Condition & maintenance: $ 0,000,000</h5>
            <p>
              How would you rate the <strong> external maintenance,</strong>,{' '}
              <strong> internal condition</strong> and <strong>internal system</strong> ?
            </p>
          </div>
          <PrimaryButton value={`Rank ${advanceLotValue?.condition_slider}%`} />
        </div>
        <div className={styles.range}>
          <Slider
            tooltip={false}
            step={1}
            progress
            value={advanceLotValue?.condition_slider}
            onChange={(e: any) => advanceHandleSliderChange(e, 'condition_slider')}
          />
          <div className={styles.scaleMarkings}>
            {Array.from({ length: 28 }).map((_, index) => (
              <span key={index} className={styles.scaleMark}></span>
            ))}
          </div>
          <div className={styles.labels}>
            <span className={`${styles.label} ${styles.poor}`}>POOR</span>
            <span className={`${styles.label} ${styles.average}`}>AVERAGE</span>
            <span className={`${styles.label} ${styles.excellent}`}>EXCELLENT</span>
          </div>
        </div>
      </div>
    </div>
  );

  const tabTitles = ['Basic mode', 'Advance mode'];
  const tabContents = [
    { content: basicTabRender, key: 'basic' },
    { content: advanceTabRender, key: 'advance' },
  ];

  return (
    <div className={styles.create}>
      <div className={styles.left}>
        <div className={styles.header}>
          <h5>Step 2: Structure-condition value</h5>
          <SecondaryButton
            value='Ask AI Assistant'
            icon={<MessagesSquare size={16} />}
            bgColor='#06417E'
            textColor='#fff'
          />
        </div>
        <div className={styles.tab}>
          <Tabs
            tabTitles={tabTitles}
            tabContents={tabContents}
            defaultIndex={tabIndex}
            setTabIndex={(index: number) => setTabIndex(index)}
          />
          <Divider className='divider' />
          <div className={styles.btn}>
            <PrimaryButton
              value='Previous'
              icon={<ChevronLeft />}
              bgColor='#C7DCE8'
              textColor='#06417E'
              onClick={() => navigate(-1)}
            />
            <PrimaryButton
              value='Next'
              icon={<ChevronRight />}
              iconPosition='right'
              onClick={handleAddToggle}
            />
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.selectedProperty}>
          <div className={styles.head}>
            <h5>Selected Property</h5>
            <SecondaryButton
              value='Edit'
              icon={<Edit size={16} />}
              noBorder
              noBackground
              iconPosition='right'
              textColor='#fff'
              className={styles.edit}
            />
          </div>
          <div className={styles.address}>
            <FlexboxGrid align='middle' style={{ gap: '16px', flexWrap: 'nowrap' }}>
              <Avatar name='WM' size='80' round={'8px'} />
              <p>{state?.propertyDetails.address || 'N/A'}</p>
            </FlexboxGrid>
          </div>
        </div>
        <div className={styles.est}>
          <h5>Estimated value</h5>
          <p>${toggle_Structure_condition_value?.estimated_price || '00'}</p>
          <p className={styles.rank}>100 %</p>
        </div>
        <div className={`${styles.details} ${styles.noRadius}`}>
          <div className={styles.head}>
            <h5>Property Details</h5>
            <SecondaryButton
              value='Edit'
              icon={<Edit size={16} />}
              noBorder
              noBackground
              iconPosition='right'
              textColor='#fff'
              className={styles.edit}
            />
          </div>
          <div className={styles.info}>
            <ul>
              <li>
                <h5>House size</h5>
                <p>3,286 sqft</p>
                <p className={styles.rank}>100%</p>
              </li>
              <li>
                <h5>Lot size</h5>
                <p>9,888</p>
                <p className={styles.rank}>89%</p>
              </li>
            </ul>
          </div>
        </div>
        <div className={`${styles.details} ${styles.noRadius}`}>
          <div className={styles.head}>
            <h5>Total LLV</h5>
            <SecondaryButton
              value='Edit'
              icon={<Edit size={16} />}
              noBorder
              noBackground
              iconPosition='right'
              textColor='#fff'
              className={styles.edit}
            />
          </div>
          <div className={styles.info}>
            <ul>
              <li>
                <h5>Lot-location value</h5>
                <p>$ 1,545,044</p>
                <p className={styles.rank}>63%</p>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.head}>
            <h5>Total SCV</h5>
            <SecondaryButton
              value='Edit'
              icon={<Edit size={16} />}
              noBorder
              noBackground
              iconPosition='right'
              textColor='#fff'
              className={styles.edit}
            />
          </div>
          <div className={styles.info}>
            <ul>
              <li>
                <h5>Structure-condition value</h5>
                <p>${toggle_Structure_condition_value?.structure_location_value || '00'}</p>
                <p className={styles.rank}>98%</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

import { AIChat } from '../../components/AIChatWrapper/AIChat';
import { SideBar } from '../../components/common/SideBar/SideBar';

export const AIWrapper = () => {
  return (
    <div className='globalSideContentHolder'>
      <AIChat />
      <SideBar />
    </div>
  );
};

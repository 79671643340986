// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChannelsMember_ChannleMemberholder__G4Tkc {
  display: flex;
  gap: 10px;
}
.ChannelsMember_ChannleMemberholder__G4Tkc .ChannelsMember_profilePic__w\\+-th {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  overflow: hidden;
  background: var(--neutral-medium);
}
.ChannelsMember_ChannleMemberholder__G4Tkc .ChannelsMember_profilePic__w\\+-th img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ChannelsMember_ChannleMemberholder__G4Tkc .ChannelsMember_ChannleMemberMedia__qd6of {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
}
.ChannelsMember_ChannleMemberholder__G4Tkc .ChannelsMember_ChannleMemberMedia__qd6of h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.ChannelsMember_ChannleMemberholder__G4Tkc .ChannelsMember_ChannleMemberMedia__qd6of span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: var(--neutral-medium);
}
.ChannelsMember_ChannleMemberholder__G4Tkc .ChannelsMember_ChannleMemberMedia__qd6of button {
  background: var(--neutral-light);
  height: 34px;
  color: var(--primary-color);
  padding: 8px 12px;
}
.ChannelsMember_ChannleMemberholder__G4Tkc .ChannelsMember_ChannleMemberMedia__qd6of .ChannelsMember_buttonHolder__97uai {
  display: flex;
  align-items: center;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Channels/ChannelsMember/ChannelsMember.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,iCAAA;AAER;AADQ;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AAGZ;AAAI;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;AAER;AADQ;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAGZ;AADQ;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,4BAAA;AAGZ;AADQ;EACI,gCAAA;EACA,YAAA;EACA,2BAAA;EACA,iBAAA;AAGZ;AADQ;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AAGZ","sourcesContent":[".ChannleMemberholder{ \n    display: flex;\n    gap:10px;\n    & .profilePic{\n        width:48px;\n        height:48px;\n        border-radius:100%;\n        overflow: hidden;\n        background:var(--neutral-medium);\n        & img{\n            width:100%;\n            height:100%;\n            object-fit: cover;\n        }\n    }\n    & .ChannleMemberMedia{\n        display: flex;\n        justify-content: space-between;\n        align-items:center;\n        flex: 1;\n        & h4{\n            font-size: 16px;\n            font-style: normal;\n            font-weight: 500;\n            line-height: 24px; \n        }\n        & span{\n            font-size: 14px;\n            font-style: normal;\n            font-weight: 500;\n            color:var(--neutral-medium);\n        }\n        & button{\n            background:var(--neutral-light);\n            height:34px;\n            color:var(--primary-color);\n            padding: 8px 12px;\n        }\n        & .buttonHolder{\n            display: flex;\n            align-items:center;\n            gap:10px;\n        }\n    }\n     \n    \n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChannleMemberholder": `ChannelsMember_ChannleMemberholder__G4Tkc`,
	"profilePic": `ChannelsMember_profilePic__w+-th`,
	"ChannleMemberMedia": `ChannelsMember_ChannleMemberMedia__qd6of`,
	"buttonHolder": `ChannelsMember_buttonHolder__97uai`
};
export default ___CSS_LOADER_EXPORT___;

import { DatePicker as CustomDatePicker, DatePickerProps } from 'rsuite';
import styles from './DatePicker.module.scss';

interface CustomDatePickerProps extends DatePickerProps {
  label?: string;
  placeholder?: string;
}
export const DatePicker = ({ label, placeholder, ...props }: CustomDatePickerProps) => {
  return (
    <div className={styles.datepicker}>
      <label>{label}</label>
      <CustomDatePicker oneTap block placeholder={placeholder} {...props} />
    </div>
  );
};

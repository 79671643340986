import { Controller, Control, FieldErrors, FieldValues, Path } from 'react-hook-form';
import styles from './InputFieldWithController.module.scss';
import { ReactNode, useState } from 'react';

interface InputFieldWithControllerProps<T extends FieldValues> {
  name: Path<T>;
  type: string;
  control: Control<T>;
  label?: string;
  errors?: FieldErrors<T>;
  required?: boolean;
  helperText?: string;
  icon?: ReactNode;
}

export const InputFieldWithController = <T extends FieldValues>({
  label,
  errors,
  name,
  type,
  control,
  required,
  helperText,
  icon,
}: InputFieldWithControllerProps<T>) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const isError = !!errors?.[name];
  const inputType = isPasswordVisible ? 'text' : type;

  return (
    <div className={styles.formControl}>
      <label className={styles.label}>{label}</label>
      <div className={styles.inputWrapper}>
        {icon && <span className={styles.inputIcon}>{icon}</span>}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <div className={styles.inputWrapper}>
              <input
                type={inputType}
                required={required}
                {...field}
                className={`${type === 'password' ? styles.password : ''} ${
                  isError ? styles.errorInput : ''
                }  ${icon ? styles.withIcon : ''}`}
              />
              {type === 'password' ? (
                <button
                  type='button'
                  className={styles.eyeIcon}
                  onClick={() => setIsPasswordVisible((isVisible) => !isVisible)}
                >
                  <img src={isPasswordVisible ? '/assets/view.png' : '/assets/hide.png'} alt='' />
                </button>
              ) : null}
            </div>
          )}
        />
      </div>
      {helperText ? <p className='helperText'>{helperText}</p> : null}
      {errors?.[name] ? (
        <p className='error'>{errors?.[name]?.message as unknown as string}</p>
      ) : null}
    </div>
  );
};

import { Dropdown, InputField, Modal, PrimaryButton } from '../../common';
import { CheckCircle, X } from 'lucide-react';
import { Divider } from 'rsuite';
import styles from './ChannelShareModal.module.scss';

interface ChannelShareModalProps {
  open: boolean;
  closeModal: () => void;
}

const options = [
  { label: 'Option 1', value: 'option1' },
  { label: 'Option 2', value: 'option2' },
];

export const ChannelShareModal = ({ open, closeModal }: ChannelShareModalProps) => {
  return (
    <Modal open={open} closeModal={closeModal} title='Share toggle with channel'>
      <div className={styles.emailTab}>
        <Dropdown
          placeholder='Select a toggle'
          options={options}
          label='Type or select a toggle to share with this channel'
        />
        <InputField type='text' label='Comment (optional)' />
        <Divider className='divider' />
        <div className={styles.btn}>
          <PrimaryButton value='Share toggle' icon={<CheckCircle />} />
          <PrimaryButton
            value='Cancel'
            icon={<X />}
            bgColor='#C7DCE8'
            onClick={closeModal}
            textColor='#06417E'
          />
        </div>
      </div>
    </Modal>
  );
};

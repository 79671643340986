import { useEffect, useState } from 'react';
import {
  Checkbox,
  Modal,
  PrimaryButton,
  CustomRangeSlider,
  Dropdown,
  Searchbar,
} from '../../common';
import styles from './PropertiesListing.module.scss';
import PropertyCard from '../PropertiesCard/PropertyCard';
import { Divider, FlexboxGrid } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../../context/PageTitleContext';
import { CheckCircle, LoaderIcon, Plus, Settings2, X } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { getProperteisList } from '../../../containers/Properties/propertiesAction';
import Pagination from '../../common/Pagination/Pagination';
import { resetToggle } from '../../../containers/Toggles/toggleSlice';
import { useDebounce } from '../../../Utils/helperFunctions';

const dummyData = [
  { id: 1, label: '327 Loring Ave Mill Valley, CA 94941' },
  { id: 2, label: '327 Lorton Avenue Burlingames, CA' },
];

// const properties = [
//   {
//     id: 1,
//     image: '/assets/banner4.jpg',
//     address: '32 Pine St, Sausalito, CA',
//     city: 'Sausalito',
//     state: 'CA',
//     zip: '94965',
//     price: 6850000,
//     beds: 4,
//     baths: 6,
//     sqft: 3300,
//     dueDate: '04/14',
//     togglesCount: 4,
//   },
//   {
//     id: 2,
//     image: '/assets/banner4.jpg',
//     address: '2118 Thornridge Cir. Syracuse, Sausalito, CA',
//     city: 'Sausalito',
//     state: 'CA',
//     zip: '94965',
//     price: 1234567,
//     beds: 4,
//     baths: 6,
//     sqft: 3300,
//     dueDate: '05/22',
//     togglesCount: 2,
//   },
//   {
//     id: 3,
//     image: '/assets/banner4.jpg',
//     address: '32 Pine St, Sausalito, CA',
//     city: 'Sausalito',
//     state: 'CA',
//     zip: '94965',
//     price: 6850000,
//     beds: 4,
//     baths: 6,
//     sqft: 3300,
//     dueDate: '04/14',
//     togglesCount: 4,
//   },
//   {
//     id: 4,
//     image: '/assets/banner4.jpg',
//     address: '2118 Thornridge Cir. Syracuse, Sausalito, CA',
//     city: 'Sausalito',
//     state: 'CA',
//     zip: '94965',
//     price: 1234567,
//     beds: 4,
//     baths: 6,
//     sqft: 3300,
//     dueDate: '05/22',
//     togglesCount: 2,
//   },
// ];

const options = [
  { label: '1+', value: '1' },
  { label: '2+', value: '2' },
  { label: '3+', value: '2' },
  { label: '4+', value: '2' },
  { label: '5+', value: '2' },
];

type Filters = {
  priceRange: number[];
  propertySize: number[];
  bedrooms: any;
  bathrooms: any;
  favouriteOnly: boolean;
};

export const PropertiesListing = ({ placeName }: any) => {
  const [results, setResults] = useState<{ id: number; label: string }[]>([]);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  const dispatch: any = useDispatch();
  const { propertiesList, loading } = useSelector((state: any) => state.properties);
  const [filters, setFilters] = useState<Filters>({
    priceRange: [],
    propertySize: [],
    bedrooms: '',
    bathrooms: '',
    favouriteOnly: false,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  // console.log(propertiesList);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    if (value === '') {
      setResults([]);
    } else {
      const filteredResults = dummyData.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      );
      setResults(filteredResults);
    }
  };

  const handleSliderChange = (value: [number, number], type: string) => {
    // console.log('Selected Range: ', value);
    setFilters({
      ...filters,
      [type]: value,
    });
  };

  const header = (
    <div className='page-header'>
      <h5>Properties</h5>
      <PrimaryButton
        onClick={() => navigate('/property/create')}
        bgColor='#C7DCE8'
        textColor='#06417E'
        icon={<Plus />}
        value='Add New'
      />
    </div>
  );

  useEffect(() => {
    setPageTitle(header);
    dispatch(resetToggle());
  }, []);

  useEffect(() => {
    dispatch(getProperteisList({ searchKey: searchTerm }));
  }, [debouncedSearchTerm]);

  // console.log('Filtes=>', filters);

  const handleDropDown = (e: any, type: string) => {
    // console.log(e);
    setFilters({
      ...filters,
      [type]: e,
    });
  };

  const handleFilters = () => {
    // console.log(filters);

    const values = {
      bedrooms: +filters.bedrooms[0]?.value,
      bathrooms: +filters.bathrooms[0]?.value,
      minSize: filters.propertySize[0],
      maxSize: filters.propertySize[1],
      minRange: filters.priceRange[0],
      maxRange: filters.priceRange[1],
      favouriteOnly: filters.favouriteOnly,
    };
    // console.log(values);
    dispatch(
      getProperteisList({ ...values }, () => {
        // setOpen(false);
      })
    );
  };

  const ResetFilters = () => {
    dispatch(getProperteisList({}));
    setFilters({
      priceRange: [],
      propertySize: [],
      bedrooms: '',
      bathrooms: '',
      favouriteOnly: false,
    });
    setOpen(false);
  };

  const handleCheckbox = (status: boolean) => {
    // console.log(status);
    setFilters({
      ...filters,
      favouriteOnly: status,
    });
  };

  const handlePageChnage = (newPage: number) => {
    setPage(newPage);
    const values = {
      bedrooms: +filters.bedrooms[0]?.value,
      bathrooms: +filters.bathrooms[0]?.value,
      minSize: filters.propertySize[0],
      maxSize: filters.propertySize[1],
      minRange: filters.priceRange[0],
      maxRange: filters.priceRange[1],
      favouriteOnly: filters.favouriteOnly,
    };
    dispatch(getProperteisList({ ...values, pageNo: newPage }));
  };

  console.log('searchTerm', searchTerm);

  return (
    <div className={styles.listingContainer}>
      <div className={styles.header}>
        <Searchbar
          label='Search by city, address or MLS ID'
          onSearchChange={handleSearchChange}
          results={results}
          // onChange={(value: any) => setSearchTerm(value)}
        />
        <PrimaryButton onClick={() => setOpen(true)} icon={<Settings2 />} value='Filter' />
      </div>
      <div className={styles.list}>
        <p className={styles.searchCount}>
          <span className={styles.count}>{propertiesList?.totalItems} results near</span>{' '}
          {placeName}
        </p>
        {loading && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <LoaderIcon size={60} />
          </div>
        )}
        <div className={styles.propertyList}>
          {!loading &&
            propertiesList?.data?.map((property: any) => (
              <div key={property._id} onClick={() => navigate(`/property/${property._id}`)}>
                <PropertyCard property={property} />
              </div>
            ))}
        </div>
        <Pagination
          page={page}
          data={propertiesList}
          setPage={(newPage: number) => handlePageChnage(newPage)}
        />
      </div>
      <Modal
        open={open}
        closeModal={() => setOpen(false)}
        className={styles.filterModal}
        title='Filter properties'
        size='sm'
      >
        <div className={styles.filter}>
          <Checkbox
            label='Favorited properties only'
            name='fav'
            onChange={(status: boolean) => handleCheckbox(status)}
          />
          <Divider className='divider' />
          <CustomRangeSlider
            label='Price range'
            minValue={50000}
            maxValue={18000000}
            onChange={(e) => handleSliderChange(e, 'priceRange')}
            inputIcon={true}
            icon='$'
          />
          <Divider className='divider' />
          <CustomRangeSlider
            label='Property size (sqft)'
            minValue={500}
            maxValue={7500}
            onChange={(e) => handleSliderChange(e, 'propertySize')}
          />
          <Divider className='divider' />
          <label className={styles.label}>Structure</label>
          <FlexboxGrid style={{ flexWrap: 'nowrap', gap: '24px' }}>
            <Dropdown
              label='Bedrooms'
              options={options}
              placeholder='Select an option'
              onChange={(value: any) => handleDropDown(value, 'bedrooms')}
            />
            <Dropdown
              label='Bathrooms'
              options={options}
              placeholder='Select an option'
              onChange={(value: any) => handleDropDown(value, 'bathrooms')}
            />
          </FlexboxGrid>
          <Divider className='divider' />
          <FlexboxGrid className={styles.btn} style={{ flexWrap: 'nowrap', gap: '24px' }}>
            <PrimaryButton onClick={handleFilters} value='Apply filters' icon={<CheckCircle />} />
            <PrimaryButton
              className={styles.custom}
              onClick={ResetFilters}
              value='Clear filters'
              icon={<X />}
              bgColor='#C7DCE8'
              textColor='#06417E'
            />
          </FlexboxGrid>
        </div>
      </Modal>
    </div>
  );
};

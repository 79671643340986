import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../common';
import { Header } from '../Header';

export const Success = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header title={'You are all set!'} content={'Your password has been successfully reset.'} />
      <PrimaryButton onClick={() => navigate('/auth/login')} value='Return to Login' />
    </>
  );
};

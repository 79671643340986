import { useState } from 'react';
import styles from './UploadButton.module.scss';
import { UploadCloud } from 'lucide-react';

interface UploadButtonProps {
  onImageChange: (image: string | null) => void;
}

export const UploadButton: React.FC<UploadButtonProps> = ({ onImageChange }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      // Read the file as a data URL
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          // Set the selected image in the state
          const imageDataUrl = e.target.result as string;
          setSelectedImage(imageDataUrl);
          // Notify the parent component about the image change
          onImageChange(imageDataUrl);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input
        type='file'
        id='upload-photo'
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <label
        htmlFor='upload-photo'
        className={styles.upload}
        style={{ display: selectedImage ? 'block' : 'flex' }}
      >
        <UploadCloud size={16} />
        <span>Upload Photo</span>
      </label>
    </div>
  );
};

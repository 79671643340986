import { DateRangePicker as CustomDatePickerRange, DateRangePickerProps } from 'rsuite';
import styles from './DateRangePicker.module.scss';

interface CustomDatePickerProps extends DateRangePickerProps {
  label?: string;
  placeholder?: string;
  showHeader?: boolean;
}
export const DateRangePicker = ({ label, placeholder, ...props }: CustomDatePickerProps) => {
  return (
    <div className={styles.datepicker}>
      <label>{label}</label>
      <CustomDatePickerRange
        block
        placeholder={placeholder}
        {...props}
        showHeader={props.showHeader}
        ranges={props.ranges}
      />
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChannelShareModal_emailTab__UP9bo {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 24px;
}
.ChannelShareModal_emailTab__UP9bo .ChannelShareModal_btn__iIYUp {
  display: flex;
  gap: 16px;
}
.ChannelShareModal_emailTab__UP9bo .ChannelShareModal_btn__iIYUp button {
  flex: 1 1;
  text-align: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Channels/ChannelShareModal/ChannelShareModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,SAAA;AAEJ;AADI;EACE,SAAA;EACA,kBAAA;EACA,uBAAA;AAGN","sourcesContent":[".emailTab {\n  display: flex;\n  gap: 24px;\n  flex-direction: column;\n  padding: 24px;\n  .btn {\n    display: flex;\n    gap: 16px;\n    button {\n      flex: 1;\n      text-align: center;\n      justify-content: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emailTab": `ChannelShareModal_emailTab__UP9bo`,
	"btn": `ChannelShareModal_btn__iIYUp`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, ReactNode } from 'react';
import Select from 'react-dropdown-select';
import styles from './Dropdown.module.scss';

interface Option {
  label: string;
  value: string;
}

interface DropdownProps {
  options: Option[];
  placeholder: string;
  multi?: boolean;
  label?: string;
  icon?: ReactNode;
  onChange?: Function;
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  placeholder,
  multi = false,
  label,
  icon,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<Option[]>([]);

  const handleChange = (selectedOptions: Option[]) => {
    setSelectedValue(selectedOptions);
    onChange && onChange(selectedOptions);
    // If you need to lift the state up or call a parent callback, you can do it here
    // e.g., props.onChange(selectedOptions.map(option => option.value));
  };

  return (
    <div className={styles.formControl}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.dropdownContainer}>
        {icon && <span className={styles.icon}>{icon}</span>}
        <Select
          className={`${styles.dropdownInput} ${icon ? styles.inputIcon : ''}`}
          options={options}
          values={selectedValue}
          searchable={false}
          multi={multi}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

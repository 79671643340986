// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordChangeModal_passwordFormModel__pzbBx {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}

.PasswordChangeModal_formBtnsProfile__QSlS- {
  gap: 24px;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/PasswordChangeModal/PasswordChangeModal.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;AACJ;;AACE;EACE,SAAA;EACA,aAAA;AAEJ","sourcesContent":[".passwordFormModel {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n    padding: 24px;\n  }\n  .formBtnsProfile{  \n    gap:24px;\n    display: flex;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordFormModel": `PasswordChangeModal_passwordFormModel__pzbBx`,
	"formBtnsProfile": `PasswordChangeModal_formBtnsProfile__QSlS-`
};
export default ___CSS_LOADER_EXPORT___;

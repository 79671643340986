import React, { useEffect } from 'react';
import styles from './InviteUser.module.scss';
import { ControlledDropdown, InputFieldWithController, Modal, PrimaryButton } from '../common';
import { FlexboxGrid } from 'rsuite';
import { useAxios } from '../../api';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { WorkspaceProps, useWorkspace } from '../../context/WorkspaceContext';

const options = [
  { label: 'Member', value: 'Member' },
  { label: 'Guest', value: 'Guest' },
];

interface InviteUserDialogProps {
  open: boolean;
  closeModal: () => void;
}

export const InviteUserDialog: React.FC<InviteUserDialogProps> = ({ open, closeModal }) => {
  const { workspace } = useWorkspace();
  const { response, makeRequest, error } = useAxios('workspace/member/invite');
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      email: '',
      workspaceId: (workspace as WorkspaceProps)?.workspaceId,
      role: '',
    });
  }, [workspace, reset, setValue]);

  useEffect(() => {
    if (response) {
      toast.success('User Invitation sent successfully', {
        duration: 4000,
      });
      reset();
      closeModal();
    }
    if (error) {
      const message = error?.response?.data as Record<string, string>;
      toast.error(message?.message, {
        duration: 4000,
      });
    }
  }, [response, error]);

  const onSubmit = (data: Record<string, unknown>) => {
    makeRequest({ method: 'post', data: data });
  };
  useEffect(() => {
    reset({});
  }, [reset]);

  const handleInvitePeople = () => {
    handleSubmit(onSubmit)();
    {
      closeModal;
    }
  };
  const handleDeleteInvitePeople = () => {
    {
      closeModal;
    }
  };

  return (
    <Modal
      size='xs'
      open={open}
      closeModal={closeModal}
      className={styles.planModal}
      title='Add users to workspace'
    >
      <div className={styles.inviteUserModal}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className={styles.inviteemail}>
            <FlexboxGrid style={{ gap: '24px' }}>
              <InputFieldWithController
                name='email'
                type='email'
                label='Enter e-mail address'
                control={control}
                errors={errors}
              />
              <ControlledDropdown
                control={control}
                name='role'
                options={options}
                errors={errors}
                placeholder='Select role'
                label='Select role'
              />
              <InputFieldWithController
                name='workspaceId'
                type='hidden'
                control={control}
                errors={errors}
              />
            </FlexboxGrid>
          </div>
        </form>
        <div className={styles.inviteuserBtns}>
          <PrimaryButton className={styles.inviteBtn} value='Add' onClick={handleInvitePeople} />
          <PrimaryButton
            className={styles.inviteBtn}
            bgColor='#74788C'
            value='Cancel'
            onClick={handleDeleteInvitePeople}
          />
        </div>
      </div>
    </Modal>
  );
};

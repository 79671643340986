import { PrimaryButton, Searchbar, Modal, InputField } from '../../common';
import styles from './AIChat.module.scss';
import { useEffect, useState } from 'react';
import { ChevronsUpDown, Send } from 'lucide-react';
import { FlexboxGrid } from 'rsuite';
import { usePageTitle } from '../../../context/PageTitleContext';

const dummyData = [
  { id: 1, label: '327 Loring Ave Mill Valley, CA 94941' },
  { id: 2, label: '327 Lorton Avenue Burlingames, CA' },
];

export const AIChat = () => {
  const { setPageTitle } = usePageTitle();
  const [results, setResults] = useState<{ id: number; label: string }[]>([]);

  const [open, setOpen] = useState(false);

  const handleSearchChange = (value: string) => {
    if (value === '') {
      setResults([]);
    } else {
      const filteredResults = dummyData.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      );
      setResults(filteredResults);
    }
  };

  useEffect(() => {
    setPageTitle('AI Chat');
  }, []);

  return (
    <>
      <div className={styles.chatHolder}>
        <div className={styles.chatHolderHeader}>
          <div className={styles.chatHolderHeaderLeft}>
            <h6>Select an AI chatbot</h6>
            <div className={styles.profilePic}>
              <span className={styles.profileDp}></span>
              <span className={styles.profileName}>
                Val AI <ChevronsUpDown onClick={() => setOpen(true)} />
              </span>
            </div>
          </div>
          <div className={styles.chatHolderHeaderSearch}>
            <Searchbar
              onSearchChange={handleSearchChange}
              results={results}
              placeholder='Search messages'
            />
          </div>
        </div>
        <div className={styles.chatAi}>
          <div className={styles.chaAiLeft}>
            <div className={styles.chatMessage}>
              <span className={styles.ChatprofileDp}></span>
              <div className={styles.chatMessagedetails}>
                Hello, I am Val. AI assistant from HomeRank. You can ask me anything about property
                valuations, or try one of these options:
              </div>
            </div>
            <div className={styles.chatMessageTag}>
              <div className={styles.chatMessageButton}>
                <PrimaryButton value='Price this home' />
                <PrimaryButton value='What is a toggle?' />
                <PrimaryButton value='Tell me about HomeRank' />
              </div>
              <div className={styles.chatMessageTime}>
                Val AI Chatbot <time>10:00am</time>
              </div>
            </div>
          </div>

          <div className={`${styles.chaAiLeft} ${styles.chaAiRight}`}>
            <div className={styles.chatMessage}>
              <span className={styles.ChatprofileDp}></span>
              <div className={styles.chatMessagedetails}>Price a home</div>
            </div>
            <div className={styles.chatMessageTag}>
              <div className={styles.chatMessageTime}>
                {' '}
                Val AI Chatbot <time>10:00am</time>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.chatHolderBottom}>
          <InputField
            placeholder='Type a message and hit send'
            icon={<Send size='16' />}
            iconPosition='right'
          />
        </div>
      </div>
      <Modal
        open={open}
        closeModal={() => setOpen(false)}
        className={styles.filterModal}
        title='Select an AI to chat with'
        size='xs'
      >
        <div className={styles.filter}>
          <FlexboxGrid style={{ gap: '24px', padding: '24px 0' }}>
            <div className={styles.AiPerson}>
              <span className={styles.profileDp}></span>
              <div className={styles.AiPersonDes}>
                <h6>Val</h6>
                <p>Home valuations and market insights</p>
              </div>
            </div>
            <div className={styles.AiPerson}>
              <span className={styles.profileDp}></span>
              <div className={styles.AiPersonDes}>
                <h6>Val</h6>
                <p>Home valuations and market insights</p>
              </div>
            </div>
            <div className={styles.AiPerson}>
              <span className={styles.profileDp}></span>
              <div className={styles.AiPersonDes}>
                <h6>Val</h6>
                <p>Home valuations and market insights</p>
              </div>
            </div>
          </FlexboxGrid>
        </div>
      </Modal>
    </>
  );
};

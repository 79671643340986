import { useState } from 'react';
import { Dropdown, InputField, Modal, PrimaryButton, Tabs } from '../../common';
import styles from './ShareToggleModal.module.scss';
import { Divider } from 'rsuite';
import { CheckCircle, X } from 'lucide-react';

interface ShareToggleModalProps {
  open: boolean;
  setOpen: () => void;
}

const options = [
  { label: 'Option 1', value: 'option1' },
  { label: 'Option 2', value: 'option2' },
];

export const ShareToggleModal = ({ open, setOpen }: ShareToggleModalProps) => {
  const [tabIndex, setTabIndex] = useState(0);

  const emailTabRender = (
    <div className={styles.emailTab}>
      <InputField type='email' label='Enter recipient email address' />
      <InputField type='text' label='Comment (optional)' />
      <Divider className='divider' />
      <div className={styles.btn}>
        <PrimaryButton value='Share toggle' icon={<CheckCircle />} />
        <PrimaryButton
          value='Cancel'
          icon={<X />}
          bgColor='#C7DCE8'
          onClick={setOpen}
          textColor='#06417E'
        />
      </div>
    </div>
  );

  const channelTabRender = (
    <div className={styles.emailTab}>
      <Dropdown
        label='Type or select a channel to share this toggle with'
        options={options}
        placeholder='Select an option'
      />
      <InputField type='text' label='Comment (optional)' />
      <Divider className='divider' />
      <div className={styles.btn}>
        <PrimaryButton value='Share toggle' icon={<CheckCircle />} />
        <PrimaryButton
          value='Cancel'
          icon={<X />}
          bgColor='#C7DCE8'
          onClick={setOpen}
          textColor='#06417E'
        />
      </div>
    </div>
  );

  const tabTitles = ['Email', 'Channel'];
  const tabContents = [
    { content: emailTabRender, key: 'email' },
    { content: channelTabRender, key: 'channel' },
  ];

  return (
    <div>
      <Modal open={open} closeModal={setOpen} title='Share toggle via...'>
        <div className={styles.shareToggle}>
          <Tabs
            tabTitles={tabTitles}
            tabContents={tabContents}
            defaultIndex={tabIndex}
            setTabIndex={(index: number) => setTabIndex(index)}
          />
        </div>
      </Modal>
    </div>
  );
};

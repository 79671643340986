import { FC, useEffect, useState } from 'react';
import { Table as RsuiteTable, Pagination } from 'rsuite';
import styles from './Table.module.scss';

type DataType = {
  [key: string]: unknown;
};

type CustomRenderer = (data: DataType) => JSX.Element;

type ColumnType = {
  title: string;
  dataKey: string;
  sortable?: boolean;
  renderer?: CustomRenderer;
  flexGrow?: number;
  width?: number;
  onPangeChange?: any;
};

interface ReusableTableProps {
  data: DataType[];
  columns: ColumnType[];
  pageSize: number;
  onSort: (sortColumn: string, sortType?: 'asc' | 'desc') => void;
  loading: boolean;
  pagination?: boolean;
  height?: number;
  fillHeight?: boolean;
  paginationData?: any;
  onPangeChange?: any;
  limit?: number;
}

const { Column, HeaderCell, Cell } = RsuiteTable;
export const Table: FC<ReusableTableProps> = ({
  data,
  columns,
  pageSize = 1,
  onSort,
  loading,
  pagination = false,
  height,
  fillHeight,
  paginationData,
  limit,
  onPangeChange,
}) => {
  const [page, setPage] = useState<number>(1);
  const [displayData, setDisplayData] = useState<DataType[]>([]);

  useEffect(() => {
    const pagedData = data.slice((page - 1) * pageSize, page * pageSize);
    setDisplayData(pagedData);
  }, [page, data, pageSize]);

  const total = paginationData?.totalItems;
  const totalPage = paginationData?.totalPages;

  return (
    <div className={styles.table}>
      <RsuiteTable
        height={height}
        autoHeight
        fillHeight={fillHeight}
        data={displayData}
        onSortColumn={onSort}
        loading={loading}
        hover={false}
        wordWrap='break-word'
      >
        {columns.map((col) => (
          <Column
            key={col.dataKey}
            sortable={col.sortable}
            flexGrow={col.flexGrow}
            fullText={false}
            minWidth={100}
            width={col.width}
          >
            <HeaderCell className={styles.header}>{col.title}</HeaderCell>
            <Cell className={styles.row} dataKey={col.dataKey}>
              {(rowData) => {
                return col.renderer ? col.renderer(rowData) : rowData[col.dataKey];
              }}
            </Cell>
          </Column>
        ))}
      </RsuiteTable>
      {pagination && (
        <Pagination
          total={total || data.length}
          prev
          next
          activePage={page}
          onChangePage={(newPage) => {
            setPage(newPage);
            onPangeChange(newPage);
          }}
          limit={limit}
        />
      )}
    </div>
  );
};

import React, { useState } from 'react';
import { DateRangePicker, Dropdown as Drop } from 'rsuite';
import styles from './Dropdown.module.scss';

interface DateRangePickerProps {
  onSelect: (value: [Date, Date] | null) => void; // Adjusted the type here
}

const DateRangePickerComponent: React.FC<DateRangePickerProps> = ({ onSelect }) => {
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null); // Adjusted the type here

  const handleSelect = (value: [Date, Date] | null) => {
    // Adjusted the type here
    setDateRange(value);
    onSelect(value);
  };

  return <DateRangePicker value={dateRange} onChange={handleSelect} style={{ width: 300 }} />;
};

const DropDownRender = ({ selectValue, label }: any) => {
  const [selectedValue, setSelectedValue] = useState<any>('');
  const handleSelect = (value: any) => {
    setSelectedValue('Custome');
    selectValue(value);
  };
  console.log(selectedValue);

  return (
    <div className={styles.formControl}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.dropdownContainer}>
        {/* {icon && <span className={styles.icon}>{icon}</span>} */}
        <Drop
          className={`${styles.dropdownInput} `}
          title={selectedValue ? selectedValue : label}
          trigger='click'
        >
          <Drop.Item
            onSelect={() => {
              selectValue('day');
              setSelectedValue('Today');
            }}
          >
            Today
          </Drop.Item>
          <Drop.Item
            onSelect={() => {
              selectValue('week');
              setSelectedValue('This Week');
            }}
          >
            This Week
          </Drop.Item>
          <Drop.Item
            onSelect={() => {
              selectValue('month');
              setSelectedValue('This Month');
            }}
          >
            This Month
          </Drop.Item>
          <Drop.Item>
            <DateRangePickerComponent onSelect={handleSelect} />
          </Drop.Item>
        </Drop>
      </div>
    </div>
  );
};

export default DropDownRender;

import React, { useState } from 'react';
import styles from './PropertyCard.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import { SecondaryButton } from '../../common';
import { ExternalLink, Heart, MapPin } from 'lucide-react';
import { useDispatch } from 'react-redux';
import {
  AddtoFavourite,
  getPropertyDetails,
  removeFavourite,
} from '../../../containers/Properties/propertiesAction';
import toast from 'react-hot-toast';
import { API_URL } from '../../../root_api';

interface Property {
  id: number;
  image: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  price: number;
  bedrooms: number;
  bathrooms: number;
  lotSize: number;
  dueDate?: string;
  togglesCount: number;
}

interface PropertyCardProps {
  property: Property;
}

const PropertyCard: React.FC<any> = ({ property }) => {
  const { id } = useParams();
  const params = useLocation();
  const dispatch: any = useDispatch();
  const [fav, setFav] = useState<any>(false);

  const location = params?.pathname?.split('/')[1];

  const formatPrice = (price: number) => {
    return price?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const handleFavourite = () => {
    dispatch(
      AddtoFavourite({ typeId: id, type: 'property' }, () => {
        toast.success('Add to favourite Successfully.', {
          duration: 4000,
        });
      })
    );

    setTimeout(() => {
      dispatch(getPropertyDetails(id, () => {}));
    }, 800);
  };

  const handleRemoveFavourite = () => {
    id &&
      dispatch(
        removeFavourite(id, () => {
          toast.success('Remove favourite Successfully.', {
            duration: 4000,
          });
        })
      );
    setTimeout(() => {
      dispatch(getPropertyDetails(id, () => {}));
    }, 800);
  };
  // removeFavourite

  const favourites = (state: number) => {
    if (state) {
      setFav(0);
      handleRemoveFavourite();
    } else {
      setFav(1);
      handleFavourite();
      // dispatch(getPropertyDetails(id, () => {}));
    }
  };

  console.log('Prooo =>', property);

  return (
    <div
      className={`${location === 'property' ? styles.detailCard : ''} ${
        (location !== 'property' && id) || location === 'toggle' ? styles.horizontol : ''
      } ${styles.card}`}
    >
      {location === 'property' && (
        <button className={styles.heartIcon} onClick={() => favourites(property?.isFavourite)}>
          <Heart fill={property?.isFavourite ? 'red' : ''} />
        </button>
      )}
      {/* <div className={styles.calendarIcon}>
        {property.dueDate && (
          <div className={styles.dueDate}>
            <CalendarIcon />
            <span>Due {property.dueDate}</span>
          </div>
        )}
      </div> */}
      <img
        src={API_URL + property?.imgUrl || '/assets/banner4.jpg'}
        alt='Property'
        className={styles.propertyImage}
      />
      <div className={styles.propertyDetails}>
        <h3>{property?.address}</h3>
        {location !== 'property' && (
          <p className={styles.address}>
            <MapPin />{' '}
            <span>{`${property?.city || 'N/a'}, ${property?.state || 'N/a'}, ${
              property?.zip || 'N/a'
            }`}</span>
          </p>
        )}
        <div className={location === 'property' ? styles.flex : ''}>
          <div className={styles.bedBathSqft}>
            <span>
              <strong>{property?.bedrooms}</strong> bed
            </span>
            <span>
              <strong>{property?.bathrooms}</strong> bath
            </span>
            <span>
              <strong>{property?.lotSize?.toLocaleString()}</strong> sqft
            </span>
          </div>
          <div className={styles.price}>
            <span>
              <strong>${formatPrice(property?.price)}</strong>
            </span>
            {!id && (
              <span className={styles.toggles}>
                <strong>{property?.togglesCount}</strong> toggles
              </span>
            )}
            {/* {location === 'propertyDetail' && (
              <SecondaryButton value='View details' icon={<ExternalLink />} />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;

import styles from './Auth.module.scss';
import {
  Login,
  Registration,
  VerifyAccount,
  CompleteProfile,
  ResetPassword,
  Success,
  CreateNewPassword,
} from '../../components/Auth';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

export const Auth = () => {
  const location = useLocation();

  const getImageSrc = (pathname: string) => {
    switch (pathname) {
      case '/auth/login':
        return '/assets/banner4.jpg';
      case '/auth/signup':
        return '/assets/signup-banner.jpg';
      case '/auth/signup/verifyaccount':
        return '/assets/banner2.jpg';
      case '/auth/verifyaccount':
        return '/assets/banner2.jpg';
      case '/auth/forgotpassword':
        return '/assets/banner2.jpg';
      case '/auth/createpassword':
        return '/assets/banner2.jpg';
      case '/auth/complete':
        return '/assets/banner2.jpg';
      default:
        return '/assets/banner4.jpg';
    }
  };

  const currentImgSrc = getImageSrc(location.pathname);

  return (
    <div className={styles.container}>
      <div className={styles.authWrapper}>
        <div className={styles.left}>
          <span>
            <img src={currentImgSrc} alt='banner' />
          </span>
        </div>
        <div className={styles.right}>
          <Routes>
            <Route path='login/:code?' element={<Login />} />
            <Route path='signup/:code?' element={<Registration />} />
            <Route path='verifyaccount' element={<VerifyAccount />} />
            <Route path='signup/verifyaccount' element={<VerifyAccount />} />
            <Route path='completeprofile' element={<CompleteProfile />} />
            <Route path='forgotpassword' element={<ResetPassword />} />
            <Route path='createpassword' element={<CreateNewPassword />} />
            <Route path='complete' element={<Success />} />
            <Route path='*' element={<Navigate to='login' replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

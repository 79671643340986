// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_wrapper__st2m1 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}

.Profile_profileForm__bimII {
  display: flex;
  gap: 24px;
}

.Profile_profileContainer__nBUTx {
  background: var(--neutral-white);
  padding: 24px;
  border-radius: 16px;
  flex: 1 1;
  grid-column: span 8;
}

.Profile_upgrade__F7C1K {
  grid-column: span 4;
}

.Profile_profilePhoto__PkjRI {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

input[name=userName] {
  pointer-events: none;
  color: rgba(20, 25, 47, 0.5019607843);
  font-weight: 500;
}

.Profile_userForm__kTuAY {
  color: #000;
  width: 100%;
}
.Profile_userForm__kTuAY .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 1024px) {
  .Profile_userForm__kTuAY .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.Profile_userForm__kTuAY form {
  margin: 0 0 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Profile/Profile.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sCAAA;EACA,SAAA;AACF;;AACA;EACE,aAAA;EACA,SAAA;AAEF;;AACA;EACE,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AAEF;;AACA;EACE,mBAAA;AAEF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;AAEF;;AAAA;EACE,oBAAA;EACA,qCAAA;EACA,gBAAA;AAGF;;AAAA;EACE,WAAA;EACA,WAAA;AAGF;AAFE;EACE,aAAA;EACA,qCAAA;AAIJ;AAHI;EAHF;IAII,qCAAA;EAMJ;AACF;AAJE;EACE,gBAAA;AAMJ","sourcesContent":[".wrapper {\n  display: grid;\n  grid-template-columns: repeat(12, 1fr);\n  gap: 24px;\n}\n.profileForm {\n  display: flex;\n  gap: 24px;\n}\n\n.profileContainer {\n  background: var(--neutral-white);\n  padding: 24px;\n  border-radius: 16px;\n  flex: 1;\n  grid-column: span 8;\n}\n\n.upgrade {\n  grid-column: span 4;\n}\n\n.profilePhoto {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 16px;\n}\ninput[name=\"userName\"] {\n  pointer-events: none;\n  color:#14192F80;\n  font-weight:500;\n}\n\n.userForm {\n  color: #000;\n  width: 100%;\n  :global .grid {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    @media (max-width: 1024px) {\n      grid-template-columns: repeat(1, 1fr);\n    }\n  }\n  form {\n    margin: 0 0 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Profile_wrapper__st2m1`,
	"profileForm": `Profile_profileForm__bimII`,
	"profileContainer": `Profile_profileContainer__nBUTx`,
	"upgrade": `Profile_upgrade__F7C1K`,
	"profilePhoto": `Profile_profilePhoto__PkjRI`,
	"userForm": `Profile_userForm__kTuAY`
};
export default ___CSS_LOADER_EXPORT___;

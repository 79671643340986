import toast from 'react-hot-toast';
import { notify } from '../../Utils/toastify';
import endPoints from '../../root_api/endpoint';
import { deleteApiCall, getApiCall, postApiCall } from '../../root_api/methods';
import { updateProperties } from './propertiesSlice';

export const getProperteisList = (values?: any, callback?: any, type?: string) => {
  console.log(values);

  const {
    searchKey,
    pageNo = 1,
    bedrooms,
    bathrooms,
    minSize,
    maxSize,
    minRange,
    maxRange,
    favouriteOnly,
    sortBy,
    sortOrder = -1,
    limit = 5,
    resetFilters,
    lat = 76.7841457,
    lng = 30.7298439,
  } = values || {};

  const baseUrl = `${endPoints.properties.getProperteisList}?longitude=${lat}&latitude=${lng}`;
  let query: any = '';

  if (maxRange) {
    query += '&maxRange=' + maxRange;
  }
  if (minRange) {
    query += '&minRange=' + minRange;
  }
  if (minSize) {
    query += '&minSize=' + minSize;
  }
  if (maxSize) {
    query += '&maxSize=' + maxSize;
  }
  // if (favouriteOnly) {
  //   query = '&maxRange=' + maxRange;
  // }
  if (bathrooms) {
    query += '&bathrooms=' + bathrooms;
  }
  if (bedrooms) {
    query += '&bedrooms=' + bedrooms;
  }

  if (pageNo) {
    query += '&pageNo=' + pageNo;
  }
  if (searchKey) {
    query += '&searchKey=' + searchKey;
  }
  if (favouriteOnly) {
    query += '&favouriteOnly=' + favouriteOnly;
  }

  return (dispatch: any, getState: any) => {
    // console.log('dataToSend', values);
    dispatch(updateProperties({ loading: true }));

    getApiCall(
      baseUrl + query,
      (s: any) => {
        const {
          data: { statusCode },
          data,
        } = s;

        if (statusCode && statusCode === 200) {
          // console.log('RESSS', data);
          dispatch(updateProperties({ propertiesList: data?.data }));
          callback && callback();
        }
        dispatch(updateProperties({ loading: false }));
      },
      (e: any) => {
        //   setLoad(false)
        dispatch(updateProperties({ loading: false }));
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error');
        } else {
          notify(null, 'error');
        }
      }
    );
  };
};

export const getPropertyDetails = (id?: string, callback?: any, type?: string) => {
  return (dispatch: any) => {
    dispatch(updateProperties({ loading: true }));

    getApiCall(
      `${endPoints.properties.getPropertyDetails(id)}`,
      (s: any) => {
        const {
          data: { statusCode },
          data,
        } = s;

        if (statusCode && statusCode === 200) {
          // console.log('RESSS', data);
          dispatch(updateProperties({ propertyDetails: data?.data }));
        }
        dispatch(updateProperties({ loading: false }));
      },
      (e: any) => {
        //   setLoad(false)
        dispatch(updateProperties({ loading: false }));
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error');
        } else {
          notify(null, 'error');
        }
      }
    );
  };
};

export const addProperty = (values: any, callback: any) => {
  // console.log('Body => ', values)
  return (dispatch: any, getState: any) => {
    // console.log('dataToSend', values)
    dispatch(updateProperties({ loading: true }));

    postApiCall(
      endPoints.properties.add,
      values,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;

        // console.log('Product banner', s)
        if (statusCode && statusCode === 200) {
          callback();
        }
        dispatch(updateProperties({ loading: false }));
      },
      (e: any) => {
        //   setLoad(false)

        dispatch(updateProperties({ loading: false }));
        if (e?.data && e?.data.message) {
          // notify(e?.data.message, 'error');
          toast.error('Something went wrong');
        } else {
          // notify(null, 'error');
          toast.error('Something went wrong');
        }
      }
    );
  };
};

export const getFavourtiesProperties = (callback?: any, type?: string) => {
  return (dispatch: any) => {
    dispatch(updateProperties({ loading: true }));

    getApiCall(
      `${endPoints.properties.getAndAddToFavourite}`,
      (s: any) => {
        const {
          data: { statusCode },
          data,
        } = s;

        if (statusCode && statusCode === 200) {
          console.log('RESSS', data);
          dispatch(updateProperties({ favoutites: data?.data }));
        }
        dispatch(updateProperties({ loading: false }));
      },
      (e: any) => {
        //   setLoad(false)
        dispatch(updateProperties({ loading: false }));
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error');
        } else {
          notify(null, 'error');
        }
      }
    );
  };
};

export const AddtoFavourite = (values: any, callback?: any) => {
  // console.log(values)
  const { type, typeId } = values;
  return (dispatch: any) => {
    postApiCall(
      endPoints.properties.getAndAddToFavourite,
      { type, typeId },
      (s: any) => {
        const {
          data: { statusCode },
        } = s;

        // console.log('RESSS  edit actionssssss', s);
        if (statusCode && statusCode === 200) {
          callback && callback();
          notify('Add in Favourite ', 'success');
          // notify('Product created successfully', 'success')
        }
      },
      (e: any) => {
        //   setLoad(false)
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error');
        } else {
          notify(null, 'error');
        }
      }
    );
  };
};

export const removeFavourite = (id: string, callback?: Function) => {
  return (dispatch: any, getState: any) => {
    // console.log('dataToSend', values)

    deleteApiCall(
      `${endPoints.properties.removeFavourite(id)}`,
      '',
      (s: any) => {
        const {
          data: { statusCode },
        } = s;

        if (statusCode && statusCode === 200) {
          // console.log('RESSS banner update', s);
          callback && callback();
          // navigate(ROUTES.PRODUCTS)
          // notify('Product created successfully', 'success')
        }
      },
      (e: any) => {
        //   setLoad(false)
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error');
        } else {
          notify(null, 'error');
        }
      }
    );
  };
};

import { ReactNode } from 'react';
import styles from './PrimaryButton.module.scss';

interface PrimaryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
  value: string;
  className?: string;
  icon?: ReactNode;
  fullWidth?: boolean;
  bgColor?: string;
  noBackground?: boolean;
  noBorder?: boolean;
  textColor?: string;
  iconPosition?: 'left' | 'right';
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  type = 'button',
  value,
  className,
  icon,
  fullWidth = false,
  bgColor,
  noBorder,
  noBackground,
  textColor,
  iconPosition = 'left',
  ...props
}) => {
  const buttonStyles: React.CSSProperties = {};
  let buttonClassName = `${styles.primary} ${className || ''}`;
  if (noBorder) buttonClassName += ` ${styles.noBorder}`;
  if (noBackground) buttonClassName += ` ${styles.noBackground}`;
  if (fullWidth) buttonClassName += ` ${styles.fullWidth}`;
  if (icon) buttonClassName += ` ${styles.icon}`;
  if (bgColor) buttonStyles.backgroundColor = bgColor;
  if (textColor) buttonStyles.color = textColor;

  return (
    <button className={buttonClassName} style={buttonStyles} type={type} {...props}>
      {icon && iconPosition === 'left' && <span>{icon}</span>} {value}{' '}
      {icon && iconPosition === 'right' && <span className={styles.rightIcon}>{icon}</span>}
    </button>
  );
};

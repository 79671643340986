// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormButtons_formBtns__15sJb {
  border-top: 1px solid #e3e4e8;
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
  padding-top: 24px;
}
.FormButtons_formBtns__15sJb .FormButtons_leftBtn__7AcDO {
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/FormButtons/FormButtons.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,iBAAA;AACF;AAAE;EACE,aAAA;EACA,SAAA;AAEJ","sourcesContent":[".formBtns {\n  border-top: 1px solid #e3e4e8;\n  display: flex;\n  justify-content: space-between;\n  margin-top: 56px;\n  padding-top: 24px;\n  .leftBtn {\n    display: flex;\n    gap: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formBtns": `FormButtons_formBtns__15sJb`,
	"leftBtn": `FormButtons_leftBtn__7AcDO`
};
export default ___CSS_LOADER_EXPORT___;

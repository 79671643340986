import { NavLink } from 'react-router-dom';
import styles from './Link.module.scss';
import { ReactNode } from 'react';

interface LinkProps {
  children: string | ReactNode;
  to?: string;
  variant?: string;
  onclick?: () => void;
}

export const Link = ({ children, to = '#', variant = 'primary', onclick }: LinkProps) => {
  const getClassName = (isActive: boolean) => {
    let baseStyle = '';
    switch (variant) {
      case 'primary':
        baseStyle = styles.linkPrimary;
        break;
      default:
        baseStyle = styles.link;
    }

    return `${baseStyle} ${isActive ? styles.active : ''}`;
  };

  return (
    <NavLink end className={({ isActive }) => getClassName(isActive)} to={to} onClick={onclick}>
      {children}
    </NavLink>
  );
};

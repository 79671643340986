// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_overlay__3g0Vt {
  background: rgba(0, 0, 0, 0.5) !important;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal_modal__N1\\+WB .rs-modal-content {
  padding: 0;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: none;
}

.Modal_header__Py3e0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0;
}
.Modal_header__Py3e0 h4 {
  font-size: 28px;
  color: var(--neutral-medium);
  font-weight: 300;
  line-height: 30px;
}
.Modal_header__Py3e0 svg {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,eAAA;EACA,MAAA;EACA,QAAA;EACA,WAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEE;EACE,UAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,oBAAA;AACF;AAAE;EACE,eAAA;EACA,4BAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,eAAA;AAEJ","sourcesContent":[".overlay {\n  background: rgba(0, 0, 0, 0.5) !important;\n  position: fixed;\n  top: 0;\n  right: 0;\n  width: 100%;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.modal {\n  :global .rs-modal-content {\n    padding: 0;\n    border-radius: 16px;\n    overflow: hidden;\n    box-shadow: none;\n  }\n}\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 24px 24px 0;\n  h4 {\n    font-size: 28px;\n    color: var(--neutral-medium);\n    font-weight: 300;\n    line-height: 30px;\n  }\n  svg {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `Modal_overlay__3g0Vt`,
	"modal": `Modal_modal__N1+WB`,
	"header": `Modal_header__Py3e0`
};
export default ___CSS_LOADER_EXPORT___;

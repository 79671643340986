// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton_iconButton__i1e9V {
  background: none;
  border: none;
  padding: 0;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/common/IconButton/IconButton.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;AACF","sourcesContent":[".iconButton {\n  background: none;\n  border: none;\n  padding: 0;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": `IconButton_iconButton__i1e9V`
};
export default ___CSS_LOADER_EXPORT___;

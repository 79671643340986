// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Link_linkPrimary__xLbH5 {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-decoration-line: underline;
  color: var(--neutral-medium);
  text-decoration-color: var(--neutral-medium);
}

.Link_active__Eqd5W {
  color: var(--primary-color);
}
.Link_active__Eqd5W svg path {
  stroke: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/components/common/Link/Link.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,+BAAA;EACA,4BAAA;EACA,4CAAA;AACF;;AAEA;EACE,2BAAA;AACF;AACI;EACE,4BAAA;AACN","sourcesContent":[".linkPrimary {\n  font-size: 16px;\n  font-weight: 300;\n  line-height: 24px;\n  text-decoration-line: underline;\n  color: var(--neutral-medium);\n  text-decoration-color: var(--neutral-medium);\n}\n\n.active {\n  color: var(--primary-color);\n  svg {\n    path {\n      stroke: var(--primary-color);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkPrimary": `Link_linkPrimary__xLbH5`,
	"active": `Link_active__Eqd5W`
};
export default ___CSS_LOADER_EXPORT___;

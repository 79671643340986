// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Searchbar_searchBarContainer__LQ2fZ {
  flex: 1 1;
}
.Searchbar_searchBarContainer__LQ2fZ label {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--neutral-medium);
  padding: 0 0 8px;
  display: flex;
}
.Searchbar_searchBarContainer__LQ2fZ .Searchbar_searchInput__P3OKA {
  position: relative;
}
.Searchbar_searchBarContainer__LQ2fZ .Searchbar_searchInput__P3OKA svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  height: 100%;
}
.Searchbar_searchBarContainer__LQ2fZ .Searchbar_searchInput__P3OKA input {
  border-radius: 4px;
  border: 1px solid rgba(20, 25, 47, 0.5);
  background: var(--neutral-white);
  padding: 16px 32px;
  font-size: 16px;
  color: var(--neutral-medium);
  font-weight: 500;
  line-height: 24px;
  width: 100%;
}
.Searchbar_searchBarContainer__LQ2fZ .Searchbar_resultsList__XG7M0 {
  margin-top: 4px;
  border-radius: 16px;
  border: 1px solid var(--neutral-light-gray);
  background: var(--neutral-white);
  box-shadow: 0px 10px 20px 0px rgba(18, 38, 63, 0.16);
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 8px;
  position: absolute;
  z-index: 11;
}
.Searchbar_searchBarContainer__LQ2fZ .Searchbar_resultsList__XG7M0 .Searchbar_resultItem__xSY01 {
  display: flex;
  align-items: center;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Searchbar/Searchbar.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,4BAAA;EACA,gBAAA;EACA,aAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;AADI;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;AAGN;AADI;EACE,kBAAA;EACA,uCAAA;EACA,gCAAA;EACA,kBAAA;EACA,eAAA;EACA,4BAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AAGN;AAAE;EACE,eAAA;EACA,mBAAA;EACA,2CAAA;EACA,gCAAA;EACA,oDAAA;EACA,oBAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAGN","sourcesContent":[".searchBarContainer {\n  flex: 1;\n  label {\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 18px;\n    color: var(--neutral-medium);\n    padding: 0 0 8px;\n    display: flex;\n  }\n  .searchInput {\n    position: relative;\n    svg {\n      position: absolute;\n      top: 0;\n      bottom: 0;\n      left: 12px;\n      height: 100%;\n    }\n    input {\n      border-radius: 4px;\n      border: 1px solid rgba(20, 25, 47, 0.5);\n      background: var(--neutral-white);\n      padding: 16px 32px;\n      font-size: 16px;\n      color: var(--neutral-medium);\n      font-weight: 500;\n      line-height: 24px;\n      width: 100%;\n    }\n  }\n  .resultsList {\n    margin-top: 4px;\n    border-radius: 16px;\n    border: 1px solid var(--neutral-light-gray);\n    background: var(--neutral-white);\n    box-shadow: 0px 10px 20px 0px rgba(18, 38, 63, 0.16);\n    display: inline-flex;\n    flex-direction: column;\n    gap: 16px;\n    padding: 16px 8px;\n    position: absolute;\n    z-index: 11;\n    .resultItem {\n      display: flex;\n      align-items: center;\n      gap: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBarContainer": `Searchbar_searchBarContainer__LQ2fZ`,
	"searchInput": `Searchbar_searchInput__P3OKA`,
	"resultsList": `Searchbar_resultsList__XG7M0`,
	"resultItem": `Searchbar_resultItem__xSY01`
};
export default ___CSS_LOADER_EXPORT___;

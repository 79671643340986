import { Modal, PrimaryButton } from '../../common';
import styles from './CreateNewModal.module.scss';
import { useNavigate } from 'react-router-dom';
import { School } from 'lucide-react';

interface CreateNewModalProps {
  open: boolean;
  setOpen: () => void;
}

export const CreateNewModal = ({ open, setOpen }: CreateNewModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal open={open} closeModal={setOpen} title='Creating toggles'>
      <div className={styles.createToggle}>
        <h5>To create a new toggle or to-do item:</h5>
        <ul>
          <li>Visit the ”Properties” tab and search for a property (or create a new one)</li>
          <li>Select the property and click on ”Toggle property”</li>
          <li>Follow the instructions on screen</li>
        </ul>
        <PrimaryButton
          onClick={() => navigate('/properties')}
          value='Start toggling'
          icon={<School />}
        />
      </div>
    </Modal>
  );
};

import axios, { AxiosInstance } from 'axios';
import { handleUnauthorisedUser } from './methods';
import { redirect } from 'react-router-dom';

export const API_URL = `${process.env.REACT_APP_API_URL}`;

// console.log(API_URL);
const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    // 'WWW-Authenticate': localStorage.getItem('token')
    //   ? `BASIC ${localStorage.getItem('token')}`
    //   : '',
    // api_key: '1234',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ``,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: 'en',
    // offset: new Date().getTimezoneOffset(),
    offset: 0,
  },
});

const UNAUTHORIZED = 401;
// // Case handle if there is no token in the local storage
// axiosInstance.interceptors.request.use((config) => {
//   if (!localStorage.getItem('token')) {
//     handleUnauthorisedUser();
//   }
//   return config;
// });

const clearAuthToken = () => {
  delete axiosInstance.defaults.headers['WWW-Authenticate'];
};

axiosInstance.interceptors.request.use(
  (config: any) => {
    // Modify config.url here
    // console.log({ config });

    if (['toggle/calculate_ll', 'toggle/calculate', 'toggle/calculate_sc'].includes(config.url)) {
      // console.log('ues');
      config.baseURL = 'https://api.homerank.net/';
      delete config.headers['Authorization'];
      delete config.headers['timezone'];
      delete config.headers['language'];
      // config.headers['offset'] = '';
      delete config.headers['offset'];
      // config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      data: { statusCode, type },
    } = error.response;

    // console.log("status", status , error.response)

    if (statusCode === 401 && type === 'SESSION_EXPIRED') {
      // console.log('working');
      handleUnauthorisedUser();
      clearAuthToken();
      (<any>window).location = '/login';
    }
    return Promise.reject(error);
  }
);

export const setAuthorizationToken = (token?: string) => {
  if (token) {
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
  }
};
export const resetAuthorizationToken = () => {
  axiosInstance.defaults.headers['Authorization'] = `Basic ${btoa('reel:reel@123')}`;
};

const axiosInstanceWithoutToken: AxiosInstance = axios.create({
  baseURL: `${process.env.API_URL_ONBOARDING}`,
  timeout: 30000,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    // 'WWW-Authenticate': localStorage.getItem('token')
    //   ? `BASIC ${localStorage.getItem('token')}`
    //   : '',
    api_key: '1234',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa('reel:reel@123')}`,
    platform: 1,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: 'en',
    // offset: new Date().getTimezoneOffset(),
    offset: 0,
  },
});

export default {
  axiosInstance,
  setAuthorizationToken,
  clearAuthToken,

  axiosInstanceWithoutToken,
};

// // https://reeldevapi.appskeeper.in/user-service/reel/api/v1/admin/login

// https://reeluserapidev.appskeeper.in/user-service/reel/api/v1/admin/login

// https://reeluserapidev.appskeeper.in/user-service/reel/api/v1/admin/login

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PropertyDataModal_editProperty__hAaNt {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.PropertyDataModal_editProperty__hAaNt .PropertyDataModal_btn__nw1Qm {
  display: grid;
  gap: 24px;
  width: 100%;
}
.PropertyDataModal_editProperty__hAaNt .PropertyDataModal_btn__nw1Qm .PropertyDataModal_flex__xdbMD {
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 24px;
}
.PropertyDataModal_editProperty__hAaNt .PropertyDataModal_button__syiaD {
  display: flex;
  gap: 16px;
  width: 100%;
}
.PropertyDataModal_editProperty__hAaNt .PropertyDataModal_button__syiaD button {
  flex: 1 1;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Toggle/PropertyDataModal/PropertyDataModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AACF;AAAE;EACE,aAAA;EACA,SAAA;EACA,WAAA;AAEJ;AADI;EACE,iBAAA;EACA,qBAAA;EACA,8BAAA;EACA,SAAA;AAGN;AAAE;EACE,aAAA;EACA,SAAA;EACA,WAAA;AAEJ;AADI;EACE,SAAA;EACA,uBAAA;AAGN","sourcesContent":[".editProperty {\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 24px;\n  .btn {\n    display: grid;\n    gap: 24px;\n    width: 100%;\n    .flex {\n      flex-wrap: nowrap;\n      align-items: flex-end;\n      justify-content: space-between;\n      gap: 24px;\n    }\n  }\n  .button {\n    display: flex;\n    gap: 16px;\n    width: 100%;\n    button {\n      flex: 1;\n      justify-content: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editProperty": `PropertyDataModal_editProperty__hAaNt`,
	"btn": `PropertyDataModal_btn__nw1Qm`,
	"flex": `PropertyDataModal_flex__xdbMD`,
	"button": `PropertyDataModal_button__syiaD`
};
export default ___CSS_LOADER_EXPORT___;

const endPoints = {
  properties: {
    getProperteisList: 'property',
    add: 'property',
    getPropertyDetails: (id: string | null | undefined) => `property/${id}`,
    getAndAddToFavourite: 'favourite',
    removeFavourite: (id: string | null | undefined) => `favourite/${id}`,
  },
  toggle: {
    add: 'toggle',
    get: 'toggle',
    getToggleDetails: (id: string | null | undefined) => `toggle/${id}`,
    delete: (id: string | null | undefined) => `toggle/${id}`,
    calculate_one: 'toggle/calculate',
    calculate_two: 'toggle/calculate_ll',
    calculate_sc: 'toggle/calculate_sc',
  },
  todo: {
    get: 'todo',
    add: 'todo',
    delete: (id: string | null | undefined) => `todo/${id}`,
    workSpace: (id: string | null | undefined) => `workspace/${id}`,
  },
};

export default endPoints;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateNewModal_createToggle__QPUmj {
  padding: 24px;
  display: grid;
  gap: 24px;
}
.CreateNewModal_createToggle__QPUmj h5 {
  font-size: 28px;
  color: var(--neutral-medium);
  font-weight: 300;
  line-height: 30px;
}
.CreateNewModal_createToggle__QPUmj ul {
  list-style: auto;
  padding-left: 24px;
}
.CreateNewModal_createToggle__QPUmj ul li {
  font-size: 24px;
  color: var(--neutral-medium);
  font-weight: 300;
  line-height: 30px;
}
.CreateNewModal_createToggle__QPUmj button {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Toggle/CreateNewModal/CreateNewModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,SAAA;AACF;AAAE;EACE,eAAA;EACA,4BAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,gBAAA;EACA,kBAAA;AAEJ;AADI;EACE,eAAA;EACA,4BAAA;EACA,gBAAA;EACA,iBAAA;AAGN;AAAE;EACE,uBAAA;AAEJ","sourcesContent":[".createToggle {\n  padding: 24px;\n  display: grid;\n  gap: 24px;\n  h5 {\n    font-size: 28px;\n    color: var(--neutral-medium);\n    font-weight: 300;\n    line-height: 30px;\n  }\n  ul {\n    list-style: auto;\n    padding-left: 24px;\n    li {\n      font-size: 24px;\n      color: var(--neutral-medium);\n      font-weight: 300;\n      line-height: 30px;\n    }\n  }\n  button {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createToggle": `CreateNewModal_createToggle__QPUmj`
};
export default ___CSS_LOADER_EXPORT___;

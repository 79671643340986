// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SecondaryButton_secondary__PvGVc {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 8px;
}
.SecondaryButton_secondary__PvGVc.SecondaryButton_noBorder__BP-M8 {
  border: none;
}
.SecondaryButton_secondary__PvGVc.SecondaryButton_noBackground__TzG2J {
  background-color: transparent;
}
.SecondaryButton_secondary__PvGVc.SecondaryButton_icon__3j11i {
  display: flex;
  align-items: center;
}
.SecondaryButton_secondary__PvGVc.SecondaryButton_icon__3j11i span {
  margin-right: 8px;
  display: flex;
}
.SecondaryButton_secondary__PvGVc.SecondaryButton_icon__3j11i span.SecondaryButton_rightIcon__Ljh-7 {
  margin-right: 0;
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/SecondaryButton/SecondaryButton.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,6BAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;AAAJ;AACI;EACE,iBAAA;EACA,aAAA;AACN;AAAM;EACE,eAAA;EACA,gBAAA;AAER","sourcesContent":[".secondary {\n  padding: 10px 20px;\n  border: 1px solid #ccc;\n  background-color: #f5f5f5;\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 18px;\n  border-radius: 8px;\n\n  &.noBorder {\n    border: none;\n  }\n\n  &.noBackground {\n    background-color: transparent;\n  }\n  &.icon {\n    display: flex;\n    align-items: center;\n    span {\n      margin-right: 8px;\n      display: flex;\n      &.rightIcon {\n        margin-right: 0;\n        margin-left: 8px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"secondary": `SecondaryButton_secondary__PvGVc`,
	"noBorder": `SecondaryButton_noBorder__BP-M8`,
	"noBackground": `SecondaryButton_noBackground__TzG2J`,
	"icon": `SecondaryButton_icon__3j11i`,
	"rightIcon": `SecondaryButton_rightIcon__Ljh-7`
};
export default ___CSS_LOADER_EXPORT___;

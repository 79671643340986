// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_progressbar__b9d3i {
  border-radius: 8px;
  height: 8px;
  background: var(--neutral-light-gray);
  display: flex;
  overflow: hidden;
  margin-bottom: 24px;
}
.Sidebar_progressbar__b9d3i span {
  background: var(--primary-color);
}

.Sidebar_ChannelListingHolder__\\+F9rd {
  background: var(--neutral-light);
  padding: 16px;
  border-radius: 16px;
}
.Sidebar_ChannelListingHolder__\\+F9rd h2 {
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 29px;
}
.Sidebar_ChannelListingHolder__\\+F9rd h2 svg {
  background: var(--primary-color);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  padding: 10px;
}
.Sidebar_ChannelListingHolder__\\+F9rd p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--neutral-medium);
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/SideBar/Sidebar.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACG,WAAA;EACA,qCAAA;EACA,aAAA;EACA,gBAAA;EACH,mBAAA;AACD;AAAE;EACE,gCAAA;AAEJ;;AAEA;EACC,gCAAA;EACA,aAAA;EACA,mBAAA;AACD;AAAC;EACC,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AAEF;AADE;EACC,gCAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;AAGH;AAAC;EACC,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,4BAAA;EACA,mBAAA;AAEF","sourcesContent":[".progressbar{\n\tborder-radius: 8px;\n    height: 8px;\n    background: var(--neutral-light-gray);\n    display: flex;\n    overflow: hidden;\n\tmargin-bottom: 24px;\n  & span{\n    background:var(--primary-color);\n  }\n}\n\n.ChannelListingHolder {\n\tbackground:var(--neutral-light);\n\tpadding:16px;\n\tborder-radius:16px;\n\t& h2{\n\t\tfont-size: 28px;\n\t\tfont-style: normal;\n\t\tfont-weight: 300;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tgap: 20px;\n\t\tmargin-bottom: 29px;\n\t\t& svg{\n\t\t\tbackground: var(--primary-color);\n\t\t\twidth: 40px;\n\t\t\theight: 40px;\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tborder-radius: 100%;\n\t\t\tpadding: 10px;\n\t\t}\n\t}\n\t& p{\n\t\tfont-size: 16px;\n\t\tfont-style: normal;\n\t\tfont-weight: 500;\n\t\tline-height: 24px; \n\t\tcolor:var(--neutral-medium);\n\t\tmargin-bottom: 24px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressbar": `Sidebar_progressbar__b9d3i`,
	"ChannelListingHolder": `Sidebar_ChannelListingHolder__+F9rd`
};
export default ___CSS_LOADER_EXPORT___;

import { PrimaryButton } from '../common';
import styles from './FormButtons.module.scss';
import { Trash2 } from 'lucide-react';

interface FormButtonsProps {
  onSaveChanges: () => void;
  workSpace?: boolean;
}

export const FormButtons = ({ onSaveChanges, workSpace }: FormButtonsProps) => {
  return (
    <div className={styles.formBtns}>
      <div className={styles.leftBtn}>
        <PrimaryButton value='Save Changes' onClick={onSaveChanges} />
        <PrimaryButton bgColor='#74788C' value='Cancel' />
      </div>
      <PrimaryButton
        value={workSpace ? 'Delete Workspace' : 'Delete Account'}
        noBackground={true}
        noBorder={true}
        icon={<Trash2 />}
        textColor='#ea3c50'
      />
    </div>
  );
};

import { combineReducers } from '@reduxjs/toolkit';
import propertiesSlice from '../containers/Properties/propertiesSlice';
import toggle from '../containers/Toggles/toggleSlice';
import { useDispatch, useSelector } from 'react-redux';

export interface Reducer {
  properties: any;
  toggle: any;
}

export const rootReducer = combineReducers<Reducer>({
  properties: propertiesSlice,
  toggle: toggle,
});

// export const dispatch: any = useDispatch();

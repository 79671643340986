import { useEffect, useState } from 'react';
import { useAxios } from '../api';
import { Content, Header, Navigation } from '../components/Layout';
import styles from './Layout.module.scss';
import { useSession } from '../context/SessionContext';

import { WorkspaceProps } from '../context/WorkspaceContext';

export const Layout = () => {
  const { response, makeRequest } = useAxios('workspace');
  const { response: sessionData, makeRequest: makeSessionDataRequest } = useAxios('auth/session');
  const [workspaceList, setWorkspaceList] = useState<WorkspaceProps[]>([]);
  const { session, setSession } = useSession();

  useEffect(() => {
    makeRequest({ method: 'get' });
    makeSessionDataRequest({ method: 'get' });
  }, []);

  useEffect(() => {
    if (sessionData) {
      const data = (sessionData as { data: Record<string, unknown>[] }).data;
      setSession(data);
    }
  }, [sessionData]);

  useEffect(() => {
    if (response) {
      const data = (response as { data: WorkspaceProps[] }).data;
      setWorkspaceList(data);
    }
  }, [response]);

  return (
    <main className={styles.main}>
      <Navigation workspaceList={workspaceList} setWorkspaceList={setWorkspaceList} />

      <div className={styles.content}>
        <Header />
        <Content />
      </div>
    </main>
  );
};
